
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { checkBbanValidation } from "services/util";


const codes = [23225, 23230, 23231, 23232,
  23233, 23234, 23244, 23275, 23276, 23277,
  23278, 23279, 23280, 23288, 23299];

const mobileMoney = [];

const banks = [];

const ViewSubscriber = (props) => {

  const [values, setValues] = React.useState(props);
  const [errors, setErrors] = React.useState({}); 
  const mobWithCC = values.mobileCode + values.mobileNumber;
  const bankMobWithCC = values.bbanLinkMobileCode + values.bbanLinkMobileNumber;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      if (checkMobValidation(value)) {
        setValues({ ...values, [name]: value });
      }
    } else if (name === 'bban') {
      if (checkBbanValidation(value)) {
        setValues({ ...values, [name]: value });
      }
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const checkMobValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 5) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const closeModal = () => {
    props.closeModal();
  }

  const validate = (inputs) => {
    let errors = {};
    let emailRegEx = /\S+@\S+\.\S+/
    if (!inputs.name) {
      errors.name = 'Merchant Name is Required';
    }
    if (inputs.email && !emailRegEx.test(inputs.email)) {
      errors.email = 'Enter valid email';
    }
    if (!inputs.bban) {
      errors.bban = 'BBAN is Required';
    } else if (inputs.bban.length < 8 || inputs.bban.length > 18) {
      errors.bban = 'BBAN should have 8 to 18 digits';
    }
    if (!inputs.mobileCode) {
      errors.mobileCode = 'Please select mobile code';
    }
    if (!inputs.phone) {
      errors.phone = 'Phone Number is Required';
    } else if (inputs.phone.length !== 5) {
      errors.phone = 'Phone Number should have 5 digits';
    }
    if (!inputs.bankName) {
      errors.bankName = 'Bank Name is Required';
    }
    if (!inputs.address) {
      errors.address = 'Address is Required';
    }
    if (!inputs.attachFile) {
      errors.attachFile = 'Attach file is required';
    }
    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
  }
  return (
    <>
      {/* Page content */}
      <div className="p-0">
        <Row >
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Subscriber Information</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: 'pointer', fontSize: '25px' }}
                    >
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                    Profile Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      {
                        values.subscriberType == 'Individual' &&
                        <>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-fname"
                              >
                                First Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-fname"
                                placeholder="First Name"
                                type="text"
                                name="firstName"
                                value={values.firstName}
                                disabled={true}
                                onChange={handleInputChange}
                              />
                              {errors.firstName && <div className="text-left text-danger">
                                <small>* {errors.firstName}</small>
                              </div>}
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-fname"
                              >
                                Last Name
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-lname"
                                placeholder="Last Name"
                                type="text"
                                name="lastName"
                                value={values.lastName}
                                disabled={true}
                                onChange={handleInputChange}
                              />
                              {errors.lastName && <div className="text-left text-danger">
                                <small>* {errors.lastName}</small>
                              </div>}
                            </FormGroup>
                          </Col>

                        </>
                      }

                      {values.subscriberType == 'Organisation' &&
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-oname"
                            >
                              Organisation Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-oname"
                              placeholder="Organisation Name"
                              type="text"
                              name="organisationName"
                              value={values.organizationName}
                              disabled={true}
                              onChange={handleInputChange}
                            />
                            {errors.organizationName && <div className="text-left text-danger">
                              <small>* {errors.organizationName}</small>
                            </div>}
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                    <Row>
                      {/* <Col lg="2">
                        <FormGroup>
                          <label
                            for="input-mobileCode"
                            className="form-control-label">
                            Mobile Code
                          </label>
                          <Input
                            type="select"
                            id="input-mobileCode"
                            className="form-control-alternative"
                            name="mobileCode"
                            value={values.mobileCode}
                            disabled={true}
                            onChange={handleInputChange}
                          >
                            {codes.map(c => <option key={c}>{c}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-phone"
                            placeholder="Phone Number"
                            type="text"
                            name="phone"
                            value={values.mobileNumber}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.phone && <div className="text-left text-danger">
                            <small>* {errors.phone}</small>
                          </div>}
                        </FormGroup>
                      </Col> */}
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={'sl'}
                            value={mobWithCC}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"

                            type="email"
                            name="email"
                            value={values.email}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.email && <div className="text-left text-danger">
                            <small>* {errors.email}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Mobile Money Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-mobilemoney"
                            className="form-control-label">
                            Mobile Money
                          </label>
                          <Input
                            type="select"
                            id="input-mobilemoney"
                            className="form-control-alternative"
                            name="mobilemoney"
                            value={values.mmoId}
                            disabled={true}
                            onChange={handleInputChange}
                          >
                            <option key="" value="">-----Select MMO-----</option>
                            {values.mobileMoneyList && values.mobileMoneyList.map(c =>
                              <option key={c.key} value={c.key}>{c.value}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-mmaccno"
                          >
                            MM Account Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-mmaccno"
                            placeholder="Acount Number"
                            type="text"
                            name="mobileMoneyAccNo"
                            value={values.mmoAccountNumber}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.mobileMoneyAccNo && <div className="text-left text-danger">
                            <small>* {errors.mobileMoneyAccNo}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Bank Details
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            for="input-bank"
                            className="form-control-label">
                            Bank
                          </label>
                          <Input
                            type="select"
                            id="input-bank"
                            className="form-control-alternative"
                            name="mobilemoney"
                            value={values.bankProfileId}
                            disabled={true}
                            onChange={handleInputChange}
                          >
                            {values.bankList && values.bankList.map(c =>
                              <option key={c.key} value={c.key}>{c.value}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bban"
                          >
                            BBAN Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bban"
                            placeholder="BBAN Number"
                            type="text"
                            name="bban"
                            value={values.bban}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.bban && <div className="text-left text-danger">
                            <small>* {errors.bban}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bank-accno"
                          >
                            Bank Account Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bank-accno"
                            placeholder="Bank Account Number"
                            type="text"
                            name="bankAccountNumber"
                            value={values.bankAccountNumber}
                            onChange={handleInputChange}
                            disabled
                          />
                          {errors.bankAccountNumber && <div className="text-left text-danger">
                            <small>* {errors.bankAccountNumber}</small>
                          </div>}
                        </FormGroup>
                      </Col>

                      {/* <Col lg="2">
                        <FormGroup>
                          <label
                            for="input-bankLinkedMobileCode"
                            className="form-control-label">
                            Mobile Code
                          </label>
                          <Input
                            type="select"
                            id="input-bbanLinkMobileCode"
                            className="form-control-alternative"
                            name="bbanLinkMobileCode"
                            value={values.bbanLinkMobileCode}
                            disabled={true}
                            onChange={handleInputChange}
                          >
                            {codes.map(c => <option key={c}>{c}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bbanLinkMobileNumber"
                          >
                            Bank Linked Phone Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bbanLinkMobileNumber"
                            placeholder="Phone Number"
                            type="text"
                            name="bbanLinkMobileNumber"
                            value={values.bbanLinkMobileNumber}
                            disabled={true}
                            onChange={handleInputChange}
                          />
                          {errors.bbanLinkMobileNumber && <div className="text-left text-danger">
                            <small>* {errors.bbanLinkMobileNumber}</small>
                          </div>}
                        </FormGroup>
                      </Col>
                     */}
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Bank Linked Phone Number
                          </label>
                          <PhoneInput
                            className="form-control-alternative"
                            country={'sl'}
                            value={bankMobWithCC}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewSubscriber;
