const ContactUs = () => {
  return (
    <div className="p-2 flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <p>Call 3737</p>
      </div>
      <div className="flex items-center gap-4">
        <p>8 Regent Road, Hill station, Freetown</p>
      </div>
      <div className="flex items-center gap-4">
        <a
          href={"https://smartsystems.sl/our-contacts/"}
          className="underline text-blue-500"
          target="_blank"
          rel="noreferrer"
        >
          Fill form
        </a>
      </div>
    </div>
  );
};

export default ContactUs;
