
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Container,
  Row,
  CardHeader
} from "reactstrap";

import Header from "../components/Headers/Header";
import { getUserEmail, loginMethod } from "../services/httpServices";
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router";

const ChangePassword = () => {

  const initialValue = {
    oldpswd: "",
    newpswd: "",
    confirmpswd: ""
  };

  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState(initialValue);
  const [showOldPswd, setOldPswd] = React.useState(false);
  const [showNewPswd, setNewPswd] = React.useState(false);
  const [showConfirmPswd, setComfirmPswd] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { addToast } = useToasts();
  const history = useHistory();

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    value = value.trim();
    let error = {};
    if (name === "confirmpswd") {
      validateConfirmPswd(value);
    }
    if (name === "confirmpswd") {
      // validateConfirmPswd(value);
      if (value !== values.newpswd) {
        error.confirmpswd = 'Confirm password should match New Password';
      }
    }
    setValues({
      ...values,
      [name]: value,
    });
  };

  const validateConfirmPswd = (value) => {
    let error = {};
    if (value !== values.newpswd) {
      error.confirmpswd = 'Confirm password should match New Password';
    }
    setErrors(error);
  }

  const validate = (inputs) => {
    let errors = {};
    if (!inputs.oldpswd) {
      errors.oldpswd = 'Old Password is Required';
    }
    if (!inputs.newpswd) {
      errors.newpswd = 'New Password is Required';
    } else if (inputs.newpswd.length < 6) {
      errors.newpswd = 'Password should have atleast 6 characters';
    }
    if (!inputs.confirmpswd) {
      errors.confirmpswd = 'Confirm Password is Required';
    } else if (inputs.confirmpswd !== inputs.newpswd) {
      errors.confirmpswd = 'Confirm password should match New Password';
    }
    return errors;
  }

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const resetValues = () => {
    setValues({
      oldpswd: "",
      newpswd: "",
      confirmpswd: ""
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validationErrors);
    const email = getUserEmail();
    if (!email) {
      notifyFailed('Login User Email is not found');
    } else if (noErrors) {
      const isNoError = Object.values(validationErrors).every(x => x === '');
      if (isNoError) {
        var data = new FormData();

        data.append('Email', email);
        data.append('oldPassword', values.oldpswd);
        data.append('newPassword', values.newpswd);
        data.append('confirmPassword', values.confirmpswd);

        let errorMsg = '';
        setLoading(true);
        loginMethod('/api/Auth/changepassword', data, 'post')
        .then(res => {
          setLoading(false);
          if (res && res.data) {
            if (res.data.responseCode === "200") {
              notifySuccess('Password has been changed successfully');
              // resetValues();
              window.localStorage.clear();
              history.push('/auth/login');
            } else if (res.data.responseCode === "400") {
              // Error while saving
              errorMsg = res.data.responseMessage || 'Error while saving';
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = 'Unknown Error in saving';
              notifyFailed(errorMsg);
            }
          } else {
            // Not valid response from server
            errorMsg = 'Unknown Error';
            notifyFailed(errorMsg);
          }
        }).catch(err => {
          setLoading(false);
          errorMsg = 'Something went wrong!, Please call 3737';
          notifyFailed(errorMsg);
        });
      } else {
      }
    }
  }


    return (
      <>
        <Header />
        <Container className="mt--5 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card 
                className="bg-secondary shadow border-0"
              >
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col lg="12">
                      <h3 className="mb-0 text-center">Change Password</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  {/* <div className="text-center text-muted mb-2">
                    <span className="h3-old">Change Password</span>
                  </div> */}
                  <Form role="form" onSubmit={handleSubmit} autoComplete="off">
                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="oldpswd">
                        Old Password
                    </label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Old Password"
                          type={showOldPswd ?  "password" :"text" }
                          name="oldpswd"
                          value={values.oldpswd}
                          onChange={handleInputChange}
                        />
                        {!showOldPswd && <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fa fa-eye-slash" onClick={() => setOldPswd(true)} />
                          </InputGroupText>
                        </InputGroupAddon>}

                        {showOldPswd && <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fa fa-eye" onClick={() => setOldPswd(false)} />
                          </InputGroupText>
                        </InputGroupAddon>}

                      </InputGroup>
                      {errors.oldpswd && <div className="text-center text-danger mb-1">
                        <small>{errors.oldpswd}</small>
                      </div>}
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="newpswd">
                        New Password
                    </label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="New Password"
                          type={showNewPswd ? "password": "text" }
                          name="newpswd"
                          id="newpswd"
                          value={values.newpswd}
                          onChange={handleInputChange}
                        />
                        {!showNewPswd && <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fa fa-eye-slash" onClick={() => setNewPswd(true)} />
                          </InputGroupText>
                        </InputGroupAddon>}

                        {showNewPswd && <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fa fa-eye" onClick={() => setNewPswd(false)} />
                          </InputGroupText>
                        </InputGroupAddon>}

                      </InputGroup>
                      {errors.newpswd && <div className="text-center text-danger mb-1">
                        <small>{errors.newpswd}</small>
                      </div>}
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <label
                        className="form-control-label"
                        htmlFor="confirmpswd">
                        Confirm Password
                    </label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Confirm Password"
                          type={showConfirmPswd ?  "password":"text" }
                          name="confirmpswd"
                          id="confirmpswd"
                          value={values.confirmpswd}
                          onChange={handleInputChange}
                        />
                        {!showConfirmPswd && <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fa fa-eye-slash" onClick={() => setComfirmPswd(true)} />
                          </InputGroupText>
                        </InputGroupAddon>}

                        {showConfirmPswd && <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <i className="fa fa-eye" onClick={() => setComfirmPswd(false)} />
                          </InputGroupText>
                        </InputGroupAddon>}

                      </InputGroup>
                      {errors.confirmpswd && <div className="text-center text-danger mb-1">
                        <small>{errors.confirmpswd}</small>
                      </div>}
                    </FormGroup>
                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit"
                        disabled={loading}>
                        Change {loading && <i class="fa fa-spinner fa-spin"></i>}
                    </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </>
    );
  };

  export default ChangePassword;
