import React from "react";
// import classnames from "classnames";
import Chart, { helpers } from "chart.js";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";

import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import { getLoginType } from "services/httpServices";
import VoucherWallet from "./subscriber/voucher-wallet";
import MerchantHeader from "components/Headers/MerchantHeader";
import AdminHeader from "components/Headers/AdminHeader";
import { Bar, Pie, Doughnut, Line } from "react-chartjs-2";
import SubscriberHeader from "components/Headers/subscriberHeader";
import { MonthArrayCAP, YearArray } from "services/util";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import BankHeader from "components/Headers/BankHeader";
import FinanceUserHeader from "components/Headers/FinanceUserHeader";
import SettlementBankHeader from "components/Headers/settlementBankHeader";
import SuperAgentHeader from "components/Headers/SuperAgentHeader";
import AgentHeader from "components/Headers/AgentHeader";
import MasterAgentHeader from "components/Headers/MasterAgentHeader";

const BAR_CHART_MAX_HEIGHT = "420px";

const mmtdloaderContainer = {
  position: "absolute",
  top: "100px",
  left: 0,
  width: "100%",
  heigth: "100%",
  backgroundColor: "#F8F8F8AD",
  // display: 'none'
};

const mmtdloader = {
  left: "35%",
  top: "50%",
  zIndex: "1000",
  position: "absolute",
};

const YEARS = YearArray();

const Home = (props) => {
  const logintype = getLoginType();
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const [pendingChart, setPendingChart] = React.useState(false);
  const [pendingChartData, setPendingChartData] = React.useState({});
  const [collectedChart, setCollectedChart] = React.useState(false);
  const [collectedChartData, setCollectedChartData] = React.useState({});
  const [merchantChart, setMerchantChart] = React.useState(false);
  const [merchantMTD, setMerchantMTD] = React.useState({});
  const [merchantMTDMonth, setMerchantMTDMonth] = React.useState(currentMonth);
  const [merchantMTDYear, setMerchantMTDYear] = React.useState(currentYear);
  const [merchantMTDLoading, setMerchantMTDLoading] = React.useState(false);
  const [merchantYTD, setMerchantYTD] = React.useState({});
  const [merchantYTDYear, setMerchantYTDYear] = React.useState(currentYear);
  const [merchantYTDLoading, setMerchantYTDLoading] = React.useState(false);

  const [adminChart, setAdminChart] = React.useState(
    logintype == "Admin" ? true : logintype === "Master Agent" ? true : false
  );
  const [adminMTDWalletTrans, setAdminMTDWalletTrans] = React.useState({});
  const [adminMTDWalletTransLoading, setAdminMTDWalletTransLoading] =
    React.useState(false);
  const [adminMTDWalletTransMonth, setAdminMTDWalletTransMonth] =
    React.useState(currentMonth);
  const [adminMTDWalletTransYear, setAdminMTDWalletTransYear] =
    React.useState(currentYear);

  const [subAgentMTDWalletTrans, setSubAgentMTDWalletTrans] = React.useState(
    {}
  );
  const [subAgentMTDWalletTransLoading, setSubAgentMTDWalletTransLoading] =
    React.useState(false);
  const [subAgentMTDWalletTransMonth, setSubAgentMTDWalletTransMonth] =
    React.useState(currentMonth);
  const [subAgentMTDWalletTransYear, setSubAgentMTDWalletTransYear] =
    React.useState(currentYear);

  const [subscriberChart, setSubscriberChart] = React.useState(
    logintype == "Subscriber" ? true : false
  );
  const [subsMTDWithDraw, setSubsMTDWithDraw] = React.useState({});
  const [subsMTDWithDrawLoading, setSubsMTDWithDrawLoading] =
    React.useState(false);
  const [subsMTDWithDrawMonth, setSubsMTDWithDrawMonth] =
    React.useState(currentMonth);
  const [subsMTDWithDrawYear, setSubsMTDWithDrawYear] =
    React.useState(currentYear);
  const [subsMTDPayment, setSubsMTDPayment] = React.useState({});
  const [subsMTDPaymentLoading, setSubsMTDPaymentLoading] =
    React.useState(false);
  const [subsMTDPaymentMonth, setSubsMTDPaymentMonth] =
    React.useState(currentMonth);
  const [subsMTDPaymentYear, setSubsMTDPaymentYear] =
    React.useState(currentYear);
  const [subsMTDWalletTrans, setSubsMTDWalletTrans] = React.useState({});
  const [subsMTDWalletTransLoading, setSubsMTDWalletTransLoading] =
    React.useState(false);
  const [subsMTDWalletTransMonth, setSubsMTDWalletTransMonth] =
    React.useState(currentMonth);
  const [subsMTDWalletTransYear, setSubsMTDWalletTransYear] =
    React.useState(currentYear);

  const [bankChart, setBankChart] = React.useState(
    logintype == "Bank" ? true : false
  );
  const [bankCommMonth, setBankCommMonth] = React.useState(currentMonth);
  const [bankCommYear, setBankCommYear] = React.useState(currentYear);
  const [bankCommLoading, setBankCommLoading] = React.useState(false);
  const [bankCommission, setBankCommission] = React.useState({});
  const [bankCTMonth, setBankCTMonth] = React.useState(currentMonth);
  const [bankCTYear, setBankCTYear] = React.useState(currentYear);
  const [bankCTloading, setBankCTLoading] = React.useState(false);
  const [bankCT, setBankCT] = React.useState({});

  const [financeChart, setFinanceChart] = React.useState(
    logintype == "Finance" ? true : false
  );
  const [financePieChart, setFinancePieChart] = React.useState({});
  const [financePieMonth, setFinancePieMonth] = React.useState(currentMonth);
  const [financePieYear, setFinancePieYear] = React.useState(currentYear);
  const [financePieLoading, setFinancePieLoading] = React.useState(false);

  const [financeMTDColltn, setFinanceMTDColltn] = React.useState({});
  const [financeMTDColltnMonth, setFinanceMTDColltnMonth] =
    React.useState(currentMonth);
  const [financeMTDColltnYear, setFinanceMTDColltnYear] =
    React.useState(currentYear);
  const [financeMTDColltnLoading, setFinanceMTDColltnLoading] =
    React.useState(false);

  const [financeMTDColltn2, setFinanceMTDColltn2] = React.useState({});
  const [financeMTDColltnMonth2, setFinanceMTDColltnMonth2] =
    React.useState(currentMonth);
  const [financeMTDColltnYear2, setFinanceMTDColltnYear2] =
    React.useState(currentYear);
  const [financeMTDColltnLoading2, setFinanceMTDColltnLoading2] =
    React.useState(false);

  const [financeMTDComm, setFinanceMTDComm] = React.useState({});
  const [financeMTDCommMonth, setFinanceMTDCommMonth] =
    React.useState(currentMonth);
  const [financeMTDCommYear, setFinanceMTDCommYear] =
    React.useState(currentYear);
  const [financeMTDCommLoading, setFinanceMTDCommLoading] =
    React.useState(false);

  const [settlementPieChart, setSettlementPieChart] = React.useState({});
  const [settlementPieMonth, setSettlementPieMonth] =
    React.useState(currentMonth);
  const [settlementPieYear, setSettlementPieYear] = React.useState(currentYear);
  const [settlementPieLoading, setSettlementPieLoading] = React.useState(false);

  const [settlementChart, setSettlementChart] = React.useState(
    logintype == "SettlementBank" ? true : false
  );
  const [settlementMTDMerchant, setSettlementMTDMerchant] = React.useState({});
  const [settlementMTDMerchantMonth, setSettlementMTDMerchantMonth] =
    React.useState(currentMonth);
  const [settlementMTDMerchantYear, setSettlementMTDMerchantYear] =
    React.useState(currentYear);
  const [settlementMTDMerchantLoading, setSettlementMTDMerchantLoading] =
    React.useState(false);

  const [settlementMTDTransfer, setSettlementMTDTransfer] = React.useState({});
  const [settlementMTDTransferMonth, setSettlementMTDTransferMonth] =
    React.useState(currentMonth);
  const [settlementMTDTransferYear, setSettlementMTDTransferYear] =
    React.useState(currentYear);
  const [settlementMTDTransferLoading, setSettlementMTDTransferLoading] =
    React.useState(false);

  const [chartExample1Data, setChartExample1Data] = React.useState("data1");

  const getHeader = () => {
    if (logintype === "Merchant") {
      return (
        <MerchantHeader
          showMerchantMTD={showMerchantMTD}
          merchantMTDMonth={merchantMTDMonth}
          merchantMTDYear={merchantMTDYear}
          showMerchantMTDLoader={showMerchantMTDLoader}
          showMerchantYTD={showMerchantYTD}
          merchantYTDYear={merchantYTDYear}
          showMerchantYTDLoader={showMerchantYTDLoader}
        />
      );
    } else if (logintype === "Admin") {
      return (
        <AdminHeader
          showPendingChart={showPendingChart}
          showCollectedChart={showCollectedChart}
          showAdminMTDWalletTrans={showAdminMTDWalletTrans}
          showAdminMTDWalletTransLoader={showAdminMTDWalletTransLoader}
          adminMTDWalletTransMonth={adminMTDWalletTransMonth}
          adminMTDWalletTransYear={adminMTDWalletTransYear}
          showSubAgentMTDWalletTrans={showSubAgentMTDWalletTrans}
          showSubAgentMTDWalletTransLoader={showSubAgentMTDWalletTransLoader}
          subAgentMTDWalletTransMonth={subAgentMTDWalletTransMonth}
          subAgentMTDWalletTransYear={subAgentMTDWalletTransYear}
          showFinanceMTDCommLoader={showFinanceMTDCommLoader}
          showFinanceMTDComm={showFinanceMTDComm}
          financeMTDCommMonth={financeMTDCommMonth}
          financeMTDCommYear={financeMTDCommYear}
          showFinanceMTDColltn={showFinanceMTDColltn}
          financeMTDColltnMonth={financeMTDColltnMonth}
          financeMTDColltnYear={financeMTDColltnYear}
          showFinanceMTDColltnLoader={showFinanceMTDColltnLoader}
          showFinanceMTDColltn2={showFinanceMTDColltn2}
          financeMTDColltnMonth2={financeMTDColltnMonth2}
          financeMTDColltnYear2={financeMTDColltnYear2}
          showFinanceMTDColltnLoader2={showFinanceMTDColltnLoader2}
        />
      );
    } else if (logintype === "Subscriber") {
      return (
        <SubscriberHeader
          showSubsMTDWithDraw={showSubsMTDWithDraw}
          showSubsMTDWithDrawLoader={showSubsMTDWithDrawLoader}
          subsMTDWithDrawMonth={subsMTDWithDrawMonth}
          subsMTDWithDrawYear={subsMTDWithDrawYear}
          showSubsMTDPayment={showSubsMTDPayment}
          showSubsMTDPaymentLoader={showSubsMTDPaymentLoader}
          subsMTDPaymentMonth={subsMTDPaymentMonth}
          subsMTDPaymentYear={subsMTDPaymentYear}
          showSubsMTDWalletTrans={showSubsMTDWalletTrans}
          showSubsMTDWalletTransLoader={showSubsMTDWalletTransLoader}
          subsMTDWalletTransMonth={subsMTDWalletTransMonth}
          subsMTDWalletTransYear={subsMTDWalletTransYear}
        />
      );
    } else if (logintype == "Bank") {
      return (
        <BankHeader
          showBankCommLoader={showBankCommLoader}
          showBankCommMTD={showBankCommMTD}
          bankCommMonth={bankCommMonth}
          bankCommYear={bankCommYear}
          showBankCTLoader={showBankCTLoader}
          showBankCTMTD={showBankCTMTD}
          bankCTMonth={bankCTMonth}
          bankCTYear={bankCTYear}
        />
      );
    } else if (logintype == "Finance") {
      return (
        <FinanceUserHeader
          showFinancePieLoader={showFinancePieLoader}
          showFinancePieChart={showFinancePieChart}
          financePieMonth={financePieMonth}
          financePieYear={financePieYear}
          showFinanceMTDColltnLoader={showFinanceMTDColltnLoader}
          showFinanceMTDColltn={showFinanceMTDColltn}
          financeMTDColltnMonth={financeMTDColltnMonth}
          financeMTDColltnYear={financeMTDColltnYear}
          showFinanceMTDColltnLoader2={showFinanceMTDColltnLoader2}
          showFinanceMTDColltn2={showFinanceMTDColltn2}
          financeMTDColltnMonth2={financeMTDColltnMonth2}
          financeMTDColltnYear2={financeMTDColltnYear2}
          showFinanceMTDCommLoader={showFinanceMTDCommLoader}
          showFinanceMTDComm={showFinanceMTDComm}
          financeMTDCommMonth={financeMTDCommMonth}
          financeMTDCommYear={financeMTDCommYear}
        />
      );
    } else if (logintype == "SettlementBank") {
      return (
        <SettlementBankHeader
          showSettlementPieChart={showSettlementPieChart}
          showSettlementPieLoader={showSettlementPieLoader}
          settlementPieMonth={settlementPieMonth}
          settlementPieYear={settlementPieYear}
          showSettlementMTDMerchantLoader={showSettlementMTDMerchantLoader}
          showSettlementMTDMerchant={showSettlementMTDMerchant}
          settlementMTDMerchantMonth={settlementMTDMerchantMonth}
          settlementMTDMerchantYear={settlementMTDMerchantYear}
          showSettlementMTDTransferLoader={showSettlementMTDTransferLoader}
          showSettlementMTDTransfer={showSettlementMTDTransfer}
          settlementMTDTransferMonth={settlementMTDTransferMonth}
          settlementMTDTransferYear={settlementMTDTransferYear}
        />
      );
    } else if (logintype == "Super Agent") {
      return <SuperAgentHeader />;
    } else if (logintype == "Agent") {
      return <AgentHeader />;
    } else if (logintype == "Master Agent") {
      return (
        <MasterAgentHeader
          showAdminMTDWalletTrans={showAdminMTDWalletTrans}
          showAdminMTDWalletTransLoader={showAdminMTDWalletTransLoader}
          adminMTDWalletTransMonth={adminMTDWalletTransMonth}
          adminMTDWalletTransYear={adminMTDWalletTransYear}
          showSubAgentMTDWalletTrans={showSubAgentMTDWalletTrans}
          showSubAgentMTDWalletTransLoader={showSubAgentMTDWalletTransLoader}
          subAgentMTDWalletTransMonth={subAgentMTDWalletTransMonth}
          subAgentMTDWalletTransYear={subAgentMTDWalletTransYear}
        />
      );
    } else {
      return <Header />;
    }
  };

  const getMonths = (year) => {
    let monthArr = MonthArrayCAP;
    if (year == currentYear) {
      monthArr = MonthArrayCAP.slice(0, currentMonth + 1);
    }
    return monthArr.map((c) => (
      <option key={c.key} value={c.key}>
        {c.value}
      </option>
    ));
  };

  /**======================== Admin Dashboard Items =========================  */

  const showPendingChart = (chart) => {
    setPendingChart(true);
    setPendingChartData(chart);
  };

  const showCollectedChart = (chart) => {
    setCollectedChart(true);
    setCollectedChartData(chart);
  };

  const showAdminMTDWalletTransLoader = (loader) => {
    setAdminMTDWalletTransLoading(loader);
  };

  const showAdminMTDWalletTrans = (chart) => {
    setAdminMTDWalletTrans(chart);
  };

  const changeAdminMTDWalletTransMonth = (value) => {
    setAdminMTDWalletTransMonth(value);
  };

  const changeAdminMTDWalletTransYear = (value) => {
    setAdminMTDWalletTransYear(value);
  };

  const showSubAgentMTDWalletTransLoader = (loader) => {
    setSubAgentMTDWalletTransLoading(loader);
  };

  const showSubAgentMTDWalletTrans = (chart) => {
    setSubAgentMTDWalletTrans(chart);
  };

  const changeSubAgentMTDWalletTransMonth = (value) => {
    setSubAgentMTDWalletTransMonth(value);
  };

  const changeSubAgentMTDWalletTransYear = (value) => {
    setSubAgentMTDWalletTransYear(value);
  };

  const options = {
    maintainAspectRatio: false,
    responsive: false,
    legend: {
      position: "bottom",
      display: true,
      align: "center",
      // labels: {
      //   boxWidth: 15
      // },
    },
    tooltips: {
      callbacks: {
        label: function (item, data) {
          var label = data.labels[item.index] || "";
          return label;
        },
      },
    },
  };

  const doughnutOptions = {
    maintainAspectRatio: false,
    responsive: false,
    legend: {
      // position: 'top',
      display: true,
      // align: 'center'
      labels: {
        boxWidth: 15,
      },
    },
    tooltips: {
      callbacks: {
        label: function (item, data) {
          var label = data.labels[item.index] || "";
          return label;
        },
      },
    },
  };

  /**======================== Merchant Dashboard Items =========================  */

  const showMerchantMTD = (chart) => {
    setMerchantChart(true);
    setMerchantMTD(chart);
  };

  const showMerchantYTD = (chart) => {
    setMerchantChart(true);
    setMerchantYTD(chart);
  };

  const changeMerchantMDTMonth = (value) => {
    setMerchantMTDMonth(value);
  };

  const changeMerchantYTD = (value) => {
    setMerchantYTDYear(value);
  };

  const changeMerchantMTDYear = (value) => {
    setMerchantMTDYear(value);
  };

  const showMerchantMTDLoader = (loader) => {
    setMerchantMTDLoading(loader);
  };

  const showMerchantYTDLoader = (loader) => {
    setMerchantYTDLoading(loader);
  };

  /**======================== Subscriber Dashboard Items =========================  */

  // WithDrawals functions
  const changeSubsMTDWithDrawMonth = (value) => {
    setSubsMTDWithDrawMonth(value);
  };

  const changeSubsMTDWithDrawYear = (value) => {
    setSubsMTDWithDrawYear(value);
  };

  const showSubsMTDWithDrawLoader = (loader) => {
    setSubsMTDWithDrawLoading(loader);
  };

  const showSubsMTDWithDraw = (chart) => {
    setSubsMTDWithDraw(chart);
  };

  // Payments functions

  const changeSubsMTDPaymentMonth = (value) => {
    setSubsMTDPaymentMonth(value);
  };

  const changeSubsMTDPaymentYear = (value) => {
    setSubsMTDPaymentYear(value);
  };

  const showSubsMTDPaymentLoader = (loader) => {
    setSubsMTDPaymentLoading(loader);
  };

  const showSubsMTDPayment = (chart) => {
    setSubsMTDPayment(chart);
  };

  // WalletTrans functions

  const changeSubsMTDWalletTransMonth = (value) => {
    setSubsMTDWalletTransMonth(value);
  };

  const changeSubsMTDWalletTransYear = (value) => {
    setSubsMTDWalletTransYear(value);
  };

  const showSubsMTDWalletTransLoader = (loader) => {
    setSubsMTDWalletTransLoading(loader);
  };

  const showSubsMTDWalletTrans = (chart) => {
    setSubsMTDWalletTrans(chart);
  };

  /**======================== Bank Dashboard Items =========================  */

  const showBankCommMTD = (chart) => {
    setBankChart(true);
    setBankCommission(chart);
  };

  const changeBankCommMonth = (value) => {
    setBankCommMonth(value);
  };

  const changeBankCommYear = (value) => {
    setBankCommYear(value);
  };

  const showBankCommLoader = (loader) => {
    setBankCommLoading(loader);
  };

  const showBankCTMTD = (chart) => {
    setBankChart(true);
    setBankCT(chart);
  };

  const changeBankCTMonth = (value) => {
    setBankCTMonth(value);
  };

  const changeBankCTYear = (value) => {
    setBankCTYear(value);
  };

  const showBankCTLoader = (loader) => {
    setBankCTLoading(loader);
  };

  /**======================== Bank Dashboard Items =========================  */

  //PIE Chart

  const showFinancePieChart = (chart) => {
    setFinancePieChart(chart);
  };

  const changeFinancePieMonth = (value) => {
    setFinancePieMonth(value);
  };

  const changeFinancePieYear = (value) => {
    setFinancePieYear(value);
  };

  const showFinancePieLoader = (loader) => {
    setFinancePieLoading(loader);
  };

  // MTD Collection

  const showFinanceMTDColltn = (chart) => {
    setFinanceMTDColltn(chart);
  };

  const changeFinanceMTDColltnMonth = (value) => {
    setFinanceMTDColltnMonth(value);
  };

  const changeFinanceMTDColltnYear = (value) => {
    setFinanceMTDColltnYear(value);
  };

  const changeFinanceMTDColltnYear2 = (value) => {
    setFinanceMTDColltnYear2(value);
  };

  const showFinanceMTDColltnLoader = (loader) => {
    setFinanceMTDColltnLoading(loader);
  };

  // MTD Collection

  const showFinanceMTDColltn2 = (chart) => {
    setFinanceMTDColltn2(chart);
  };

  const changeFinanceMTDColltnMonth2 = (value) => {
    setFinanceMTDColltnMonth2(value);
  };

  const showFinanceMTDColltnLoader2 = (loader) => {
    setFinanceMTDColltnLoading2(loader);
  };

  // MTD Commission

  const showFinanceMTDComm = (chart) => {
    setFinanceMTDComm(chart);
  };

  const changeFinanceMTDCommMonth = (value) => {
    setFinanceMTDCommMonth(value);
  };

  const changeFinanceMTDCommYear = (value) => {
    setFinanceMTDCommYear(value);
  };

  const showFinanceMTDCommLoader = (loader) => {
    setFinanceMTDCommLoading(loader);
  };

  /**======================== Bank Dashboard Items =========================  */

  // Settlement Pie Chart

  const showSettlementPieChart = (chart) => {
    setSettlementPieChart(chart);
  };

  const changeSettlementPieMonth = (value) => {
    setSettlementPieMonth(value);
  };

  const changeSettlementPieYear = (value) => {
    setSettlementPieYear(value);
  };

  const showSettlementPieLoader = (loader) => {
    setSettlementPieLoading(loader);
  };

  // MTD Merchant Settlements

  const showSettlementMTDMerchant = (chart) => {
    setSettlementMTDMerchant(chart);
  };

  const changeSettlementMTDMerchantMonth = (value) => {
    setSettlementMTDMerchantMonth(value);
  };

  const changeSettlementMTDMerchantYear = (value) => {
    setSettlementMTDMerchantYear(value);
  };

  const showSettlementMTDMerchantLoader = (loader) => {
    setSettlementMTDMerchantLoading(loader);
  };

  // MTD Transfers

  const showSettlementMTDTransfer = (chart) => {
    setSettlementMTDTransfer(chart);
  };

  const changeSettlementMTDTransferMonth = (value) => {
    setSettlementMTDTransferMonth(value);
  };

  const changeSettlementMTDTransferYear = (value) => {
    setSettlementMTDTransferYear(value);
  };

  const showSettlementMTDTransferLoader = (loader) => {
    setSettlementMTDTransferLoading(loader);
  };

  return (
    <>
      {getHeader()}
      <Container fluid>
        <Row>
          {logintype === "Admin" && (
            <Col xl="6">
              <Card className="shadow mb-2">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="mb-0 chart-card-header">
                        Collected Commission
                      </h2>
                    </div>
                    <div className="col">
                      <Input
                        type="select"
                        id="status"
                        className="form-control-alternative"
                        name="financeMTDCommMonth"
                        value={financeMTDCommMonth}
                        onChange={(e) =>
                          changeFinanceMTDCommMonth(e.target.value)
                        }
                      >
                        {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                        {getMonths(financeMTDCommYear)}
                      </Input>
                    </div>

                    <div className="col">
                      <Input
                        type="select"
                        id="status"
                        className="form-control-alternative"
                        name="financeMTDCommYear"
                        value={financeMTDCommYear}
                        onChange={(e) =>
                          changeFinanceMTDCommYear(e.target.value)
                        }
                      >
                        {YEARS.map((c) => (
                          <option key={c.key} value={c.key}>
                            {c.value}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    {financeMTDComm &&
                      financeMTDComm.data &&
                      financeMTDComm.options &&
                      !financeMTDCommLoading && (
                        <Bar
                          data={financeMTDComm.data}
                          options={financeMTDComm.options}
                        />
                      )}

                    {financeMTDCommLoading && (
                      <FullPageLoader
                        loaderContainer={mmtdloaderContainer}
                        loader={mmtdloader}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
          {adminChart && (
            <>
              <Col xl="6">
                <Card className="shadow  mb-4">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h4 className="text-muted mb-1">
                          Customer Topup MTD (Other platforms)
                        </h4>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="subsMTDWalletTransMonth"
                          value={adminMTDWalletTransMonth}
                          onChange={(e) =>
                            changeAdminMTDWalletTransMonth(e.target.value)
                          }
                        >
                          {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                          {getMonths(adminMTDWalletTransYear)}
                        </Input>
                      </div>

                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="merchantMTDYear"
                          value={adminMTDWalletTransYear}
                          onChange={(e) =>
                            changeAdminMTDWalletTransYear(e.target.value)
                          }
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      {adminMTDWalletTrans &&
                        adminMTDWalletTrans.data &&
                        adminMTDWalletTrans.options &&
                        !adminMTDWalletTransLoading && (
                          <Bar
                            data={adminMTDWalletTrans.data}
                            options={adminMTDWalletTrans.options}
                          />
                        )}

                      {adminMTDWalletTransLoading && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </>
          )}
        </Row>

        {/**===========================Merchant Dashboard ========================== */}

        <Row>
          {merchantChart && (
            <Col xl="6">
              <Card className="shadow mb-2">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="mb-0">Daily Sales</h2>
                    </div>
                    {/* <div className="col">
                      <Input
                        type="select"
                        id="status"
                        className="form-control-alternative"
                        name="merchantMTDMonth"
                        value={merchantMTDMonth}
                        onChange={(e) => changeMerchantMDTMonth(e.target.value)}
                      >
                        {/* {MonthArrayCAP.map(c =>
                        <option key={c.key} value={c.key}>{c.value}</option>
                      )} */}
                    {/* {getMonths(merchantMTDYear)}
                      </Input>
                    </div> */}{" "}
                    {/* <div className="col">
                      <Input
                        type="select"
                        id="status"
                        className="form-control-alternative"
                        name="merchantMTDYear"
                        value={merchantMTDYear}
                        onChange={(e) => changeMerchantMTDYear(e.target.value)}
                      >
                        {YEARS.map((c) => (
                          <option key={c.key} value={c.key}>
                            {c.value}
                          </option>
                        ))}
                      </Input>
                    </div> */}
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    {merchantMTD &&
                      merchantMTD.data &&
                      merchantMTD.options &&
                      !merchantMTDLoading && (
                        <Bar
                          data={merchantMTD.data}
                          options={merchantMTD.options}
                        />
                      )}

                    {merchantMTDLoading && (
                      <FullPageLoader
                        loaderContainer={mmtdloaderContainer}
                        loader={mmtdloader}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
          {merchantChart && (
            <Col xl="6">
              <Card className="shadow mb-2">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h2 className="mb-0">Monthly Sales</h2>
                    </div>
                    <div className="col"></div>
                    {/* <div className="col">
                      <Input
                        type="select"
                        id="status"
                        className="form-control-alternative"
                        name="merchantYTDYear"
                        value={merchantYTDYear}
                        onChange={(e) => changeMerchantYTD(e.target.value)}
                      >
                        {YEARS.map((c) => (
                          <option key={c.key} value={c.key}>
                            {c.value}
                          </option>
                        ))}
                      </Input>
                    </div> */}
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    {merchantYTD &&
                      merchantYTD.data &&
                      merchantYTD.options &&
                      !merchantYTDLoading && (
                        <Bar
                          data={merchantYTD.data}
                          options={merchantYTD.options}
                        />
                      )}

                    {merchantYTDLoading && (
                      <FullPageLoader
                        loaderContainer={mmtdloaderContainer}
                        loader={mmtdloader}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>

        {/* ==================Subscriber Dashboard======================== */}

        {subscriberChart && (
          <>
            <Row>
              <Col xl="6">
                <Card className="shadow mb-2">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0">MTD Withdrawals</h2>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="subsMTDWithDrawMonth"
                          value={subsMTDWithDrawMonth}
                          onChange={(e) =>
                            changeSubsMTDWithDrawMonth(e.target.value)
                          }
                        >
                          {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                          {getMonths(subsMTDWithDrawYear)}
                        </Input>
                      </div>

                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="subsMTDWithDrawYear"
                          value={subsMTDWithDrawYear}
                          onChange={(e) =>
                            changeSubsMTDWithDrawYear(e.target.value)
                          }
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      {subsMTDWithDraw &&
                        subsMTDWithDraw.data &&
                        subsMTDWithDraw.options &&
                        !subsMTDWithDrawLoading && (
                          <Bar
                            data={subsMTDWithDraw.data}
                            options={subsMTDWithDraw.options}
                          />
                        )}

                      {subsMTDWithDrawLoading && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="6">
                <Card className="shadow mb-2">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0">MTD Payment</h2>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="subsMTDPaymentMonth"
                          value={subsMTDPaymentMonth}
                          onChange={(e) =>
                            changeSubsMTDPaymentMonth(e.target.value)
                          }
                        >
                          {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                          {getMonths(subsMTDPaymentYear)}
                        </Input>
                      </div>

                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="merchantMTDYear"
                          value={subsMTDPaymentYear}
                          onChange={(e) =>
                            changeSubsMTDPaymentYear(e.target.value)
                          }
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      {subsMTDPayment &&
                        subsMTDPayment.data &&
                        subsMTDPayment.options &&
                        !subsMTDPaymentLoading && (
                          <Bar
                            data={subsMTDPayment.data}
                            options={subsMTDPayment.options}
                          />
                        )}

                      {subsMTDPaymentLoading && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xl="6">
                <Card className="shadow mb-2">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0">MTD Wallet Transfer</h2>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="subsMTDWalletTransMonth"
                          value={subsMTDWalletTransMonth}
                          onChange={(e) =>
                            changeSubsMTDWalletTransMonth(e.target.value)
                          }
                        >
                          {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                          {getMonths(subsMTDWalletTransYear)}
                        </Input>
                      </div>

                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="merchantMTDYear"
                          value={subsMTDWalletTransYear}
                          onChange={(e) =>
                            changeSubsMTDWalletTransYear(e.target.value)
                          }
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      {subsMTDWalletTrans &&
                        subsMTDWalletTrans.data &&
                        subsMTDWalletTrans.options &&
                        !subsMTDWalletTransLoading && (
                          <Bar
                            data={subsMTDWalletTrans.data}
                            options={subsMTDWalletTrans.options}
                          />
                        )}

                      {subsMTDWalletTransLoading && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}

        {/* ================== Bank Dashboard ======================== */}

        {bankChart && (
          <>
            <Row>
              <Col xl="6">
                <Card className="shadow mb-2">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0 chart-card-header">
                          MTD Commissions
                        </h2>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="bankCommMonth"
                          value={bankCommMonth}
                          onChange={(e) => changeBankCommMonth(e.target.value)}
                        >
                          {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                          {getMonths(bankCommYear)}
                        </Input>
                      </div>

                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="bankCommYear"
                          value={bankCommYear}
                          onChange={(e) => changeBankCommYear(e.target.value)}
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      {bankCommission &&
                        bankCommission.data &&
                        bankCommission.options &&
                        !bankCommLoading && (
                          <Bar
                            data={bankCommission.data}
                            options={bankCommission.options}
                          />
                        )}

                      {bankCommLoading && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="6">
                <Card className="shadow mb-2">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0 chart-card-header">
                          MTD Customer Transfers
                        </h2>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="bankCTMonth"
                          value={bankCTMonth}
                          onChange={(e) => changeBankCTMonth(e.target.value)}
                        >
                          {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                          {getMonths(bankCTYear)}
                        </Input>
                      </div>

                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="bankCTYear"
                          value={bankCTYear}
                          onChange={(e) => changeBankCTYear(e.target.value)}
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      {bankCT &&
                        bankCT.data &&
                        bankCT.options &&
                        !bankCTloading && (
                          <Bar data={bankCT.data} options={bankCT.options} />
                        )}

                      {bankCTloading && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}

        {/* ================== Finance User Dashboard ======================== */}
        {financeChart || adminChart && (
          <>
            <Row>
              <Col xl="12">
                <Card className="chart-card shadow -full">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0 chart-card-header">
                          MTD Collection
                        </h2>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="financeMTDColltnMonth2"
                          value={financeMTDColltnMonth2}
                          onChange={(e) =>
                            changeFinanceMTDColltnMonth2(e.target.value)
                          }
                        >
                          {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                          {getMonths(financeMTDColltnYear)}
                        </Input>
                      </div>

                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="financeMTDColltnYear"
                          value={financeMTDColltnYear2}
                          onChange={(e) =>
                            changeFinanceMTDColltnYear2(e.target.value)
                          }
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart" style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        {" "}
                        {financeMTDColltn2 &&
                          financeMTDColltn2?.chart1?.data &&
                          financeMTDColltn2?.chart1?.options &&
                          !financeMTDColltnLoading2 && (
                            <Bar
                              data={financeMTDColltn2?.chart1?.data}
                              options={financeMTDColltn2?.chart1?.options}
                            />
                          )}
                      </div>
                      <div style={{ width: "50%" }}>
                        {" "}
                        {financeMTDColltn2 &&
                          financeMTDColltn2?.chart2?.data &&
                          financeMTDColltn2?.chart2?.options &&
                          !financeMTDColltnLoading2 && (
                            <Bar
                              data={financeMTDColltn2?.chart2?.data}
                              options={financeMTDColltn2?.chart2?.options}
                            />
                          )}
                      </div>

                      {financeMTDColltnLoading2 && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
        {financeChart && (
          <>
            <Row>
              <Col xl="12">
                <Card className="chart-card shadow -full">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0 chart-card-header">
                          MTD Collection
                        </h2>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="financeMTDColltnMonth2"
                          value={financeMTDColltnMonth2}
                          onChange={(e) =>
                            changeFinanceMTDColltnMonth2(e.target.value)
                          }
                        >
                          {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                          {getMonths(financeMTDColltnYear)}
                        </Input>
                      </div>

                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="financeMTDColltnYear"
                          value={financeMTDColltnYear2}
                          onChange={(e) =>
                            changeFinanceMTDColltnYear2(e.target.value)
                          }
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart" style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        {" "}
                        {financeMTDColltn2 &&
                          financeMTDColltn2?.chart1?.data &&
                          financeMTDColltn2?.chart1?.options &&
                          !financeMTDColltnLoading2 && (
                            <Bar
                              data={financeMTDColltn2?.chart1?.data}
                              options={financeMTDColltn2?.chart1?.options}
                            />
                          )}
                      </div>
                      <div style={{ width: "50%" }}>
                        {" "}
                        {financeMTDColltn2 &&
                          financeMTDColltn2?.chart2?.data &&
                          financeMTDColltn2?.chart2?.options &&
                          !financeMTDColltnLoading2 && (
                            <Bar
                              data={financeMTDColltn2?.chart2?.data}
                              options={financeMTDColltn2?.chart2?.options}
                            />
                          )}
                      </div>

                      {financeMTDColltnLoading2 && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl="6">
                <Card className="chart-card mt-4 shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0 chart-card-header">
                          Collection and Commissions
                        </h2>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="financePieMonth"
                          value={financePieMonth}
                          onChange={(e) =>
                            changeFinancePieMonth(e.target.value)
                          }
                        >
                          {/* {MonthArrayCAP.map(c =>
                        <option key={c.key} value={c.key}>{c.value}</option>
                      )} */}
                          {getMonths(financePieYear)}
                        </Input>
                      </div>

                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="financePieYear"
                          value={financePieYear}
                          onChange={(e) => changeFinancePieYear(e.target.value)}
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody className="d-flex justify-content-center">
                    <div className="chart">
                      {/* {!financePieLoading &&
                    <Pie data={financePieChart} options={options} height={220} width={270} />
                  } */}

                      {!financePieLoading && (
                        <Doughnut
                          data={financePieChart?.data || []}
                          options={doughnutOptions}
                          height={220}
                          width={270}
                        />
                      )}

                      {financePieLoading && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="6">
                <Card className="chart-card mt-4 shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0 chart-card-header">
                          Total Collection
                        </h2>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="financeMTDColltnMonth"
                          value={financeMTDColltnMonth}
                          onChange={(e) =>
                            changeFinanceMTDColltnMonth(e.target.value)
                          }
                        >
                          {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                          {getMonths(financeMTDColltnYear)}
                        </Input>
                      </div>

                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="financeMTDColltnYear"
                          value={financeMTDColltnYear}
                          onChange={(e) =>
                            changeFinanceMTDColltnYear(e.target.value)
                          }
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody className="d-flex justify-content-center">
                    <div className="chart">
                      {
                        financeMTDColltn &&
                          financeMTDColltn.data &&
                          financeMTDColltn.options &&
                          !financeMTDColltnLoading && (
                            <Doughnut
                              data={financeMTDColltn?.data || []}
                              options={doughnutOptions}
                              height={220}
                              width={270}
                            />
                          )
                        // <Line
                        //   data={financeMTDColltn.data}
                        //   options={financeMTDColltn.options}
                        // />
                        // <Line
                        //   data={chartExample1[chartExample1Data]}
                        //   options={chartExample1.options}
                        // />
                      }

                      {financeMTDColltnLoading && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="6" className="mt-4">
                <Card className="chart-card shadow mt-4">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0 chart-card-header">
                          MTD Commissions
                        </h2>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="financeMTDCommMonth"
                          value={financeMTDCommMonth}
                          onChange={(e) =>
                            changeFinanceMTDCommMonth(e.target.value)
                          }
                        >
                          {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                          {getMonths(financeMTDCommYear)}
                        </Input>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="financeMTDCommYear"
                          value={financeMTDCommYear}
                          onChange={(e) =>
                            changeFinanceMTDCommYear(e.target.value)
                          }
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      {financeMTDComm &&
                        financeMTDComm.data &&
                        financeMTDComm.options &&
                        !financeMTDCommLoading && (
                          <Bar
                            data={financeMTDComm.data}
                            options={financeMTDComm.options}
                          />
                        )}
                      {financeMTDCommLoading && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}

        {/* ================== Settlement Bank Dashboard ======================== */}
        {settlementChart && (
          <>
            <Row>
              <Col xl="6">
                <Card className="shadow mb-2">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0 chart-card-header">
                          MTD Merchant Settlements
                        </h2>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="settlementMTDMerchantMonth"
                          value={settlementMTDMerchantMonth}
                          onChange={(e) =>
                            changeSettlementMTDMerchantMonth(e.target.value)
                          }
                        >
                          {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                          {getMonths(settlementMTDMerchantYear)}
                        </Input>
                      </div>

                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="settlementMTDMerchantYear"
                          value={settlementMTDMerchantYear}
                          onChange={(e) =>
                            changeSettlementMTDMerchantYear(e.target.value)
                          }
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      {settlementMTDMerchant &&
                        settlementMTDMerchant.data &&
                        settlementMTDMerchant.options &&
                        !settlementMTDMerchantLoading && (
                          <Bar
                            data={settlementMTDMerchant.data}
                            options={settlementMTDMerchant.options}
                          />
                        )}

                      {settlementMTDMerchantLoading && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="6">
                <Card className="shadow mb-2">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0 chart-card-header">
                          MTD Transfers
                        </h2>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="settlementMTDTransferMonth"
                          value={settlementMTDTransferMonth}
                          onChange={(e) =>
                            changeSettlementMTDTransferMonth(e.target.value)
                          }
                        >
                          {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                          {getMonths(settlementMTDTransferYear)}
                        </Input>
                      </div>

                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="settlementMTDTransferYear"
                          value={settlementMTDTransferYear}
                          onChange={(e) =>
                            changeSettlementMTDTransferYear(e.target.value)
                          }
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="chart">
                      {settlementMTDTransfer &&
                        settlementMTDTransfer.data &&
                        settlementMTDTransfer.options &&
                        !settlementMTDTransferLoading && (
                          <Bar
                            data={settlementMTDTransfer.data}
                            options={settlementMTDTransfer.options}
                          />
                        )}

                      {settlementMTDTransferLoading && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-4 mb-4">
              <Col xl="6">
                <Card className="chart-car shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col">
                        <h2 className="mb-0 chart-card-header">Funds</h2>
                      </div>
                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="settlementPieMonth"
                          value={settlementPieMonth}
                          onChange={(e) =>
                            changeSettlementPieMonth(e.target.value)
                          }
                        >
                          {/* {MonthArrayCAP.map(c =>
                          <option key={c.key} value={c.key}>{c.value}</option>
                        )} */}
                          {getMonths(settlementPieYear)}
                        </Input>
                      </div>

                      <div className="col">
                        <Input
                          type="select"
                          id="status"
                          className="form-control-alternative"
                          name="settlementPieYear"
                          value={settlementPieYear}
                          onChange={(e) =>
                            changeSettlementPieYear(e.target.value)
                          }
                        >
                          {YEARS.map((c) => (
                            <option key={c.key} value={c.key}>
                              {c.value}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody className="d-flex justify-content-center">
                    <div className="chart">
                      {/* {!settlementPieLoading &&
                      <Pie data={settlementPieChart} options={options} height={220} width={270} />
                    } */}

                      {!settlementPieLoading && (
                        <Doughnut
                          data={settlementPieChart?.data || []}
                          options={doughnutOptions}
                          height={220}
                          width={270}
                        />
                      )}

                      {settlementPieLoading && (
                        <FullPageLoader
                          loaderContainer={mmtdloaderContainer}
                          loader={mmtdloader}
                        />
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default Home;
