
import React, { useEffect } from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
} from "reactstrap";

const h1Style = {
    textAlign: 'center',
    color: 'black'
};
const h4Style = {
    color: 'white'
};



const privacyPolicy = () => {

    return (
        <div className="bg-white-original p-4 rounded card">
            <p>

                Your privacy is very important to us. This privacy policy discloses how Smart Systems Sierra Leone Limited and its applications (“SmartPay”, “SmartBill”, “SmartShop”, or “SmartPower”) will collect, use and share personal information about you. Smart Systems recognizes the need for appropriate protections and management of the personal information you provide us.
                This Privacy Policy is designed to both inform you of your rights in respect to the collection and storage of personal information, and to help us ensure that we comply with applicable law in the countries in which we operate.
                This Privacy Policy .
            </p>
            <h1 style={h1Style}>Privacy Policy</h1>

            Your privacy is very important to us. This privacy policy discloses how Smart Systems Sierra Leone Limited and its applications (“SmartPay”, “SmartBill”, “SmartShop”, or “SmartPower”) will collect, use and share personal information about you. Smart Systems recognizes the need for appropriate protections and management of the personal information you provide us.
            This Privacy Policy is designed to both inform you of your rights in respect to the collection and storage of personal information, and to help us ensure that we comply with applicable law in the countries in which we operate.
            <p></p>
            <h4 style={h4Style}> Information We Collect</h4>
            Personal information is information or an opinion about you or which is reasonably identifiable as you, whether or not the information or opinion is true and whether or not the information is recorded in a material form.When you register for services that we may offer, place an order, meet us face - to - face meeting, correspond with us online, via mobile applications, mail, email or telephone, subscribe to our newsletter, or fill out any forms we provide you with, we may collect, the following personal information(without limitation):
            <ul>
                <li>Your name.</li>
                <li>Your date of birth.</li>
                <li>Your physical and/or postal address.</li>
                <li>Your email address.</li>
                <li>Your phone number.</li>
                <li> A copy of your passport (or other proof of identification).</li>
                <li>Credit information, such as details relating to your credit history, credit capacity, and credit eligibility.</li>
                <li>Records of our interactions with you.</li>
                <li>Your mobile money account number.</li>
            </ul>
            <p>
                In addition to the above, if you conduct a business or financial transaction with Smart Systems, or through a merchant or biller using a system that is managed by Smart Systems, we will collect payment and transaction data (including the name, amount, frequency, type, location, origin and recipients) from you at the time we process those transactions, as required in order to allow the transaction to be completed.
                We may collect personal information about:
            </p>
            <ul>
                <li>Contractors and suppliers.</li>
                <li>Third parties seeking or using our services.</li>
                <li>Other people who come into contact with us in the ordinary course of business
                    Applicants applying for a job or position with us: we may collect certain information from you (including your name, contact details, working history and relevant records checks) from any recruitment consultant, your previous employers and others who may be able to provide information to us to assist in our decision whether or not to make you an offer of employment or engage you under a contract. This Privacy Policy does not apply to acts and practices in relation to employee records of our current and former employees, which are exempt from privacy laws.
                    In some cases, we collect personal information from third parties including public sources, our related companies and our service providers.</li>
            </ul>

            <h4 style={h4Style}>Using Your Personal Information</h4>
            <p>Any of the personal information we collect from you may be used in one of the following ways:</p>
            <ul>
                <li>To undertake customer due diligence to ensure we comply with our obligations within the laws of Sierra Leone to prevent and (if necessary) report financial crimes.</li>
                <li>To personalize your experience of our Website and services (your personal information helps us to better respond to your individual needs)..</li>
                <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and support your needs)..</li>
                <li>To process transactions, or deliver purchased products or services requested.</li>
                <li>To administer the product(s) or service(s) we provide you (including by answering any question, requests or complaints you may have).</li>
                <li>To track traffic through our Website.</li>
                <li>To consider your employment or contractual engagement application.</li>
                <li>Where you have specifically consented to us doing so, to:
                    <p> Communicate with you in relation to products and services we offer which we feel may be of interest to you; and/or.</p>
                    <p> Communicate with you on behalf of our business partners.</p></li>
                • Such other purposes that we will notify you of at the time we collect your personal information.
                • The personal information you submit is gathered only on a voluntary basis; you may choose to not provide the personal information, although this may mean that you are unable to access certain services.
            </ul>
            <h4 style={h4Style}>Sharing Your Information</h4>
            <p>
                We do not sell personal information to third parties. Except as set out below, your personal information will not be exchanged, transferred, or given to third parties for any reason without your consent.
                We may share your personal information with third parties in the following circumstances:
            </p>
            <ul>
                <li>To the extent necessary to deliver purchased products or services requested.</li>
                <li>To enterprise resource planning (ERP) service providers, as necessary for the storage of your information on the cloud. Currently, Smart Systems stores data (including personal information) using various solutions.</li>
                <li>Registering a Security Interest against Smart Systems owned equipment.</li>
                <li>Where we believe in good faith that we are required to do so by law.</li>
                <li>We may provide non-personally identifiable information to other parties for marketing, advertising, or other uses.</li>
                <li>To track traffic through our Website.</li>
            </ul>

            <h4 style={h4Style}>Protecting Your Information</h4>
            <p>
                We implement a variety of security measures to safeguard against loss, access, use, modification or disclosure of your personal information when you enter, submit, or access your personal information.
                We will retain your personal information only for as long as necessary to fulfil the purpose(s) for which it was collected and to comply with applicable laws. Your consent to such purpose(s) remains valid after termination of our relationship with you.
                We will take all reasonable steps to ensure that your personal information is accurate, up to date, complete, relevant and not misleading.
                Storing & Transferring Your Personal Information
            </p>
            <p>
                Smart Systems’ business is operated in and from Sierra Leone. Accordingly, information is, in the first instance, processed in Sierra Leone.
                We may store your information in the cloud or on other types of electronic storage systems. All electronic storage system premises are located in Sierra Leone. Smart Systems ensures any cloud provider used is certified for security of the cloud in compliance with all the laws and regulations. Note that cloud agents are not to disclose information that Smart Systems stores on their services except as legally required.
                We take reasonable steps to ensure that all personal information that we collect is kept secure in accordance with industry standard physical, electronic and managerial procedures. All of our staff are trained to comply with this Privacy Policy. In respect of personal information collected online or stored electronically (including in the cloud) in particular, you acknowledge that no security system is impenetrable and that the security of such networks and systems can never be guaranteed. We also cannot guarantee that the information you supply will not be intercepted while being transmitted over the internet. Accordingly, any personal information which is transmitted to us is transmitted at your own risk.
            </p>
            <p>
                PCI DSS
                The Payment Card Industry Data Security Standard (“PCI DSS”) includes a number of security requirements relating to cardholder data. Smart Systems will collect full card number/data information for our card services, therefore Smart Systems does is to be PCi DSS compliant always.
            </p>

            <h4 style={h4Style}>Cookies</h4>
            <p>
                Cookies are small files that a site or its service provider transfers to your computer hard drive through your Web browser (if you allow) that enables the sites or service provider’s systems to recognize your browser and capture and remember certain information. We use cookies to:
            </p>
            <ul>
                <li>Track traffic patterns to our Website.</li>
                <li>Ensure that the most relevant content is being shown to you.</li>
                <li>Ensure that the most relevant content is being shown to you.</li>
                <li>Ensure that the most relevant content is being shown to you.</li>

            </ul>
            <p>
                If you would rather that we did not use cookies with you, you can refuse them by turning them off in your browser and/or deleting them from your hard drive. You will still be able to visit our Website.
            </p>

            <h4 style={h4Style}>Terms & Conditions
            </h4>
            <p>
                Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website at:
            </p>
            <ul>
                <li>
                    http://www.smartsystems.sl/terms-and-conditions/
                </li>
            </ul>
            <p>
                This Website may contain links to third party websites for your convenience and information. Smart Systems does not control these sites or their privacy practices. We do not endorse or make any representations about third party websites, and any personal information you choose to give these sites will not be covered by this privacy policy. We encourage you to review the privacy policy of any company or website before submitting your personal information.
            </p>

            <h4 style={h4Style}>Your Rights to Access Information</h4>
            <p>              We will take reasonable steps to ensure that any personal information we collect is accurate, up-to-date, complete, relevant and not misleading, and any personal information that we use or disclose is up-to-date, complete, accurate and relevant.
                You may contact us to access, or request correction or deletion of your personal information. However, if you have any questions regarding this Privacy Policy you may contact us on one of the following:</p>
            <ul>
                <li> Phone: 8080 (within Sierra Leone).</li>
                <li>Mail: 8 Regent Road, Hill Station, Freetown, Sierra Leone. Attention: Privacy Officer.</li>
                <li>Email: support@smartsystems.sl
                </li>

            </ul>
            <h4 style={h4Style}>Privacy Complaints</h4>
            <p>If you believe that we have breached this Privacy Policy, you may direct your complaint to us at support@smartsystems.sl or at 8 Regent Road, Hill Station, Freetown, Sierra Leone. Attention: Privacy Officer, please include your name, email and/or telephone number. We will review your complaint and liaise with you directly to resolve your complaint within a reasonable time.</p>

            <h4 style={h4Style}>Privacy Breaches & Escalations</h4>
            <p>
                Smart Pay takes Privacy seriously and any Privacy breaches will be escalated internally to the Smart Systems Executive Team and the Board.</p>
            <p>
                Smart Pay will follow the guidelines of the Privacy Commission in relation to the handling of any privacy breaches.
            </p>
        </div >
    );
};




export default privacyPolicy;