/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Alert,
} from "reactstrap";
// core components
import { useToasts } from "react-toast-notifications";
import { checkSpace, postMethod, getMethod } from "../../services/httpServices";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const initialValue = {
  firstName: "",
  lastName: "",
  gender: "",
  email: "",
  MobileCodeId: 232,
  MobileNumber: "",
  roleId: null,
  merchantId: null,
  merchantUserID: null,
  userCategory: null,
  id: 1,
};

const Merchant = (props) => {
  const [merchantUsers, setMerchantUsers] = useState([initialValue]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const [allMerchants, setAllMerchants] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);

  useEffect(() => {
    fetchAllMerchant();
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllMerchant = () => {
    if (allMerchants.length !== 0) return;
    setLoading(true);
    getMethod("/api/MerchantProfile/all", "get")
      .then((res) => {
        if (res.data.responseCode === "200") {
          setAllMerchants(res.data.result);
        }
      })
      .catch(() => {
        notifyFailed("Something went wrong!");
      })
      .finally(() => setLoading(false));
  };

  const fetchRoles = async () => {
    let errorMsg = "";
    setLoading(true);
    getMethod("/api/User/roles", "get")
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setRoles(
              res.data.result.filter((i) => i.normalizedName === "POSAgent")
            );
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching roles";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Something went wrong!, Please call 3737");
      })
      .finally(() => setLoading(false));
  };

  const handleInputChange = (e, idx) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      const updated = structuredClone(merchantUsers);
      updated[idx][name] = value;
      if (name === "roleId") {
        const role = roles.find((i) => i.id == value);
        updated[idx]["userCategory"] = role?.normalizedName;
      }
      setMerchantUsers(updated);
    }
  };

  const phoneChange = (e, idx) => {
    const updated = structuredClone(merchantUsers);
    updated[idx]["MobileNumber"] = e;
    setMerchantUsers(updated);
  };

  const closeModal = () => {
    props.closeModal();
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const validate = (inputs) => {
    const allErrors = [];
    if (inputs.length === 0) {
      inputs = structuredClone(merchantUsers);
    }
    inputs?.forEach((input) => {
      let obj = {};
      if (!input?.firstName?.trim()) {
        obj["firstName"] = "First name is required";
      }
      if (!input?.lastName?.trim()) {
        obj["lastName"] = "Last name is required";
      }
      if (!input?.MobileNumber?.trim()) {
        obj["MobileNumber"] = "Mobile number is required";
      }
      if (!input?.email?.trim()) {
        obj["email"] = "Email is required";
      }
      if (!input?.roleId?.trim()) {
        obj["roleId"] = "User Category is required";
      }
      allErrors.push(obj);
    });
    return allErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(merchantUsers)?.filter(
      (i) => Object.values(i).length > 0 && i
    );
    setErrors(validationErrors);
    if (validationErrors?.length) {
      return;
    }
    const currentMerchant = allMerchants.find((i) => i.id == selectedMerchant);
    const payload = merchantUsers.map((i) => ({
      ...i,
      merchantId: currentMerchant.id,
      merchantUserID: currentMerchant.userProfileId,
      MobileNumber: i.MobileNumber.slice(
        String(i.MobileCodeId).length,
        String(i.MobileNumber).length
      ),
    }));
    setLoading(true);
    postMethod("/api/MerchantProfile/SaveUsers", payload, "post")
      .then((res) => {
        if (res.data.responseCode === "200") {
          notifySuccess(res.data.responseMessage);
          props.closeModal();
        }
      })
      .catch(() => {
        notifyFailed("Something went wrong!");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {/* Page content */}
      <div className="p-0">
        <Row>
          <Col xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Merchant User Information</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={closeModal}
                      size="xs"
                      close
                      // className="fa fa-times"
                      style={{ cursor: "pointer", fontSize: "25px" }}
                    ></Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit} autoComplete="off">
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            for="input-roleId"
                            className="form-control-label"
                          >
                            Merchant
                          </label>
                          <Input
                            type="select"
                            id="input-roleId"
                            className="form-control-alternative"
                            onChange={(e) =>
                              setSelectedMerchant(e.target.value)
                            }
                          >
                            {/* {roles.map(c => <option key={c.key}>{c.value}</option>)} */}
                            <option key="" value="">
                              ------- Select -------
                            </option>
                            {allMerchants?.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item?.name}
                              </option>
                            ))}
                          </Input>
                          {errors.roleId && (
                            <div className="text-left text-danger">
                              <small>* {errors.roleId}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr className="my-4" />

                    {selectedMerchant &&
                      merchantUsers.map((user, idx) => (
                        <div key={idx}>
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-firstName"
                                >
                                  First Name
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-firstName"
                                  placeholder="First Name"
                                  type="text"
                                  name="firstName"
                                  value={user.firstName}
                                  onChange={(e) => handleInputChange(e, idx)}
                                />
                                {errors[idx]?.firstName && (
                                  <div className="text-left text-danger">
                                    <small>* {errors[idx]?.firstName}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-name"
                                >
                                  Last Name
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-lastName"
                                  placeholder="Last Name"
                                  type="text"
                                  name="lastName"
                                  value={user.lastName}
                                  onChange={(e) => handleInputChange(e, idx)}
                                />
                                {errors[idx]?.lastName && (
                                  <div className="text-left text-danger">
                                    <small>* {errors[idx]?.lastName}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>

                            <Col lg="4">
                              <FormGroup>
                                <label
                                  for="input-roleId"
                                  className="form-control-label"
                                >
                                  User Category
                                </label>
                                <Input
                                  type="select"
                                  id="input-roleId"
                                  className="form-control-alternative"
                                  name="roleId"
                                  value={user.roleId}
                                  onChange={(e) => handleInputChange(e, idx)}
                                >
                                  {/* {roles.map(c => <option key={c.key}>{c.value}</option>)} */}
                                  <option key="" value="">
                                    ------- Select Role -------
                                  </option>
                                  {roles.map((c) => (
                                    <option key={c.id} value={c.id}>
                                      {c.name}
                                    </option>
                                  ))}
                                </Input>
                                {errors[idx]?.roleId && (
                                  <div className="text-left text-danger">
                                    <small>* {errors[idx]?.roleId}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Email
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  placeholder="jesse@example.com"
                                  type="text"
                                  name="email"
                                  value={user.email}
                                  onChange={(e) => handleInputChange(e, idx)}
                                />
                                {errors[idx]?.email && (
                                  <div className="text-left text-danger">
                                    <small>* {errors[idx]?.email}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-bankphone"
                                >
                                  Phone Number
                                </label>
                                <PhoneInput
                                  className="form-control-alternative"
                                  country={"sl"}
                                  name="phoneWithCC"
                                  value={user.MobileNumber}
                                  // onlyCountries={MOBILE_ONLY_COUNTRY}
                                  countryCodeEditable={false}
                                  onChange={(e) => phoneChange(e, idx)}
                                />
                                {errors[idx]?.MobileNumber && (
                                  <div className="text-left text-danger">
                                    <small>* {errors[idx]?.MobileNumber}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>

                            {merchantUsers.length - 1 === idx && (
                              <Col lg="2">
                                <Button
                                  color="primary"
                                  className="mt-4"
                                  onClick={() =>
                                    setMerchantUsers([
                                      ...merchantUsers,
                                      {
                                        ...initialValue,
                                        id: merchantUsers.length + 1,
                                      },
                                    ])
                                  }
                                >
                                  Add More
                                </Button>
                              </Col>
                            )}
                            {merchantUsers.length !== 1 && (
                              <Col lg="2">
                                <Button
                                  color="primary"
                                  style={{
                                    background: "crimson",
                                  }}
                                  className="mt-4"
                                  onClick={() =>
                                    setMerchantUsers(
                                      merchantUsers.filter(
                                        (_, index) => index !== idx
                                      )
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </Col>
                            )}
                          </Row>
                          <hr className="my-4" />
                        </div>
                      ))}
                  </div>
                  {selectedMerchant && (
                    <Alert color="info">
                      <span>
                        Merchant users, also known as POS agents, will use their
                        8-digit mobile or phone number as their Agent ID when
                        logging into the POS device, machine, or terminal. Upon
                        their first login, they will be required to enter a
                        One-Time Password (OTP), which will be sent to their
                        registered mobile number. The default password for
                        first-time login is 00000000. After OTP verification,
                        they will be prompted to set their own PIN or password
                        for future logins.
                      </span>
                    </Alert>
                  )}

                  {selectedMerchant && (
                    <div className="d-flex justify-content-end">
                      <Button
                        color="primary"
                        className=""
                        type="submit"
                        disabled={loading}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Merchant;
