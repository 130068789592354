import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { useToasts } from "react-toast-notifications";
import {
  checkSpace,
  getMethod,
  getUserName,
  postMethod,
} from "../../services/httpServices";
import PhoneInput from "react-phone-input-2";

const initialValue = {
  id: 0,
  fspCategory: "",
  fspName: "",
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  mobileCodeId: "",
  mobileNumber: "",
  userId: "",
  userStatus: "Pending",
  createBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  isDeleted: false,
  isBlocked: false,
};

const AddFspUser = (props) => {
  const [values, setValues] = React.useState(initialValue);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const { addToast } = useToasts();
  const [fsps, setFsps] = useState([]);
  const changeState = (obj) => {
    setValues(obj);
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const closeModal = () => {
    props.closeModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      changeState({ ...values, [name]: value });
    }
  };
  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      changeState({
        ...values,
        mobileCodeId: data.dialCode,
        mobileNumber: value.substring(data.dialCode.length),
      });
    }
    return;
  };
  useEffect(() => {
    fetchAllFsps();
  }, []);

  const fetchAllFsps = async () => {
    let errorMsg = "";
    setLoading(true);
    getMethod("/api/FSP/GetAll", "get")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data?.result?.length) {
              setFsps([]);
            } else {
              setFsps(res.data.result);
            }
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const validate = (inputs) => {
    let errors = {};
    if (!inputs.fspCategory) {
      errors.fspCategory = "Fsp Category is required";
    }
    if (!inputs.fspName) {
      errors.fspName = "Fsp Name is required";
    }
    if (!inputs.firstName) {
      errors.firstName = "First Name is required";
    }
    if (!inputs.lastName) {
      errors.lastName = "Last Name is required";
    }
    if (!inputs.email) {
      errors.email = "Email is required";
    }
    if (!inputs.mobileCodeId) {
      errors.mobileCodeId = "Mobile Code Id is required";
    }
    if (!inputs.mobileNumber) {
      errors.mobileNumber = "Mobile No  is required";
    }

    return errors;
  };

  /**======================== API Calls ==========================*/

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      const payload = {
        ...values,
        createBy: getUserName(),
        createdDate: new Date().toISOString(),
        updatedBy: getUserName(),
        updatedDate: new Date().toISOString(),
        isDeleted: false,
        isBlocked: false,
        mobileCodeId: Number(values.mobileCodeId)
      };
      let errorMsg = "";
      setLoading(true);
      postMethod("/api/FSP/Profile/User/Save", payload, "post")
        .then((res) => {
          setLoading(false);
          if (res && res.data) {
            if (res.data.responseCode === "200") {
              notifySuccess("Saved Successfully");
              closeModal();
            } else if (res.data.responseCode === "400") {
              // Error while saving
              errorMsg = res.data.responseMessage || "Error while saving";
              notifyFailed(errorMsg);
            } else {
              // Unknown Error
              errorMsg = "Unknown Error in saving";
              notifyFailed(errorMsg);
            }
          } else {
            // Not valid response from server
            errorMsg = "Unknown Error";
            notifyFailed(errorMsg);
          }
        })
        .catch((err) => {
          setLoading(false);
          errorMsg = "Something went wrong!, Please call 3737";
          notifyFailed(errorMsg);
        });
    }
  };

  return (
    <div className="p-0">
      <Row>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Add FSP User</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    color="primary"
                    onClick={closeModal}
                    size="xs"
                    close
                    // className="fa fa-times"
                    style={{ cursor: "pointer", fontSize: "25px" }}
                  ></Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-fspName"
                        >
                          FSP Name
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-fspName"
                          placeholder="FSP Name"
                          type="select"
                          name="fspName"
                          required
                          value={values.fspName}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              fspName: e.target.value,
                              fspCategory: fsps.find(
                                (i) => i.fspName == e.target.value
                              )?.fspCategory,
                            });
                          }}
                        >
                          <option key="" value="">
                            ------- Select -------
                          </option>
                          {fsps?.map((item) => (
                            <option key={item.id} value={item.fspName}>
                              {item?.fspName}
                            </option>
                          ))}
                        </Input>
                        {errors.fspName && (
                          <div className="text-left text-danger">
                            <small>* {errors.fspName}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>

                    {values.fspName !== "" && (
                      <>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-fspCategory"
                            >
                              FSP Category
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-fspCategory"
                              placeholder="FSP Category"
                              type="text"
                              name="fspCategory"
                              disabled
                              value={values.fspCategory}
                              onChange={handleInputChange}
                            />
                            {errors.fspCategory && (
                              <div className="text-left text-danger">
                                <small>* {errors.fspCategory}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-firstName"
                            >
                              First Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-firstName"
                              placeholder="First Name"
                              type="text"
                              name="firstName"
                              required
                              value={values.firstName}
                              onChange={handleInputChange}
                            />
                            {errors.firstName && (
                              <div className="text-left text-danger">
                                <small>* {errors.firstName}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-middleName"
                            >
                              Middle Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-middleName"
                              placeholder="middleName"
                              type="text"
                              name="middleName"
                              value={values.middleName}
                              onChange={handleInputChange}
                            />
                            {errors.middleName && (
                              <div className="text-left text-danger">
                                <small>* {errors.middleName}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-lastName"
                            >
                              Last Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-lastName"
                              placeholder="lastName"
                              type="text"
                              required
                              name="lastName"
                              value={values.lastName}
                              onChange={handleInputChange}
                            />
                            {errors.lastName && (
                              <div className="text-left text-danger">
                                <small>* {errors.lastName}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-email"
                              required
                              placeholder="jesse@example.com"
                              type="email"
                              name="email"
                              value={values.email}
                              onChange={handleInputChange}
                            />
                            {errors.email && (
                              <div className="text-left text-danger">
                                <small>* {errors.email}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-adminContactMobileNo"
                            >
                              Mobile No
                            </label>
                            <PhoneInput
                              className="form-control-alternative"
                              country={"sl"}
                              name="adminContactMobileNo"
                              value={values.mobileCodeId + values.mobileNumber}
                              required
                              countryCodeEditable={false}
                              onChange={phoneChange}
                            />
                            {errors.mobileNumber && (
                              <div className="text-left text-danger">
                                <small>* {errors.mobileNumber}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>

                <Button
                  type="submit"
                  color="primary"
                  className="mt-4 float-right"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddFspUser;
