/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Col,
  ModalHeader,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import {
  getMethod,
  getUserName,
  postMethod,
} from "../../services/httpServices";
import { useToasts } from "react-toast-notifications";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { btnCss, iconCss } from "../../appConfig";
import jsPDF from "jspdf";
import ReactPaginate from "react-paginate";

const sortIS = {
  sortMerchantUID: 0,
  sortMerchantName: 0,
  sortFspName: 0,
  sortMerchantAccountNumber: 0,
  sortPendingBalance: 0,
};

const SettlementProcessList = () => {
  const [loading, setLoading] = React.useState(false);
  const [filteredList, setFilteredList] = React.useState([]);
  const [merchantBanks, setMerchantBanks] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize] = React.useState(10);
  const [deleteObj, setDeleteObj] = React.useState(null);
  const [openDelModal, setOpenDelModal] = React.useState(false);
  const [openBalModal, setOpenBalModal] = React.useState(false);
  const [totalInfo, setTotalInfo] = React.useState(null);
  const [errors, setErrors] = useState({});
  const [searchText, setSearchText] = useState("");
  const [totalRec, setTotalRec] = useState(0);
  const [pagesCount, setPageCount] = useState(0);
  const [selectedMerchantAcc, setSelectedMerchantAcc] = useState({});
  const [values, setValues] = React.useState({
    paymentType: "",
    settlementType: "",
    amount: 0,
    refNum: "",
    description: "",
    bankAccountId: "",
  });
  const [refresh, setRefresh] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [sort, setSort] = React.useState(sortIS);
  const [balanceInfo, setBalanceInfo] = useState({});

  const { addToast } = useToasts();

  React.useEffect(() => {
    fetchTotalInfo();
    fetchBalanceInfo();
    fetchSettlementsList();
  }, []);

  const getSearch = (val) => {
    setSearchText(val);
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const openDelete = (mmo) => {
    setDeleteObj({ ...mmo, totalAmount: mmo.pendingBalance });
    setOpenDelModal(true);
  };

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    if (name === "paymentType" && value === "Offline") {
      setSelectedMerchantAcc({});
    }
    if (name === "paymentType" && value === "Online") {
      fetchMerchantBanks();
    }

    if (name === "settlementType" && value === "Full") {
      setValues({
        ...values,
        amount: deleteObj.pendingBalance,
        settlementType: value,
      });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const reloadList = () => {
    setCurrentPage(0);
    fetchSettlementsList();
    fetchTotalInfo();
    fetchBalanceInfo();
    setSearchText("");
    setRefresh(true);
  };

  const exportPDF = (reportData) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 10;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Settlements List";
    const headers = [
      [
        "MERCHANT UID",
        "MERCHANT NAME",
        "FSP NAME",
        "ACCOUNT NUMBER",
        "PENDING BALANCE",
      ],
    ];
    const data = filteredList?.map((mmo) => [
      mmo.merchantUID,
      mmo.merchantName,
      mmo.fspName,
      mmo.merchantAccountNumber,
      mmo.pendingBalance,
    ]);

    let content = {
      startY: 50,
      head: headers,
      margin: { left: 10, right: 10 },
      body: true
        ? [
            ...data,
            [
              {
                content: ``,
                colSpan: 12,
                styles: { fillColor: "#ffffff" },
              },
            ],
            [
              {
                content: `Total Pending Settlement Account(TPSA): ${totalInfo?.settlementPendingAmount}`,
                colSpan: 12,
                styles: { fillColor: "#ffffff" },
              },
            ],
            [
              {
                content: `Gross Collection Account Balance(GCAB): ${totalInfo?.collectionAccountBalance}`,
                colSpan: 12,
                styles: { fillColor: "#ffffff" },
              },
            ],
          ]
        : data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
  };

  const validate = (inputs) => {
    const err = {};
    if (inputs.paymentType === "Online" && !inputs.bankAccountId) {
      err.bankAccountId = "This field is required";
    }
    if (inputs.paymentType === "Offline" && !inputs.refNum?.trim().length) {
      err.refNum = "This field is required";
    }
    if (!inputs.settlementType) {
      err.settlementType = "This field is required";
    }
    if (!inputs.amount || !Number(inputs?.amount)) {
      err.amount = "This must greater than 0";
    }

    if (Number(inputs.amount) > Number(deleteObj?.totalAmount)) {
      err.amount = `Amount must be less than total amount (Le. ${deleteObj?.totalAmount})."`;
    }

    return err;
  };

  const submitSettleMentData = async (payload) => {
    let errorMsg = "";
    setLoading(true);
    postMethod(
      "/api/Transactions/V2/SettelementProcess",
      {
        ...payload,
      },
      "post"
    )
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            notifySuccess("Success");
            fetchSettlementsList();
            fetchTotalInfo();
            fetchBalanceInfo();
            closeDeleteModal();
            // Success
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        setTotalInfo(null);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };
  const fetchTotalInfo = async () => {
    let errorMsg = "";
    setLoading(true);
    postMethod(
      "/api/Transactions/V2/CollectedAndSettlementAmountList",
      {},
      "post"
    )
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            setTotalInfo(res.data?.result);
          }
          // else if (res.data.responseCode === "400") {
          //   // Error
          //   errorMsg = res.data.responseMessage || "Error in fetching list";
          //   notifyFailed(errorMsg);
          // } else {
          //   // Unknown Error
          //   errorMsg = "Unknown Error in fetching";
          //   notifyFailed(errorMsg);
          //   setTotalInfo(null);
          // }
        } else {
          // errorMsg = "Unknown Error";
          notifyFailed("Something went wrong!, Please call 3737");
          setTotalInfo(null);
        }
      })
      .catch((err) => {
        setLoading(false);
        setTotalInfo(null);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const fetchBalanceInfo = async () => {
    let errorMsg = "";
    setLoading(true);
    getMethod("/api/Transactions/V2/GetCollectionBankAccountBalance", "get")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            setBalanceInfo(res.data.result);
          }
          // else if (res.data.responseCode === "400") {
          //   // Error
          //   errorMsg = res.data.responseMessage || "Error in fetching list";
          //   notifyFailed(errorMsg);
          //   setBalanceInfo({});
          // } else {
          //   // Unknown Error
          //   errorMsg = "Unknown Error in fetching";
          //   notifyFailed(errorMsg);
          // }
        } else {
          // errorMsg = "Unknown Error";
          notifyFailed("Something went wrong!, Please call 3737");
          setBalanceInfo({});
        }
      })
      .catch((err) => {
        setLoading(false);
        setTotalInfo(null);
        setBalanceInfo({});
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  useEffect(() => {
    if (!refresh && isMounted) {
      if ((searchText && searchText.length >= 3) || searchText.length == 0) {
        fetchSettlementsList();
      }
    }
  }, [searchText]);
  const fetchSettlementsList = async () => {
    let errorMsg = "";
    setLoading(true);
    let data = {
      PageNumber: Number(currentPage + 1),
      PerPage: pageSize,
    };
    if (searchText && searchText.length >= 3) {
      data["SearchText"] = searchText;
      // data['PageNumber'] = 0;
    }
    if (sort.sortMerchantUID === "1") {
      data["SortingOn"] = "merchantUID";
      data["IsDescending"] = false;
    } else if (sort.sortMerchantName === "2") {
      data["SortingOn"] = "merchantName";
      data["IsDescending"] = true;
    } else if (sort.sortFspName === "1") {
      data["SortingOn"] = "fspName";
      data["IsDescending"] = false;
    } else if (sort.sortMerchantAccountNumber === "2") {
      data["SortingOn"] = "merchantAccountNumber";
      data["IsDescending"] = true;
    } else if (sort.sortPendingBalance === "1") {
      data["SortingOn"] = "pendingBalance";
      data["IsDescending"] = false;
    }
    postMethod("/api/Transactions/V2/GetSettlementList", data, "post")
      .then((res) => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            setFilteredList(res.data?.result?.list);
            let pc = Math.ceil(res.data.result.totalRecords / pageSize);
            setTotalRec(res.data.result.totalRecords);
            setPageCount(pc);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsMounted(true);
        setRefresh(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const fetchMerchantBanks = async () => {
    let errorMsg = "";
    setLoading(true);
    getMethod(
      "/api/MerchantProfile/GetBankAccount/" + deleteObj?.merchantId,
      "get"
    )
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            const filtered = [];
            res.data?.result.forEach((i) => {
              if (!filtered.find((j) => j.fspId === i.fspId)) {
                filtered.push(i);
              }
            });

            setMerchantBanks(filtered);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        fetchSettlementsList();
      }
    }
  }, [sort]);

  const closeDeleteModal = () => {
    setDeleteObj(null);
    setOpenDelModal(false);
    setValues({ paymentType: "", settlementType: "" });
    setMerchantBanks([]);
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Saved successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const submitSettleMent = (e) => {
    e.preventDefault();
    const payload = {
      merchantId: Number(deleteObj?.merchantId),
      merchantUserId: deleteObj?.merchantUserId, //merchantUserId
      fspId: Number(
        selectedMerchantAcc?.fspId
          ? selectedMerchantAcc?.fspId
          : deleteObj?.fspId
      ), //FSPid
      MerchantAccountId: selectedMerchantAcc?.accountId ?? null, //acc
      toId: Number(deleteObj?.merchantId),
      toName: deleteObj?.merchantName, //merchantname
      settlementType: values.paymentType,
      type: values?.paymentType,
      referance: values?.paymentType === "Offline" ? values?.refNum : "Online",
      settledAmount:
        values?.paymentType === "Full"
          ? Number(deleteObj?.pendingBalance)
          : Number(values.amount),
      remainingAmount:
        values?.paymentType === "Full"
          ? 0
          : Number(deleteObj?.pendingBalance) - Number(values.amount),
      settledBy: getUserName(), //login user name
      createdBy: getUserName(), //login user name
      createdDate: new Date().toISOString(),
      toBeSettledAmount: Number(deleteObj?.pendingBalance),
    };

    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");

    if (isNoError) {
      submitSettleMentData(payload);
    }
  };

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = "1";
    } else if (fieldValue == "1") {
      newValue = "2";
    } else if (fieldValue == "2") {
      newValue = "1";
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  };

  const getSortCls = (fieldName) => {
    if (fieldName == "0") {
      return "fas fa-sort fa-lg";
    } else if (fieldName == "1") {
      return "fas fa-sort-up fa-lg";
    } else if (fieldName == "2") {
      return "fas fa-sort-down fa-lg";
    }
  };

  return (
    <>
      <Header />
      <Modal isOpen={openBalModal} className="modal-sm">
        <ModalHeader className="bg-white border-0 d-flex position-relative w-100 justify-content-between flex-items-middle">
          <h3 className="mb-0">Account Balance</h3>
          <h6
            onClick={() => setOpenBalModal(false)}
            className="position-absolute right-4 top-4"
          >
            <i
              class="fas fa-times text-white"
              style={{ cursor: "pointer" }}
            ></i>
          </h6>
        </ModalHeader>
        <ModalBody>
          <div>
            {Object.entries(balanceInfo)?.length > 0 && (
              <div>
                {Object.entries(balanceInfo).map(([key, val]) => (
                  <div key={key}>
                    <h5>
                      {String(key).charAt(0).toUpperCase() +
                        String(key).slice(1)}
                      : {val}
                    </h5>
                  </div>
                ))}
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}

                <Form
                  className="form-inline mr-3 d-md-flex ml-lg-auto"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  {filteredList.length !== 0 && (
                    <Button
                      color="primary"
                      onClick={() => exportPDF(filteredList)}
                    >
                      <i class="fas fa-download pr-2"></i>
                      Download list
                    </Button>
                  )}
                  <FormGroup className="mb-0 navbar-search ">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="search-cls"
                        placeholder="Search"
                        type="text"
                        value={searchText}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  &nbsp;&nbsp;
                  <Button
                    color="primary"
                    onClick={reloadList}
                    size="sm"
                    style={btnCss}
                    data-toggle="tooltip"
                    title="Refresh"
                  >
                    <i class="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>
                </Form>
              </CardHeader>
              <Modal isOpen={openDelModal}>
                <ModalHeader className="bg-white border-0 w-100 position-relative">
                  <h3 className="mb-0">Settlement Process</h3>

                  <Button
                    color="primary"
                    onClick={closeDeleteModal}
                    size="xs"
                    close
                    // className="fa fa-times"
                    style={{
                      cursor: "pointer",
                      fontSize: "25px",
                      position: "absolute",
                      right: "24px",
                      top: "24px",
                    }}
                  ></Button>
                </ModalHeader>

                <ModalBody>
                  <Form onSubmit={submitSettleMent}>
                    <h4>
                      Settlement Amount: Le <b>{deleteObj?.pendingBalance}</b>
                    </h4>
                    <FormGroup tag="fieldset">
                      <strong>Payment Type</strong>
                      <Row>
                        <Col lg="4">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="paymentType"
                                value="Online"
                                checked={values.paymentType === "Online"}
                                onChange={handleInputChange}
                              />{" "}
                              Online
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="paymentType"
                                value="Offline"
                                checked={values.paymentType === "Offline"}
                                onChange={handleInputChange}
                              />{" "}
                              Offline
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </FormGroup>
                    {values?.paymentType === "Online" && (
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              for="input-bank"
                              className="form-control-label"
                            >
                              Bank Account
                            </label>
                            <Input
                              type="select"
                              id="input-bank"
                              className="form-control-alternative"
                              name="bankAccountId"
                              required
                              value={values.bankAccountId}
                              onChange={(e) => {
                                handleInputChange(e);
                                setSelectedMerchantAcc(
                                  merchantBanks.find(
                                    (k) => k.accountId == e.target.value
                                  )
                                );
                              }}
                            >
                              <option value="">
                                -----Select Bank Account-----
                              </option>
                              {merchantBanks &&
                                merchantBanks.map((c) => (
                                  <option key={c.accountId} value={c.accountId}>
                                    {c.accountId}-{c.fspName}
                                  </option>
                                ))}
                            </Input>
                            {errors.bankAccountId && (
                              <div className="text-left text-danger">
                                <small>* {errors.bankAccountId}</small>
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    {values?.paymentType !== "" && (
                      <FormGroup tag="fieldset">
                        <strong>Settlement Type</strong>
                        <Row>
                          <Col lg="6">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="settlementType"
                                  value="Full"
                                  checked={values.settlementType === "Full"}
                                  onChange={handleInputChange}
                                />{" "}
                                Full Settlement
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="settlementType"
                                  value="Partial"
                                  checked={values.settlementType === "Partial"}
                                  onChange={handleInputChange}
                                />{" "}
                                Partial Settlement
                              </Label>
                              {errors.settlementType && (
                                <div className="text-left text-danger">
                                  <small>* {errors.settlementType}</small>
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label mt-2"
                                htmlFor="input-amount"
                              >
                                Amount
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-amount"
                                placeholder="Amount"
                                type="number"
                                min={1}
                                max={`${
                                  deleteObj?.totalAmount || values?.totalAmount
                                }`}
                                required
                                disabled={values.settlementType === "Full"}
                                name="amount"
                                value={values.amount}
                                onChange={handleInputChange}
                              />
                              {errors.amount && (
                                <div className="text-left text-danger">
                                  <small>* {errors.amount}</small>
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          {values?.paymentType === "Offline" && (
                            <>
                              <Col lg="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-refNum"
                                  >
                                    Reference Number
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-refNum"
                                    placeholder="Ref. Number"
                                    type="text"
                                    min={1}
                                    required
                                    name="refNum"
                                    value={values.refNum}
                                    onChange={handleInputChange}
                                  />
                                  {errors.refNum && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.refNum}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                            </>
                          )}
                        </Row>
                      </FormGroup>
                    )}
                    {values.paymentType && (
                      <div className="d-flex justify-content-between align-items-center">
                        <h5
                          onClick={closeDeleteModal}
                          style={btnCss}
                          disabled={loading}
                        >
                          Cancel
                        </h5>
                        <Button
                          color="primary"
                          // style={btnCss}
                          disabled={
                            loading ||
                            (values?.paymentType === "Partial" &&
                              values.totalAmount < 1)
                          }
                          onClick={submitSettleMent}
                        >
                          {loading ? "Loading..." : "Submit"}
                        </Button>
                      </div>
                    )}
                  </Form>
                </ModalBody>
              </Modal>
              {totalInfo && !loading && (
                <div className="pl-4 text-sm mb-2">
                  <h5>
                    <b>Total Pending Settlement Account(TPSA):</b> &nbsp;&nbsp;
                    Le {totalInfo?.settlementPendingAmount}
                  </h5>
                  <h5>
                    <b>Gross Collection Account Balance(GCAB):</b> &nbsp;&nbsp;
                    Le {totalInfo?.collectionAccountBalance}{" "}
                    <i
                      style={{ cursor: "pointer" }}
                      onClick={() => setOpenBalModal(true)}
                      class="fas text-blue text-primary fa-info-circle pl-2 text-body"
                    ></i>{" "}
                  </h5>
                </div>
              )}
              {loading && <div className="text-sm p-4">Loading balance...</div>}
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th
                      scope="col"
                      onClick={() =>
                        sortHandle(sort.sortMerchantUID, "sortMerchantUID")
                      }
                    >
                      Merchant UID{" "}
                      <button style={btnCss}>
                        <i
                          className={getSortCls(sort.sortMerchantUID)}
                          onClick={() =>
                            sortHandle(sort.sortMerchantUID, "sortMerchantUID")
                          }
                        />
                      </button>
                    </th>
                    <th
                      scope="col"
                      onClick={() =>
                        sortHandle(sort.sortMerchantName, "sortMerchantName")
                      }
                    >
                      Merchant name{" "}
                      <button style={btnCss}>
                        <i
                          className={getSortCls(sort.sortMerchantName)}
                          onClick={() =>
                            sortHandle(
                              sort.sortMerchantName,
                              "sortMerchantName"
                            )
                          }
                        />
                      </button>
                    </th>

                    <th
                      scope="col"
                      onClick={() =>
                        sortHandle(
                          sort.sortMerchantAccountNumber,
                          "sortMerchantAccountNumber"
                        )
                      }
                    >
                      Account Number{" "}
                      <button style={btnCss}>
                        <i
                          className={getSortCls(sort.sortMerchantAccountNumber)}
                          onClick={() =>
                            sortHandle(
                              sort.sortMerchantAccountNumber,
                              "sortMerchantAccountNumber"
                            )
                          }
                        />
                      </button>
                    </th>
                    <th
                      scope="col"
                      onClick={() =>
                        sortHandle(
                          sort.sortPendingBalance,
                          "sortPendingBalance"
                        )
                      }
                    >
                      Pending Balance{" "}
                      <button style={btnCss}>
                        <i
                          className={getSortCls(sort.sortPendingBalance)}
                          onClick={() =>
                            sortHandle(
                              sort.sortPendingBalance,
                              "sortPendingBalance"
                            )
                          }
                        />
                      </button>
                    </th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredList
                    // .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                    .map((mmo, i) => {
                      return (
                        <tr>
                          <th scope="row">{mmo.merchantUID}</th>
                          <td>{mmo.merchantName}</td>
                          <td>{mmo.merchantAccountNumber}</td>
                          <td>{mmo.pendingBalance}</td>
                          <td>
                            <Button
                              color="primary"
                              onClick={() => openDelete(mmo)}
                              size="sm"
                              style={btnCss}
                              data-toggle="tooltip"
                              disabled={mmo.pendingBalance <= 0}
                              title="Initiate Settlement"
                            >
                              <i class="fas fa-handshake" style={iconCss}></i>X{" "}
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                {loading && <FullPageLoader label={"Fetching..."} />}
                {!loading && !filteredList.length && <NoRecordFound />}
              </Table>
              <CardFooter className="py-4">
                {filteredList.length > 0 && (
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>Total Records : {totalRec}</strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6">
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage}
                          />
                        </div>
                      </nav>
                    </Col>
                  </Row>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SettlementProcessList;
