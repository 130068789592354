import { 
  PAYMENT_FECTH_LIST, PAYMENT_SET_LIST,
  PAYMENT_FETCH_ERROR, RESET_PAYMENT_LIST,
  COLLECTION_BANK_ERROR, COLLECTION_BANK_LIST, 
  COLLECTION_BANK_SET_LIST, 
  RESET_COLLECTION_BANK_LIST
} from './constant';
import { DEFAULT_ACTION } from './constant';

export const paymentInitialState = {
  loading: false,
  list: [],
  error: false,
  totalRec: 0
};

export const paymentReducer = (state = paymentInitialState, action) => {
  switch (action.type) {
    case DEFAULT_ACTION:
      break;
    case PAYMENT_FECTH_LIST:
      return { ...state, loading: true}
    case PAYMENT_SET_LIST:
      return { 
        ...state, 
        list: action.payload.list, 
        totalRec: action.payload.totalRecords, 
        loading: false
      }
    case PAYMENT_FETCH_ERROR:
      return {
        ...state, loading: false, list: [],
        error: true, errorMsg: action.payload
      }
    case RESET_PAYMENT_LIST: 
      return {
        ...state, loading: false, list: [], error: false
      }
    default:
      return { ...state };
  }
};

export const cbankListIS = {
  loading: false,
  list: [],
  error: false
};

export const cBankListReducer = (state = cbankListIS, action) => {
  switch (action.type) {
    case DEFAULT_ACTION:
      break;
    case COLLECTION_BANK_LIST:
      return { ...state, loading: true}
    case COLLECTION_BANK_SET_LIST:
      return { ...state, list: action.payload, loading: false}
    case COLLECTION_BANK_ERROR:
      return {
        ...state, loading: false, list: [],
        error: true, errorMsg: action.payload
      }
    case RESET_COLLECTION_BANK_LIST: 
      return {
        ...state, loading: false, list: [], error: false
      }
    default:
      return { ...state };
  }
};