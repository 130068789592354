import React from "react";
import { Card, CardBody, Row } from "reactstrap";

const CardAssignmentInstructions = () => {
  return (
    <Row>
      <div className="col">
        <Card className="shadow p-4">
          <CardBody>
            <h2>Card Assignment Instructions for Admin Users</h2>

            <h4>Step 1: Create a Card Scheme</h4>
            <ol>
              <li>
                <h4>Navigate to the Card Scheme Management Section:</h4>
                <ul>
                  <li>
                    Access the backend admin portal and go to the "Card Schemes"
                    section.
                  </li>
                </ul>
              </li>
              <li>
                <h4>Define Card Scheme Details:</h4>
                <ul>
                  <li>
                    Enter Issuer Information: Include the issuer's name and
                    details.
                  </li>
                  <li>
                    Configure Card Usage Rules:
                    <ul>
                      <li>
                        Specify whether PIN Rules will apply when a customer
                        taps the card.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <h4>Set the Card Application Type:</h4>
                <ul>
                  <li>
                    Choose between Open or Closed application types:
                    <ul>
                      <li>
                        Open: Allows the card to be used at any SmartPay POS
                        terminal.
                      </li>
                      <li>
                        Closed: Restricts card usage to a specific group of POS
                        terminals.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <h4>Save the Card Scheme:</h4>
                <ul>
                  <li>
                    Ensure all required details are correctly entered before
                    saving.
                  </li>
                </ul>
              </li>
            </ol>

            <h4>Step 2: Assign the Card Scheme to a Specific Card</h4>
            <ol>
              <li>
                <h4>Navigate to the Card Management Section:</h4>
                <ul>
                  <li>
                    Access the "Card Management" section in the backend admin
                    portal.
                  </li>
                </ul>
              </li>
              <li>
                <h4>Select the Card:</h4>
                <ul>
                  <li>
                    Locate the card to which the scheme will be assigned (e.g.,
                    by card number or identifier).
                  </li>
                </ul>
              </li>
              <li>
                <h4>Assign the Card Scheme:</h4>
                <ul>
                  <li>
                    From the list of available card schemes, select the
                    appropriate scheme for the card.
                  </li>
                  <li>Save the changes to complete the assignment.</li>
                </ul>
              </li>
            </ol>

            <h4>
              Step 3: Create a POS Terminal Group (For Closed Application Type)
            </h4>
            <ol>
              <li>
                <h4>Navigate to the POS Terminal Group Section:</h4>
                <ul>
                  <li>
                    Go to the "POS Terminal Groups" section in the backend admin
                    portal.
                  </li>
                </ul>
              </li>
              <li>
                <h4>Create a New Group:</h4>
                <ul>
                  <li>
                    Provide a Group Name:
                    <ul>
                      <li>
                        Ensure the group name is unique. Duplicate names are not
                        allowed.
                      </li>
                      <li>
                        An error message will appear if a duplicate name is
                        entered.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Assign relevant POS terminals to this group:
                    <ul>
                      <li>
                        Add one or more POS terminals where the card will be
                        valid.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ol>

            <h4>Step 4: Assign the Card to a Customer</h4>
            <ol>
              <li>
                <h4>Select the Customer:</h4>
                <ul>
                  <li>
                    Search for the customer using their details (e.g., name,
                    customer ID).
                  </li>
                  <li>
                    Navigate to the "Card Assignment" section under their
                    profile.
                  </li>
                </ul>
              </li>
              <li>
                <h4>Link the Card to the Customer:</h4>
                <ul>
                  <li>
                    Choose the card you wish to assign from the list of
                    available cards.
                  </li>
                  <li>
                    Ensure the selected card follows the rules defined in the
                    assigned card scheme.
                  </li>
                </ul>
              </li>
              <li>
                <h4>Verify and Save:</h4>
                <ul>
                  <li>Review the details (card scheme, POS group, etc.).</li>
                  <li>Confirm and save the assignment.</li>
                </ul>
              </li>
            </ol>

            <h4>Key Considerations for Admin Users</h4>
            <ul>
              <li>
                <h4>Card Scheme Assignment:</h4>
                <ul>
                  <li>
                    Each card must have a card scheme assigned before being
                    linked to a customer.
                  </li>
                  <li>
                    Ensure the card scheme settings (e.g., PIN rules,
                    application type) align with the intended usage of the card.
                  </li>
                </ul>
              </li>
              <li>
                <h4>POS Terminal Group Validations:</h4>
                <ul>
                  <li>
                    Group names must be unique. Duplicate names will trigger
                    validation errors.
                  </li>
                  <li>
                    Ensure all required POS terminals are included in the group
                    before assigning the card.
                  </li>
                </ul>
              </li>
            </ul>
          </CardBody>
        </Card>
      </div>
    </Row>
  );
};

export default CardAssignmentInstructions;
