import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardSubtitle,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { useToasts } from "react-toast-notifications";
import { checkSpace, postMethod } from "services/httpServices";
import { connect } from "react-redux";
import classNames from "classnames";
import BetSalonePaymentTab from "./BetSalonePaymentTab";
import SmartKorporPaymentTab from "./SmartKorporPaymentTab";
import EDSAPayment from "./EDSAPayment";
import { getMethod } from "services/httpServices";
import ElephantBetsPaymentTab from "./ElephantBetsPaymentTab";
import LapoLoanRepaymentTab from "components/makePaymentScreens/lapo-loan-repayment";

// bets alone
const currencyTypes = [
  {
    key: "eur",
    value: "eur",
    label: "EUR",
  },
];
const langs = [
  {
    key: "en",
    value: "en",
    label: "En",
  },
];
// bets alone

const billTypes = [
  {
    id: 1,
    name: "Elephant Bet",
    color: "#2b3b84",
  },
  {
    id: 2,
    name: "SmartKorpor",
    color: "#2b3b84",
  },
  {
    id: 3,
    name: "EDSA",
    color: "#2b3b84",
  },
  {
    id: 4,
    name: "Bet salone",
    color: "#2b3b84",
  },
  {
    id: 5,
    name: "Lapo Loan Repayment",
    color: "#2b3b84",
  },
];

// elephant
const initialValue = {
  agentId: "",
  companyName: "",
  companyId: "",
  amount: null,
  pin: "",
  bettingCompany: "",
};

const initialValue1 = {
  refNo: "",
  amount: null,
  currency: "",
  customername: "",
  customer_account_number: "",
  pin: "",
};

const initialValue2 = {
  amount: "",
  billNumber: "",
  pin: "",
};

// bet salone
const initialValue3 = {
  phone: "",
  currency: "",
  lang: "",
  amount: null,
  pin: "",
  betSaloneToken: "",
};

// lapo loan repayment
const initialValue5 = {
  accNo: "",
  amount: 0,
  pin: "",
};

const MakePayment = (props) => {
  //elephant bet
  const [values, setValues] = useState(initialValue);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(1);
  const [userResponse, setUserResponse] = useState({});
  const [bettingCompanyTypes, setbettingCompanyTypes] = useState([]);

  //smartkorpor
  const [values1, setValues1] = useState(initialValue1);
  const [errors1, setErrors1] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [formState1, setFormState1] = useState(1);
  const [userResponse1, setUserResponse1] = useState({});

  //EDSA
  const [values2, setValues2] = useState(initialValue2);
  const [errors2, setErrors2] = useState({});
  const [loading2, setLoading2] = useState(false);
  const [formState2, setFormState2] = useState(1);

  //bet salone
  const [values3, setValues3] = useState(initialValue3);
  const [errors3, setErrors3] = useState({});
  const [loading3, setLoading3] = useState(false);
  const [formState3, setFormState3] = useState(1);
  const [userResponse3, setUserResponse3] = useState({});

  //Lapo loan repayment
  const [values5, setValues5] = useState(initialValue5);
  const [errors5, setErrors5] = useState({});
  const [loading5, setLoading5] = useState(false);
  const [formState5, setFormState5] = useState(1);
  const [userResponse5, setUserResponse5] = useState({});

  const [currentBillScreen, setCurrentBillScreen] = useState(0);
  const [currentScreenName, setcurrentScreenName] = React.useState("");

  const { addToast } = useToasts();

  useEffect(() => {
    if (currentBillScreen === 1) {
      getCompanyList();
    }
  }, [currentBillScreen]);

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  /**======================== Form Validation function ==========================*/

  // elephant bets
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "bettingCompany") {
      if (checkSpace(value)) {
        setValues({
          ...values,
          bettingCompany: value,
          companyId: value,
          companyName: bettingCompanyTypes.find((i) => i.id == value)?.label,
        });
      }
    } else {
      if (checkSpace(value)) {
        setValues({ ...values, [name]: value });
      }
    }
  };

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      setValues1({ ...values1, [name]: value });
    }
  };
  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      setValues2({ ...values2, [name]: value });
    }
  };

  const handleInputChange3 = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      setValues3({ ...values3, [name]: value });
    }
  };
  const handleInputChange5 = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      setValues5({ ...values5, [name]: value });
    }
  };
  // EP
  const validate = (inputs) => {
    let errors = {};
    if (formState === 2) {
      if (!inputs.amount) {
        errors.amount = "This field is required";
      }
      if (!inputs.pin) {
        errors.pin = "This field is required";
      }
    }
    return errors;
  };

  const validate1 = (inputs) => {
    let errors = {};

    if (!inputs.refNo) {
      errors.refNo = "This field is required";
    }

    if (formState === 2) {
      if (!inputs.customer_account_number) {
        errors.customer_account_number = "This field is required";
      }
      if (!inputs.pin) {
        errors.pin = "This field is required";
      }
      if (!inputs.currency) {
        errors.currency = "This field is required";
      }
    }
    return errors;
  };

  const validate2 = (inputs) => {
    let errors = {};

    if (!inputs.billNumber) {
      errors.billNumber = "This field is required";
    }
    if (!inputs.amount) {
      errors.amount = "This field is required";
    }
    return errors;
  };

  const validate5 = (inputs) => {
    let errors = {};

    if (!inputs.accNo) {
      errors.accNo = "This field is required";
    }
    if (!inputs.amount && inputs?.amount <= 0) {
      errors.amount = "Enter valid amount";
    }
    return errors;
  };

  // BA
  const validate3 = (inputs) => {
    let errors = {};
    if (!inputs.phone) {
      errors.phone = "This field is required";
    }
    if (!inputs.lang) {
      errors.lang = "This field is required";
    }
    if (!inputs.currency) {
      errors.currency = "This field is required";
    }
    if (formState === 2) {
      if (!inputs.amount) {
        errors.amount = "This field is required";
      }
      if (!inputs.pin) {
        errors.pin = "This field is required";
      }
    }
    return errors;
  };

  /**======================== API Calls ==========================*/

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError && formState === 1) {
      validateUser();
    } else if (isNoError && formState === 2) {
      initiatePayment();
    }
  };

  const VerifyAgent = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError && formState === 1) {
      validateUser();
    }
  };

  const handleSubmit1 = (e) => {
    e.preventDefault();
    const validationErrors = validate1(values1);
    setErrors1(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError && formState1 === 1) {
      validateSmartKorpor();
    } else if (isNoError && formState1 === 2) {
      initiatePaymentSmartKorpor();
    }
  };

  const handleSubmit5 = (e) => {
    e.preventDefault();
    if (formState5 === 2) {
      const validationErrors = validate5(values5);
      setErrors5(validationErrors);
      const isNoError = Object.values(validationErrors).every((x) => x === "");
      if (isNoError && formState5 === 2) {
        initiateLapoLoanRepayment();
      }
    } else {
      validateLapoAccNo();
    }
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    const validationErrors = validate2(values2);
    setErrors2(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError && formState2 === 1) {
      initiatePaymentEDSA();
    }
  };

  // BA
  const handleSubmit3 = (e) => {
    e.preventDefault();
    const validationErrors = validate3(values3);
    setErrors3(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError && formState === 1) {
      validateBetSalone();
    } else if (isNoError && formState === 2) {
      initiatePaymentBetsAlone();
    }
  };

  const validateUser = () => {
    setLoading(true);
    var errorMsg = "";
    const { agentId } = values;
    var data = {
      agent_id: agentId,
    };

    let url = "/api/Transactions/ValidateBettingAgent/";
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        setLoading(false);

        if (res.data) {
          if (res.data?.metaData) {
            notifySuccess("Agent validation success");
            setUserResponse(res.data?.metaData);

            setFormState(2);
          } else {
            errorMsg = "No Agent found!!";
            notifyFailed(errorMsg);
            setLoading(false);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setLoading(false);
        }
      })
      .catch((err) => {
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
        setLoading(false);
      });
  };

  const validateSmartKorpor = () => {
    var errorMsg = "";
    const { refNo } = values1;

    setLoading1(true);
    let url = "/api/Transactions/SmartKorporFetchInvoice/?invoiceNo=" + refNo;
    getMethod(url, "get")
      .then((res) => {
        setLoading1(false);

        if (res.data?.status === "error") {
          notifyFailed(res.data?.message);
        }
        if (res.data) {
          if (res?.data?.data.payment_status === "settled") {
            notifyFailed("Bill/Invoice already paid");
            setLoading1(false);
            setValues1(initialValue1);
            setFormState1(1);
          } else {
            setUserResponse1(res?.data?.data);
            setValues1({
              ...values1,
              currency: res.data?.data?.currency,
              customername: res.data?.data?.name,
              amount: res.data?.data?.amount,
            });
            setFormState1(2);
            if (res.data?.desc) notifySuccess(res.data?.desc);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setLoading1(false);
        }
      })
      .catch((err) => {
        errorMsg = "Check you referance/Bill/Invoice number";
        notifyFailed(errorMsg);
        setLoading1(false);
      });
  };

  const validateLapoAccNo = () => {
    var errorMsg = "";
    const { accNo } = values5;
    const data = {
      id: accNo,
    };
    setLoading5(true);
    let url = "/api/Transactions/VerifyLapoLoanAccount";
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        setLoading5(false);
        if (res.data?.status === "error") {
          notifyFailed(res.data?.message);
        }
        if (res.data) {
          if (res?.data?.responseCode === "200") {
            setUserResponse5(res.data?.result?.account);
            notifySuccess("Account verified successfully");
            setFormState5(2);
          } else {
            notifyFailed(
              typeof res?.data?.result === "string"
                ? res?.data?.result
                : "Please Check lapo loan account number"
            );
          }
        } else {
          errorMsg = "Something went wrong";
          notifyFailed(errorMsg);
          setLoading1(false);
        }
      })
      .catch((err) => {
        errorMsg = "Please Check lapo loan account number";
        notifyFailed(errorMsg);
        setLoading1(false);
      });
  };

  const initiateLapoLoanRepayment = () => {
    var errorMsg = "";
    const { accNo: accountID, pin, amount } = values5;
    var data = {
      accountID,
      pin,
      amount: Number(amount),
    };

    setLoading5(true);
    let url = "/api/Transactions/InitiatePaymentLapoLoan";
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        setLoading5(false);
        if (res.data?.responseCode === "400") {
          notifyFailed(
            res.data?.responseMessage ?? "Issue with Loan account number"
          );
          return;
        }
        if (res.data) {
          notifySuccess(res.data?.responseMessage ?? "Loan repayment successful");
          setValues5(initialValue5);
          setFormState5(1);
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
        setLoading5(false);
      });
  };

  // BA
  const validateBetSalone = () => {
    var errorMsg = "";
    const { phone, currency, lang } = values3;
    var data = {
      phone,
      currency,
      lang,
    };
    setLoading3(true);
    let url = "/api/Transactions/ValidatePaymentBetSalone";
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        setLoading3(false);
        if (res.data?.status === "error") {
          notifyFailed(res.data?.message);
        }
        if (res.data) {
          if (res.data?.data?.token) {
            notifySuccess("User validation success");
            setUserResponse3(res.data?.data?.user);
            setValues3({ ...values, betSaloneToken: res.data?.data.token });
            setFormState3(2);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setLoading3(false);
        }
      })
      .catch((err) => {
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
        setLoading3(false);
      });
  };

  const getCompanyList = () => {
    var errorMsg = "";
    setLoading1(true);
    let url = "/api/Transactions/GetBettingCompanyList";
    getMethod(url, "get")
      .then((res) => {
        setLoading1(false);
        if (res.data.message !== null) {
          setbettingCompanyTypes(
            res.data.message?.map((i) => {
              return {
                id: i.id,
                value: i.id,
                label: i.name,
              };
            })
          );
        } else {
          errorMsg = "Error while fetching the company";
          notifyFailed(errorMsg);
          setLoading1(false);
        }
      })
      .catch((err) => {
        errorMsg = "Error while fetching the company";
        notifyFailed(errorMsg);
        setLoading1(false);
      });
  };

  // EP
  const initiatePayment = () => {
    var errorMsg = "";
    const { amount, bettingCompany, ...rest } = values;
    var data = {
      ...rest,

      amount: Number(amount),
    };

    setLoading(true);
    let url = "/api/Transactions/InitiatePaymentBettingCollection";
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        setLoading(false);
        if (res.data?.responseCode === "400") {
          notifyFailed(res?.data?.responseMessage);
          return;
        }
        if (res.data) {
          notifySuccess(String(res.data));
          setValues(initialValue);
          setFormState(1);
          setLoading(false);
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setLoading(false);
        }
      })
      .catch((err) => {
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
        setLoading(false);
      });
  };

  const initiatePaymentSmartKorpor = () => {
    var errorMsg = "";
    const {
      amount,
      refNo,
      currency,
      customername,
      customer_account_number,
      pin,
    } = values1;
    var data = {
      amount: Number(amount),
      refno: refNo,
      currency,
      customername,
      customer_account_number,
      pin,
    };

    setLoading1(true);
    let url = "/api/Transactions/InitiatePaymentSmartKorpor";
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        setLoading1(false);
        if (res.data?.responseCode === "400") {
          notifyFailed(res?.data?.responseMessage);
          return;
        }
        if (res.data) {
          notifySuccess(String(res.data));
          setValues1(initialValue);
          setFormState1(1);
          setLoading1(false);
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setLoading1(false);
        }
      })
      .catch((err) => {
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
        setLoading1(false);
      });
  };

  const initiatePaymentEDSA = () => {
    var errorMsg = "";
    const { amount, billNumber, pin } = values2;
    var data = {
      amount: Number(amount),
      billNumber,
      pin,
    };

    setLoading2(true);
    let url = "/api/Transactions/InitiatePaymentEDSA";
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        setLoading2(false);
        if (res.data?.responseCode === "400") {
          notifyFailed(res?.data?.responseMessage);
          return;
        }
        if (res.data) {
          notifySuccess(String(res.data));
          setValues2(initialValue2);
          setFormState2(1);
          setLoading2(false);
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setLoading2(false);
        }
      })
      .catch((err) => {
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
        setLoading2(false);
      });
  };

  // BA
  const initiatePaymentBetsAlone = () => {
    var errorMsg = "";
    const { amount, pin, betSaloneToken } = values;
    var data = {
      amount: Number(amount),
      pin,
      betSaloneToken,
    };
    setLoading3(true);
    let url = "/api/Transactions/InitiatePaymentBetSalone";
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        setLoading3(false);
        if (res.data?.responseCode === "400") {
          notifyFailed(res?.data?.responseMessage);
          return;
        }
        if (res.data) {
          notifySuccess(String(res.data));
          setValues3(initialValue);
          setFormState3(1);
          setLoading3(false);
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setLoading3(false);
        }
      })
      .catch((err) => {
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
        setLoading3(false);
      });
  };

  const onClickBillSelectBtn = (item) => {
    setCurrentBillScreen(item.id);
    setcurrentScreenName(item.name);
  };

  const renderBillScreen = (scrennNum) => {
    switch (scrennNum) {
      case 1:
        return (
          <ElephantBetsPaymentTab
            values={values}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            formState={formState}
            errors={errors}
            bettingCompanyTypes={bettingCompanyTypes}
            userResponse={userResponse}
            setValues={setValues}
            initialValue={initialValue}
            loading={loading}
            setFormState={setFormState}
            VerifyAgent={VerifyAgent}
          />
        );
      case 2:
        return (
          <SmartKorporPaymentTab
            values1={values1}
            handleSubmit1={handleSubmit1}
            handleInputChange1={handleInputChange1}
            formState1={formState1}
            errors1={errors1}
            userResponse1={userResponse1}
            setValues1={setValues1}
            initialValue1={initialValue1}
            loading1={loading1}
            setFormState1={setFormState1}
          />
        );
      case 3:
        return (
          <EDSAPayment
            values2={values2}
            handleSubmit2={handleSubmit2}
            handleInputChange2={handleInputChange2}
            formState2={formState2}
            errors2={errors2}
            setValues2={setValues2}
            initialValue2={initialValue2}
            loading2={loading2}
            setFormState2={setFormState2}
          />
        );
      case 4:
        return (
          <BetSalonePaymentTab
            values={values3}
            handleSubmit={handleSubmit3}
            handleInputChange={handleInputChange3}
            formState={formState3}
            errors={errors3}
            currencyTypes={currencyTypes}
            langs={langs}
            userResponse={userResponse3}
            setValues={setValues3}
            initialValue={initialValue3}
            loading={loading3}
            setFormState={setFormState3}
          />
        );
      case 5:
        return (
          <LapoLoanRepaymentTab
            values5={values5}
            handleSubmit5={handleSubmit5}
            handleInputChange5={handleInputChange5}
            formState5={formState5}
            errors5={errors5}
            userResponse5={userResponse5}
            setValues5={setValues5}
            initialValue5={initialValue5}
            loading5={loading5}
            setFormState5={setFormState5}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <Header />
      {/* <Container className="mt--6 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="12">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col lg="12">
                    <h3 className="mb-0 text-center">Make Payment</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Nav tabs>
                  {billTypes.map((item) => (
                    <NavItem key={item.id}>
                      <NavLink
                        className={classNames({
                          active: currentBillScreen === item.id,
                        })}
                        style={{ cursor: "pointer" }}
                        onClick={() => onClickBillSelectBtn(item.id)}
                      >
                        {item.name}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <TabContent activeTab={currentBillScreen}>
                  <TabPane tabId={currentBillScreen}>
                    {renderBillScreen(currentBillScreen)}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container> */}
      <Container className="mt--6 pb-5">
        <Row className="justify-content-center">
          <Col xs="8">
            <Card
              className="bg-secondary shadow border-0"
              style={{ minWidth: "300px" }}
            >
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col style={{ display: "flex", alignItems: "center" }}>
                    {currentBillScreen !== 0 && (
                      <div onClick={() => setCurrentBillScreen(0)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="#F5F5F5"
                          class="bi bi-arrow-left-short"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                          />
                        </svg>
                      </div>
                    )}
                    <h3 className="mb-0 text-center ml-4">Make Payment</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ display: "flex", justifyContent: "center" }}>
                {currentBillScreen ? (
                  <div style={{ width: "100%" }}>
                    <h2>{currentScreenName}</h2>
                    {renderBillScreen(currentBillScreen)}
                  </div>
                ) : (
                  <div
                    className="flex"
                    style={{
                      display: "flex",
                      gap: "14px",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {billTypes.map((item) => (
                      <Card
                        key={item.id}
                        style={{
                          width: "12rem",
                        }}
                      >
                        <CardHeader
                          inverse
                          style={{
                            backgroundColor: item.color,
                            borderColor: item.color,
                            color: "#FFFFFF",
                            textWrap: "noWrap",
                            padding: 12,
                            textAlign: "center",
                          }}
                        >
                          {item.name}
                        </CardHeader>
                        <CardText
                          style={{ justifyContent: "center" }}
                        ></CardText>
                        <CardBody
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button onClick={() => onClickBillSelectBtn(item)}>
                            Select
                          </Button>
                        </CardBody>
                      </Card>
                    ))}
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MakePayment);
