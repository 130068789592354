import React from "react";
import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";

const EDSAPayment = ({
  values2,
  handleSubmit2,
  handleInputChange2,
  formState2,
  errors2,
  setValues2,
  initialValue2,
  loading2,
  setFormState2,
}) => {
  return (
    <div className="mt-2">
      <Form onSubmit={handleSubmit2} autoComplete="off">
        <div className="pl-lg-4">
          {formState2 === 1 && (
            <>
             <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-amount"
                    >
                      Bill Number
                    </label>
                    <Input
                      className="form-control-alternative"
                      // country={"sl"}
                      id="input-billnumber"
                      name="billNumber"
                      type="number"
                      value={values2.billNumber}
                      // onlyCountries={MOBILE_ONLY_COUNTRY}
                      // countryCodeEditable={false}
                      onChange={handleInputChange2}
                      placeholder="Bill Number"
                      required
                      maxLength={50}
                    />
                    {errors2.billNumber && (
                      <div className="text-left text-danger">
                        <small>* {errors2.billNumber}</small>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-amount"
                    >
                      Amount
                    </label>
                    <Input
                      className="form-control-alternative"
                      // country={"sl"}
                      id="input-amount"
                      name="amount"
                      type="number"
                      value={values2.amount}
                      // onlyCountries={MOBILE_ONLY_COUNTRY}
                      // countryCodeEditable={false}
                      onChange={handleInputChange2}
                      placeholder="Amount"
                      required
                      min={30}
                      maxLength={50}
                    />
                    {errors2.amount && (
                      <div className="text-left text-danger">
                        <small>* {errors2.amount}</small>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-pin">
                      Pin
                    </label>
                    <Input
                      className="form-control-alternative"
                      // country={"sl"}
                      id="input-pin"
                      name="pin"
                      type="password"
                      value={values2.pin}
                      onChange={handleInputChange2}
                      placeholder="Pin"
                      required
                      maxLength={4}
                    />
                    {errors2.pin && (
                      <div className="text-left text-danger">
                        <small>* {errors2.pin}</small>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </>
           )}
          <Row>
           
              <Col>
                <div className="float-left">
                  <Button
                    className="btn float-right"
                    color="primary"
                    type="button"
                    disabled={loading2}
                    onClick={() => {
                      setValues2(initialValue2);
                      setFormState2(1);
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
             
            <Col>
              <div>
                <Button
                  className="btn float-right"
                  color="primary"
                  type="submit"
                  disabled={loading2}
                >
                  Submit {loading2 && <i class="fa fa-spinner fa-spin"></i>}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default EDSAPayment;
