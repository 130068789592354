import React from "react";
import { Button, Col, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import SuccessImg from "assets/img/brand/checkmark-1.png";
import CancelImg from "assets/img/brand/cancel-1.png";

const textP = {
  fontSize: "14px",
  fontWeight: 500,
};

const PaymentInfo = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = Object.fromEntries(params.entries());
  const history = useHistory();

  return (
    <div className="p-0 bg-0" style={{ margin: "-60px 12px" }}>
      <Row>
        <Col lg="12" style={{ marginTop: "-60px" }}>
          <div
            style={{
              background: "#fff",
              minWidth: "300px",
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 9px",
              padding: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <img
                  src={search.status === "success" ? SuccessImg : CancelImg}
                  alt="success"
                  style={{ height: 120, margin: 12 }}
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <p style={textP}>
                  {search.status === "success"
                    ? "Smart Pay wallet Top up is successful"
                    : "Smart Pay Wallet Top up failed"}
                </p>
                <p style={textP}>Wallet Number: {search.wallet}</p>
                <p style={textP}>Reference Number: {search.reference}</p>
              </div>
            </div>
          </div>

          <div style={{ textAlign: "center", marginTop: "12px" }}>
            <Button
              onClick={() => history.push("/top-up")}
              className="bg-blue"
              style={{ color: "white" }}
            >
              Exit Page
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentInfo;
