import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  ModalHeader,
  Col
} from "reactstrap";
import Header from "components/Headers/Header.js";
import { getMethod, postMethod } from "../../services/httpServices";
import { useToasts } from 'react-toast-notifications';
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import AddCommission from "./add-commission";
import ViewCommission from "./view-commission";
import EditCommission from "./edit-commission";
import { btnCss, iconCss } from '../../appConfig';
import { getCommissionTypeName } from "services/commission-service";
import ReactPaginate from 'react-paginate';
import { getSortCls } from "services/util";
import { getPrivileges } from "services/util";

const sortIS = {
  sortName: 0,
  sortType: 0,
  sortMethod: 0
};

const CommissionList = () => {

  const [loading, setLoading] = React.useState(false);
  const [postlist, setPosList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [pagesCount, setPageCount] = React.useState(0);
  const [timeout, setTimeoutNew] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [view, setView] = React.useState(false);
  const [openEdit, setOpenEditModal] = React.useState(false);
  const [ViewObj, setViewObj] = React.useState(null);
  const [editObj, setEditObj] = React.useState(null);
  const [totalRec, setTotalRec] = React.useState(0);
  const [sort, setSort] = React.useState(sortIS);
  const [refresh, setRefresh] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(false);

  const { addToast } = useToasts();
  const privileges = getPrivileges('commission');

  /**======================== React Hooks ==========================*/

  // React.useEffect(() => {
  //   fetchCommission();
  // }, []);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (
        searchText && searchText.length >= 3 ||
        searchText.length == 0
      ) {
        if (currentPage != 0) {
          setCurrentPage(0);
        } else {
          fetchCommission();
        }
      }
    }
  }, [searchText]);


  React.useEffect(() => {
    fetchCommission();
  }, [currentPage]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchCommission();
      }
    }
  }, [sort]);

  React.useEffect(()=> {
    if (refresh && isMounted) {
      setSearchText('');
      setSort(sortIS);
      if (currentPage == 0) {
        fetchCommission();
      } else {
        setCurrentPage(0);
      }
    }
  }, [refresh]);


  /**======================== File Functions ==========================*/

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }


  const getSearch = (val) => {
    setSearchText(val);
  };

  const reloadList = () => {
    setSearchText('');
    setRefresh(true);
  }

  const closeModal = () => {
    setOpen(false);
    fetchCommission();
  }

  const openModal = () => {
    setOpen(true);
  }

  const handleClick = (e, index) => {

    e.preventDefault();
    setCurrentPage(index);

  }

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  }


  const ShowCommission = (obj) => {
    setViewObj(obj);
    setView(true);
  }

  const closeViewModal = () => {
    setView(false);
    setSearchText('');
    setViewObj(null);
  }

  const closeEditModal = () => {
    setOpenEditModal(false);
    setEditObj(null);
    setSearchText('');
    fetchCommission();
  }

  const commType = (value) => {
    if (value == 'WalletTopUp') {
      return 'Wallet';
    } else if (value == 'CashTopUp') {
      return 'Cash Top Up';
    } else {
      return value;
    }
  }

  /**======================== Validation Functions ==========================*/











  /**======================== API Calls ==========================*/

  const fetchCommission = async () => {
    let errorMsg = '';
    setLoading(true);

    let data = {
      'PageNumber': Number(currentPage + 1),
      'PerPage': pageSize
    };

    if (searchText && searchText.length >= 3) {
      data['SearchText'] = searchText;
      // data['PageNumber'] = 0;
    }

    if (sort.sortName == "1") {
      data['SortingOn'] = 'CommissionName';
      data['IsDescending'] = false;
    } else if (sort.sortName == "2") {
      data['SortingOn'] = 'CommissionName';
      data['IsDescending'] = true;
    } else if (sort.sortType == "1") {
      data['SortingOn'] = 'CommissionType';
      data['IsDescending'] = false;
    } else if (sort.sortType == "2") {
      data['SortingOn'] = 'CommissionType';
      data['IsDescending'] = true;
    } else if (sort.sortMethod == "1") {
      data['SortingOn'] = 'CommissionCalculateMethodName';
      data['IsDescending'] = false;
    } else if (sort.sortMethod == "2") {
      data['SortingOn'] = 'CommissionCalculateMethodName';
      data['IsDescending'] = true;
    }

    postMethod('/api/commission/all', data,  'post')
      .then(res => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        if (
          res && res.data
        ) {

          if (res.data.responseCode === "200" &&
            res.data.result) {
            // Success
            if (
              !res.data.result.list ||
              !res.data.result.list.length
            ) {
              res.data.result.list = [];
            }
            setPosList(res.data.result.list);
            setFilteredList(res.data.result.list)
            let pc = Math.ceil(res.data.result.totalRecords / pageSize);
            setTotalRec(res.data.result.totalRecords);
            setPageCount(pc);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching list';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
        }
      }).catch(err => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        notifyFailed('Something went wrong!, Please call 3737');
      });
  }

  const fetchCommissionById = (id, page) => {
    let errorMsg = '';
    postMethod('/api/commission/Edit/' + id, {}, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (res.data.responseCode === "200") {
          // Success
          if (page == 'edit') {
            setEditObj(res.data.result);
            setOpenEditModal(true);
          } else if (page == 'view') {
            ShowCommission(res.data.result);
          }
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching list';
          // setSError(errorMsg);
          notifyFailed(errorMsg);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching';
          // setSError(errorMsg);
          notifyFailed(errorMsg);
        }
      } else {
        errorMsg = 'Unknown Error';
        // setSError(errorMsg);
        notifyFailed(errorMsg);
      }
    }).catch(err => {
      // setSError();
      notifyFailed('Something went wrong!, Please call 3737');
    });
  }


  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}

                <Form className="form-inline mr-3 d-md-flex ml-lg-auto"
                  onSubmit={e => { e.preventDefault(); }}>
                  <FormGroup className="mb-0 navbar-search ">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input className="search-cls" placeholder="Search"
                        type="text"
                        value={searchText}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  &nbsp;&nbsp;

                  {privileges.add && <Button className="my-4" color="primary"
                    onClick={openModal}>
                    Add Commission
                  </Button>}

                  <Button
                    color="primary"
                    onClick={reloadList}
                    size="sm"
                    style={btnCss}
                    data-toggle="tooltip"
                    title="Refresh">
                    <i class="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>


                </Form>


              </CardHeader>


              <Modal isOpen={open}
                style={{ width: "80vw", maxWidth: "80vw" }}>
                <ModalBody style={{ padding: 0 }}>
                  <AddCommission closeModal={closeModal} />
                </ModalBody>
              </Modal>

              <Modal
                isOpen={view}
                style={{ width: "70vw", maxWidth: "70vw" }}>
                <ModalBody style={{ padding: 0 }}>
                  <ViewCommission closeModal={closeViewModal} {...ViewObj} />
                </ModalBody>
              </Modal>

              <Modal
                isOpen={openEdit}
                style={{ width: "70vw", maxWidth: "70vw" }}>
                <ModalBody style={{ padding: 0 }}>
                  <EditCommission closeModal={closeEditModal} {...editObj} />
                </ModalBody>
              </Modal>


              {/* <Modal
                isOpen={view}
                style={{ width: "70vw", maxWidth: "70vw" }}>
                <ModalBody style={{ padding: 0 }}>
                  <ViewPOS closeModal={closeViewModal} {...ViewObj} />
                </ModalBody>
              </Modal>

             
              <Modal isOpen={assign} className="modal-sm">
                <ModalBody style={{ padding: 0 }}>
                  <AssignPOS closeModal={closeAssignModal} />
                </ModalBody>
              </Modal> */}


              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortName, 'sortName')}>
                      Commission Name
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortName)}
                          onClick={() => sortHandle(sort.sortName, 'sortName')}
                        />
                      </button>
                    </th>
                    <th scope="col" onClick={() => sortHandle(sort.sortType, 'sortType')}>
                      Commission Type
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortType)}
                          onClick={() => sortHandle(sort.sortType, 'sortType')}
                        />
                      </button>
                    </th>
                    <th scope="col" onClick={() => sortHandle(sort.sortMethod, 'sortMethod')}>
                      Calculation Method
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortMethod)}
                          onClick={() => sortHandle(sort.sortMethod, 'sortMethod')}
                        />
                      </button>
                    </th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                {filteredList.length > 0 && filteredList.map((mmo, i) => {
                  return (
                    <tr>
                      <th scope="row">
                        {mmo.commissionName}
                      </th>
                      <td>{commType(mmo.commissionType)}</td>
                      <td>{mmo.commissionCalculateMethodName}</td>
                      <td>
                        {privileges.view && <Button
                          color="primary"
                          onClick={() => fetchCommissionById(mmo.commissionId, 'view')}
                          // onClick={() => ShowCommission(mmo)}
                          size="sm"
                          style={btnCss}
                          data-toggle="tooltip"
                          title="View"
                        >
                          <i class="fas fa-eye fa-lg" style={iconCss}></i>
                        </Button>}

                        {privileges.edit && <Button
                          color="primary"
                          onClick={() => fetchCommissionById(mmo.commissionId, 'edit')}
                          size="sm"
                          style={btnCss}
                          data-toggle="tooltip"
                          title="Edit">
                          <i class="fas fa-edit fa-lg" style={iconCss}></i>
                        </Button>
}
                      </td>
                    </tr>

                  )
                })}
                </tbody>
                {loading && <FullPageLoader label={"Fetching..."} />}
                {!loading && !filteredList.length && <NoRecordFound />}
              </Table>

              <CardFooter className="py-4">
              {filteredList.length > 0 &&
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>
                            Total Records : {totalRec}
                          </strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6" >
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage} />
                        </div>
                      </nav>

                    </Col>
                  </Row>}
              </CardFooter>
            
            </Card>
          </div>
        </Row>
      </Container>

    </>
  )

}

export default CommissionList;