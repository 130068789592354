import React, { useEffect, useState } from "react";
import {
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const SearchableSelect = ({
  options,
  onSelect,
  defaultValue,
  dropdownVersion = 1,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue]);

  const toggle = () => setIsOpen(!isOpen);

  const handleSearchInputChange = (event) => setSearchValue(event.target.value);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
    setSearchValue("");
  };

  const filteredOptions = options.filter((option) =>
    option.value.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggle}
      direction="end"
      style={{ width: "100%" }}
      className={
        dropdownVersion === 2 ? "form-control-alternative rounded-lg" : ""
      }
    >
      <DropdownToggle
        caret
        className={`form-control-alternative status-filter ${
          dropdownVersion === 2 ? "" : "dropdown-border"
        }`}
        style={{ width: "100%", textAlign: "left" }}
      >
        {selectedOption ? selectedOption.value : "Select an option"}
      </DropdownToggle>
      <DropdownMenu
        class="h-auto d-inline-block"
        style={{ maxHeight: 300, overflowX: "hidden", width: "100%" }}
      >
        <Input
          className="form-control-alternative"
          value={searchValue}
          onChange={handleSearchInputChange}
          placeholder="Search..."
        />
        {filteredOptions.map((option) => (
          <DropdownItem
            key={option.key}
            onClick={() => handleOptionSelect(option)}
          >
            {option.value}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default SearchableSelect;
