import { getMethod } from "services/httpServices";
import { setSettlementBank, settlementBankError, resetSettleError } from "./action";
import { FETCH_SETTLEMENT_BANK } from "./constant";
import { call, put, takeLatest } from 'redux-saga/effects';


export function* fetchSettleBank() {
  var errorMsg = '';
  try {
    const res = yield getMethod('/api/bankProfile/ddllist?Accounttype=Settlement', 'get');
    if (res.data.responseCode === "200") {
      // Success
      yield put(setSettlementBank(res.data.result));
    } else if (res.data.responseCode === "400") {
      // Error
      errorMsg = res.data.responseMessage || 'Error in fetching bank transactions';
      yield put(settlementBankError(errorMsg));
      yield put(resetSettleError());
    } else {
      // Unknown Error
      errorMsg = 'Unknown Error in fetching bank transactions';
      yield put(settlementBankError(errorMsg));
      yield put(resetSettleError());
    }
  } catch (err) {
    errorMsg = 'Something went wrong!, Please call 3737';
    yield put(settlementBankError(errorMsg));
    yield put(resetSettleError());
  }
}

export default function* financeSaga() {
  yield takeLatest(FETCH_SETTLEMENT_BANK, fetchSettleBank);
}
