import React, { useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input, InputGroupText, InputGroupAddon, InputGroup,
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { connect } from "react-redux";
import { fetchSubs, addVoucher } from "./action";
import { useToasts } from 'react-toast-notifications';
import { getUserEmail, loginMethod } from "services/httpServices";
import { useHistory } from "react-router";
import { postMethod } from "services/httpServices";
import { getUserId } from "services/httpServices";
import swal from 'sweetalert';
import { getAmt } from "services/util";
import { getLoginType } from "services/httpServices";


const initialValue = {
  accountType: 'Bank',
  amount: null,
  otp: '',
  password: ''
};

const TopUp = (props) => {
  const [values, setValues] = React.useState(initialValue);
  const [subsInfo, setSubsInfo] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(1);
  const [use1FA, set1FA] = React.useState(false);
  const [use2FA, set2FA] = React.useState(false);
  const [showPswd, setShowPswd] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const [fees, setFees] = React.useState(0);

  const { addToast } = useToasts();
  const history = useHistory();


  /**======================== React Hooks ==========================*/

  useEffect(() => {
    props.fetchSubsInfo();
    // genOtp();
  }, []);

  useEffect(() => {
    if (props.subscriber.subsInfo.error) {
      notifyFailed(props.subscriber.subsInfo.errorMsg);
    }
  }, [props.subscriber.subsInfo.error]);


  useEffect(() => {
    setSubsInfo(props.subscriber.subsInfo.data);
  }, [props.subscriber.subsInfo.data]);

  useEffect(() => {
    setLoading(props.subscriber.voucherInfo.loading);
  }, [props.subscriber.voucherInfo.loading]);

  useEffect(() => {
    if (props.subscriber.voucherInfo.success) {
      notifySuccess(props.subscriber.voucherInfo.successMsg);
      resetValues();
      history.push('/subscriber-transaction');
    }
  }, [props.subscriber.voucherInfo.success]);

  useEffect(() => {
    if (props.subscriber.voucherInfo.error) {
      notifyFailed(props.subscriber.voucherInfo.errorMsg);
    }
  }, [props.subscriber.voucherInfo.error]);

  // useEffect(() => {
  //   if (use2FA) {
  //     genOtp();
  //   }
  // }, [use2FA]);


  /**======================== File Functions ==========================*/

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  const notifySuccess = (text) => {
    if (!text) {
      text = 'Save successfully';
    }
    addToast(text, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const resendOtp = (e) => {
    e.preventDefault();
    setValues({ ...values, 'otp': '' });
    setSpinner(true);
    genOtp();
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSubmitCB();
  }

  const handleSubmitCB = () => {
    const validationErrors = validateV2(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      // verifyOtp();
      saveTrans();
    }
  }

  const resetValues = () => {
    setValues({ ...values, amount: "" });
  };

  const openModal = (msg) => {
    swal({
      // title: msg,
      icon: "success",
      text: msg,
      allowOutsideClick: false,
      allowEscapeKey: false,
      closeOnClickOutside: false,
      buttons:
      {
        confirm: {
          text: "Ok",
          visible: true,
          closeModal: true,
          className: 'sweet-btn'
        }
      }
    }).then((confirm) => {
      setLoading(false);
      setForm(3);
    });
  }

  const gotoNext = (e) => {
    setLoading(true);
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every(x => x === '');
    if (isNoError) {
      verifyPinV2();
      // Check subscriber's 1FA/2FA
      // if (subsInfo.authFactorType == "2FA") {
      //   set2FA(true);
      // } else if (subsInfo.authFactorType == "1FA") {
      //   if (
      //     subsInfo.excidedPaymentAmount >=
      //     values.amount
      //   ) {
      //     set1FA(true);
      //     setForm(2);
      //     setLoading(false);
      //   } else {
      //     set2FA(true);
      //   }
      // }
    } else {
      setLoading(false);
    }
  }

  const goBack = () => {
    setForm(1);
    set1FA(false);
    set2FA(false);
    setValues({ ...values, otp: '', password: '' });
  }

  const proceed = (e) => {
    e.preventDefault();
    if (use2FA) {
      // call genOtp()
      setLoading(true);
      genOtp();
    } else {
      handleSubmitCB();
    }
  }

  /**======================== Form Validation function ==========================*/


  const handleInputChange = (ev) => {
    var { name, value } = ev.target;
    value = value.trim();
    if (name === "amount") {
      if (value == '') {
        setValues({ ...values, [name]: value });
      } else {
        if (checkNumberOnly(value)) {
          setValues({ ...values, [name]: value });
        } else {
          setValues({...values, [name]: ''});
        }
      }
      
    } else  if (name === "password") {
      if (pinValidation(value)) {
        setValues({ ...values, [name]: value });
      }
    } else if (name === "otp") {
      if (checkOTP(value)) {
        setValues({ ...values, [name]: value });
      }
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const pinValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 4) {

      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const checkNumberOnly = (value) => {
    let digiRegex = /^[1-9][\d]*$/;
    // let digiRegex = /^[+-]?([1-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (digiRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  }

  const checkOTP = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (digiRegex.test(value)) {
      if (value.length <= 6) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const validate = (inputs) => {
    let errors = {};
    if (!inputs.accountType) {
      errors.accountType = 'Please select one account';
    }
    if (!inputs.amount) {
      errors.amount = 'Please enter amount';
    } else if (Number(inputs.amount) <= 0) {
      errors.amount = `Amount can't be ${inputs.amount}`
    }
    if (!subsInfo.id) {
      errors.id = 'Subscriber is required';
    }
    // if (!inputs.otp) {
    //   errors.otp = 'Please enter OTP';
    // }
    
    if (!inputs.password) {
      errors.password = 'Pin is required';
    } else if (inputs.password.length !== 4) {
      errors.password = 'Pin should contain 4 digits';
    }
    return errors;
  }

  const validateV2 = (inputs) => {
    let errors = {};
    if (!inputs.accountType) {
      errors.accountType = 'Please select one account';
    }
    if (!inputs.amount) {
      errors.amount = 'Please enter amount';
    }
    if (!subsInfo.id) {
      errors.id = 'Subscriber is required';
    }
    // if (!inputs.otp) {
    //   errors.otp = 'Please enter OTP';
    // }
    if (Number(inputs.amount) <= 0) {
      errors.amount = `Amount can't be ${inputs.amount}`
    }
    if (!inputs.password) {
      errors.password = 'Password is Required';
    }
    if (use2FA && !inputs.otp) {
      errors.otp = 'Otp is required';
    }
    return errors;
  }

  /**======================== API Calls ==========================*/


  const saveTrans = () => {
    setLoading(true);
    var data = new FormData();
    // data.append('SubscriberId', subsInfo.id);
    data.append('SubscriberUserId', getUserId());
    data.append('Amount', values.amount);
    data.append('PaymentMethod', values.accountType);
    data.append('PaymentType', 'topup');
    data.append('PaymentTo', 'Wallet TopUp');
    data.append('AmountType', 'R');
    data.append('CollectionType', 'TopUp');
    if (use2FA) {
      data.append('Otp', values.otp);
    }
    data.append('PIN', values.password);
    if (values.accountType === "Bank") {
      data.append('PaymentFrom', subsInfo.bankName);
      data.append('PaymentMethodId', subsInfo.bankProfileId);
    } else if (values.accountType === "MMO") {
      data.append('PaymentFrom', subsInfo.mmoName);
      data.append('PaymentMethodId', subsInfo.mmoId);
    }
    data.append('FeesAmount', fees);
    let loginType = getLoginType()
    if(loginType === "Master Agent") {
      data.append('MasterAgentUserId', getUserId())
    }
    props.addVoucherAmt(data);
  }

  const genOtp = () => {
    var errorMsg = '';
    var data = JSON.stringify({
      "UserId": getUserId(),
      "OtpType": "TransactionOTP"
    });
    let url = '/api/Transactions/Sendotp';
    postMethod(url, data, 'post')
      .then(res => {
        setSpinner(false);
        if (res.data) {
          if (res.data.responseCode === "200") {
            let msg = res.data.responseMessage || 'Otp sent';
            // notifySuccess(msg);
            openModal(msg);
          } else if (res.data.responseCode === "400") {
            errorMsg = res.data.responseMessage || 'Error in generating otp';
            notifyFailed(errorMsg);
            setLoading(false);
          } else {
            errorMsg = 'Unknown error in generating otp';
            notifyFailed(errorMsg);
            setLoading(false);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
          setLoading(false);
        }
      }).catch(err => {
        setSpinner(false);
        setLoading(false);
        errorMsg = 'Something went wrong!, Please call 3737';
        notifyFailed(errorMsg);
      });
  }

  const verifyOtp = () => {
    var errorMsg = '';
    var data = JSON.stringify({
      "UserId": getUserId(),
      "OtpType": "TransactionOTP",
      "Otp": values.otp
    });
    setLoading(true);
    let url = '/api/Transactions/verifyotp';
    postMethod(url, data, 'post')
      .then(res => {
        if (res.data) {
          if (res.data.responseCode === "200") {
            saveTrans();
          } else if (res.data.responseCode === "400") {
            errorMsg = res.data.responseMessage || 'Error in generating otp';
            notifyFailed(errorMsg);
            setLoading(false);
          } else {
            errorMsg = 'Unknown error in generating otp';
            notifyFailed(errorMsg);
            setLoading(false);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
          setLoading(false);
        }
      }).catch(err => {
        errorMsg = 'Something went wrong!, Please call 3737';
        notifyFailed(errorMsg);
        setLoading(false);
      });
  }

  const verifyPin = () => {
    var errorMsg = '';
    var data = {
      "PaymentAmount": Number(values.amount),
      "Password": values.password,
      "Email": getUserEmail(),
      "PaymentType": values.accountType 
    };

    setLoading(true);
    let loginType = getLoginType()
    let url = loginType === "Master Agent"  ? '/api/Agent/GetSuperAgentDetails' : '/api/Subscriber/GetSubscriberDetails';
    postMethod(url, JSON.stringify(data), 'post')
    .then(res => {
      if (res.data) {
        if (res.data.responseCode === "200") {
          let result = res.data.result;
          setFees(result.fees);
          if (result.authFactorType == "1FA") {
            set1FA(true);
            set2FA(false);
            handleSubmitCB();
          } else if (result.authFactorType == "2FA") {
            set1FA(false);
            set2FA(true);
            // setForm(2);
            // setLoading(false);
          } else {
            set1FA(false);
            set2FA(false);
            handleSubmitCB();
          }
        } else if (res.data.responseCode === "400") {
          errorMsg = res.data.responseMessage || 'Error in validating pin';
          notifyFailed(errorMsg);
          setLoading(false);
        } else {
          errorMsg = 'Unknown error in validating pin';
          notifyFailed(errorMsg);
          setLoading(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        setLoading(false);
      }
    }).catch(err => {
      errorMsg = 'Something went wrong!, Please call 3737';
      notifyFailed(errorMsg);
      setLoading(false);
    });
  }

  const verifyPinV2 = () => {
    var errorMsg = '';
    var data = {
      "PaymentAmount": Number(values.amount),
      "Password": values.password,
      "Email": getUserEmail(),
      "PaymentType": values.accountType 
    };

    setLoading(true);
    let loginType = getLoginType()
    let url = loginType === "Master Agent"  ? '/api/Agent/GetSuperAgentDetails' : '/api/Subscriber/GetSubscriberDetails';
    postMethod(url, JSON.stringify(data), 'post')
    .then(res => {
      if (res.data) {
        if (res.data.responseCode === "200") {
          let result = res.data.result;
          if (!isNaN(result.fees)) {
            setFees(Number(result.fees));
          }
          if (result.authFactorType == "1FA") {
            set1FA(true);
            set2FA(false);
            // handleSubmitCB();
          } else if (result.authFactorType == "2FA") {
            set1FA(false);
            set2FA(true);
            // setForm(2);
            // setLoading(false);
          } else {
            set1FA(false);
            set2FA(false);
            // handleSubmitCB();
          }
          verifyPinHandle(result);
        } else if (res.data.responseCode === "400") {
          errorMsg = res.data.responseMessage || 'Error in validating pin';
          notifyFailed(errorMsg);
          setLoading(false);
        } else {
          errorMsg = 'Unknown error in validating pin';
          notifyFailed(errorMsg);
          setLoading(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        setLoading(false);
      }
    }).catch(err => {
      errorMsg = 'Something went wrong!, Please call 3737';
      notifyFailed(errorMsg);
      setLoading(false);
    });
  }

  const verifyPinHandle = (result) => {
    if (result.fees == 0) {
      // Smart pay fees is zero
      if (result.authFactorType == '2FA') {
        // setLoading(false);
        genOtp();
      } else {
        handleSubmitCB();
      }
    } else {
      setLoading(false);
      setForm(2);
    }
  }

  return (
    <>
      <Header />
      <Container className="mt--5 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="7">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col lg="12">
                    <h3 className="mb-0 text-center">Top Up</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-4" style={{ minHeight: '300px' }}>
                {form == 1 && <Form role="form" onSubmit={gotoNext}>
                  {/* <FormGroup tag="fieldset">
                    <strong>Recharge Using</strong>
                    <Row>
                      <Col lg="6">
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="accountType" value="Bank"
                              checked={values.accountType === "Bank"}
                              onChange={handleInputChange} />{' '}
                            Bank
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="accountType" value="MMO"
                              checked={values.accountType === "MMO"}
                              onChange={handleInputChange} />{' '}
                            Mobile Money
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup> */}
                  {values.accountType && <Row>
                    {values.accountType === "Bank" ?
                      <Col lg="12">
                        <strong>Bank Details</strong>
                        <div className="mb-4 mt-2">
                          <div>
                            <small>Bank Name - {subsInfo.bankName}</small>
                          </div>
                          <div>
                            <small className="mt-2">Account Number - {subsInfo.bankAccountNumber}</small>
                          </div>
                        </div>

                      </Col> :
                      <Col>
                        <strong>MMO Details</strong>
                        <div className="mb-4 mt-2">
                          <div>
                            <small>MMO Name - {subsInfo.mmoName}</small>
                          </div>
                          <div>
                            <small className="mt-2">Account Number - {subsInfo.mmoAccountNumber}
                            </small>
                          </div>
                        </div>
                      </Col>}
                  </Row>}

                  {values.accountType &&
                    <div>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-amount">
                              Amount
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-amount"
                              placeholder="Enter Amount"
                              type="text"
                              name="amount"
                              value={values.amount}
                              onChange={handleInputChange}
                            />
                            {errors.amount && <div className="text-left text-danger">
                              <small>* {errors.amount}</small>
                            </div>}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-pswd">
                              PIN
                            </label>

                            <InputGroup className="input-group-alternative">
                              <Input
                                className="form-control-alternative"
                                id="input-pswd"
                                type={showPswd ? "text" : "password"}
                                name="password"
                                value={values.password}
                                placeholder="Enter 4-digit PIN"
                                onChange={handleInputChange}
                              />
                              {!showPswd && <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fa fa-eye" onClick={() => setShowPswd(true)} />
                                </InputGroupText>
                              </InputGroupAddon>
                              }

                              {showPswd && <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <i className="fa fa-eye-slash" onClick={() => setShowPswd(false)} />
                                </InputGroupText>
                              </InputGroupAddon>}
                            </InputGroup>
                            {errors.password && <div className="text-left text-danger">
                              <small>* {errors.password}</small>
                            </div>}
                          </FormGroup>
                        </Col>

                      </Row>


                    </div>
                  }

                  {values.accountType && <Row>
                    <Col lg-6>
                      <div>
                        <Button className="btn float-right" color="primary" type="submit"
                          disabled={loading}>
                          Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
                        </Button>
                      </div>
                    </Col>
                  </Row>}
                </Form>
                }

                {form == 2 && <Form onSubmit={proceed} autoComplete="off">
                  <Row className="mb-3">
                    <Col>
                      <div className="mt-2">
                        <div>
                          {values.accountType && <Row>
                            {values.accountType === "Bank" ?
                              <Col lg="12">
                                <strong>Bank Details</strong>
                                <div className="mb-4 mt-2">
                                  <div>
                                    <small>Bank Name - {subsInfo.bankName}</small>
                                  </div>
                                  <div>
                                    <small className="mt-2">Account Number - {subsInfo.bban}</small>
                                  </div>
                                  <div>
                                    <small className="mt-2">Amount - {getAmt(values.amount)}</small>
                                  </div>
                                  <div>
                                    <small className="mt-2">Smart Pay Fees - {getAmt(fees)}</small>
                                  </div>
                                </div>

                              </Col> :
                              <Col>
                                <strong>MMO Details</strong>
                                <div className="mb-4 mt-2">
                                  <div>
                                    <small>MMO Name - {subsInfo.mmoName}</small>
                                  </div>
                                  <div>
                                    <small className="mt-2">Account Number - {subsInfo.mmoAccountNumber}
                                    </small>
                                  </div>
                                  <div>
                                    <small className="mt-2">Amount - {getAmt(values.amount)}</small>
                                  </div>
                                  <div>
                                    <small className="mt-2">Smart Pay Fees - {getAmt(fees)}</small>
                                  </div>
                                </div>
                              </Col>}
                          </Row>}
                        </div>

                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col lg-6>
                      <div>
                        <Button className="btn float-left" color="primary"
                          onClick={goBack}
                          disabled={loading}
                        >
                          <i class="fas fa-arrow-alt-circle-left" style={{ fontSize: '18px' }}></i> Back
                        </Button>
                      </div>
                    </Col>
                    <Col lg-6>
                      <div>
                        <Button className="btn float-right" color="primary" type="submit"
                          disabled={loading}>
                          Proceed {loading && <i class="fa fa-spinner fa-spin"></i>}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              }

                {form == 3 && <Form onSubmit={handleSubmit} autoComplete="off">
                  <Row className="mb-3">
                    <Col>
                      <div className="mt-2">
                        <div>
                          {values.accountType && <Row>
                            {values.accountType === "Bank" ?
                              <Col lg="12">
                                <strong>Bank Details</strong>
                                <div className="mb-4 mt-2">
                                  <div>
                                    <small>Bank Name - {subsInfo.bankName}</small>
                                  </div>
                                  <div>
                                    <small className="mt-2">Account Number - {subsInfo.bban}</small>
                                  </div>
                                  <div>
                                    <small className="mt-2">Amount - {getAmt(values.amount)}</small>
                                  </div>
                                  <div>
                                    <small className="mt-2">Smart Pay Fees - {getAmt(fees)}</small>
                                  </div>
                                </div>

                              </Col> :
                              <Col>
                                <strong>MMO Details</strong>
                                <div className="mb-4 mt-2">
                                  <div>
                                    <small>MMO Name - {subsInfo.mmoName}</small>
                                  </div>
                                  <div>
                                    <small className="mt-2">Account Number - {subsInfo.mmoAccountNumber}
                                    </small>
                                  </div>
                                  <div>
                                    <small className="mt-2">Amount - {getAmt(values.amount)}</small>
                                  </div>
                                  <div>
                                    <small className="mt-2">Smart Pay Fees - {getAmt(fees)}</small>
                                  </div>
                                </div>
                              </Col>}
                          </Row>}
                        </div>

                      </div>
                    </Col>
                  </Row>
                  {use2FA && <Row>
                    <Col>
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-otp">
                          OTP
                        </label>

                        <div className="float-right text-small">
                          <small>
                            <a href="#pablo" onClick={resendOtp}>
                              Resend
                            </a> &nbsp; &nbsp;
                            {spinner &&
                              <div class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only"></span>
                              </div>}
                          </small>
                        </div>

                        <Input
                          className="form-control-alternative"
                          id="input-otp"
                          placeholder="Enter OTP"
                          type="text"
                          name="otp"
                          value={values.otp}
                          onChange={handleInputChange}
                        />
                        <div className="text-left text-muted">
                          <small><i>*
                            we have sent you an OTP on your registered mobile number / email</i></small>
                        </div>
                        {errors.otp && <div className="text-left text-danger">
                          <small>* {errors.otp}</small>
                        </div>}
                      </FormGroup>
                    </Col>
                  </Row>
                  }

                  <Row className="mt-4">
                    <Col lg-6>
                      <div>
                        <Button className="btn float-left" color="primary"
                          onClick={goBack}
                          disabled={loading}>
                          <i class="fas fa-arrow-alt-circle-left" style={{ fontSize: '18px' }}></i> Back
                        </Button>
                      </div>
                    </Col>
                    <Col lg-6>
                      <div>
                        <Button className="btn float-right" color="primary" type="submit"
                          disabled={loading}>
                          Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return { ...state }
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubsInfo: () => dispatch(fetchSubs()),
    addVoucherAmt: (data) => dispatch(addVoucher(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopUp);
