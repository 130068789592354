import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Input,
  Button,
  Row,
  Col,
  Table,
  CardFooter,
} from "reactstrap";
import moment from "moment";
import { postMethod } from "services/httpServices";
import { useToasts } from "react-toast-notifications";
import { getMethod } from "services/httpServices";
import { getAmt } from "services/util";
import ReactPaginate from "react-paginate";
import ReactSelect from "react-select";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
const AllTransactionReport = (props) => {
  const [merchants, setMerchants] = React.useState([]);
  const [merchantUsers, setMerchantUsers] = React.useState([]);
  const [fsps, setFsps] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [pageSize, setPageSize] = React.useState(10);
  const [posData, setPosData] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [totalRec, setTotalRec] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pagesCount, setPageCount] = React.useState(0);
  const [values, setValues] = useState({
    isDescending: true,
    pageNumber: 1,
    perPage: 10,
    searchText: "",
    sortingOn: "",
    transactionStatus: [],
    collectionType: [],
    posMacID: [],
    collectedBy: [],
    merchantID: [],
    fspid: [],
    dateRange: [],
    startDate: "",
    endDate: "",
    paymentType: [],
  });
  const [isFirst, setIsFirst] = useState(true);
  const [pdfLoading, setPdfLoading] = React.useState(false);
  const [xlLoading, setXlLoading] = React.useState(false);

  const { addToast } = useToasts();

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const fetchAllMerchant = () => {
    setLoading(true);
    getMethod("/api/MerchantProfile/all", "get")
      .then((res) => {
        if (res.data.responseCode === "200") {
          setMerchants(
            res.data.result.map((i) => ({
              label: i.name,
              value: i.id,
            }))
          );
        }
      })
      .catch(() => {
        notifyFailed("Something went wrong!");
      })
      .finally(() => setLoading(false));
  };

  const fetchAllFsps = () => {
    setLoading(true);
    getMethod("/api/FSP/GetAll", "get")
      .then((res) => {
        if (res.data.responseCode === "200") {
          setFsps(
            res.data.result.map((i) => ({
              value: i.id,
              label: i.fspName,
            }))
          );
        }
      })
      .catch(() => {
        notifyFailed("Something went wrong!");
      })
      .finally(() => setLoading(false));
  };

  const fetchAllMerchantUsers = () => {
    setLoading(true);
    getMethod("/api/User/all", "get")
      .then((res) => {
        if (res.data.responseCode === "200") {
          setMerchantUsers(
            res.data.result
              .filter((i) => i.roleName === "POSAgent")
              .map((i) => ({
                value: i.fullName,
                label: i.fullName,
              }))
          );
        }
      })
      .catch(() => {
        notifyFailed("Something went wrong!");
      })
      .finally(() => setLoading(false));
  };

  function fetchPosDeviceData() {
    getMethod("/api/POS/GetAllDevice")
      .then((res) => {
        if (res.data?.length) {
          setPosData(
            res.data?.map((i) => ({
              label: i.posSerialNumber,
              value: i.posSerialNumber,
            }))
          );
        }
      })
      .catch(console.log);
  }

  useEffect(() => {
    fetchPosDeviceData();
    fetchAllMerchant();
    fetchAllFsps();
    fetchAllMerchant();
    fetchAllMerchantUsers();
  }, []);

  useEffect(() => {
    if (!isFirst) {
      onSubmitReport();
    }
  }, [currentPage]);

  const validate = (payload) => {
    var error = {};
    // if (!payload.transactionStatus?.length) {
    //   error.transactionStatus = "Please select transaction status";
    // }
    // if (!payload.collectedBy?.length) {
    //   error.collectedBy = "Please select collected by";
    // }
    return error;
  };

  const onSubmitReport = () => {
    setIsFirst(false);
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (!isNoError) {
      return;
    }
    const payload = {
      ...values,
      transactionStatus: values?.transactionStatus?.map((i) => i.value),
      paymentType: values?.paymentType?.map((i) => i.value),
      collectionType: values?.collectionType?.map((i) => i.value),
      posMacID: values?.posMacID?.map((i) => i.value),
      merchantID: values?.merchantID?.map((i) => String(i.value)),
      fspid: values?.fspid?.map((i) => String(i.value)),
      collectedBy: values?.collectedBy?.map((i) => i.value),
    };
    if (values.startDate) {
      payload.dateRange = [new Date(values.startDate).toISOString()];
    }
    if (payload.endDate) {
      payload.dateRange = [
        new Date(values.startDate).toISOString(),
        new Date(values.endDate).toISOString(),
      ];
    }

    payload.pageNumber = currentPage + 1;
    setLoading(true);
    postMethod(
      "/api/Reports/v2/Transaction/GetAll",
      JSON.stringify(payload),
      "post"
    )
      .then((res) => {
        setFilteredList(res.data.result.list);
        let pc = Math.ceil(res.data.result.totalRecords / pageSize);
        setTotalRec(res.data.result.totalRecords);
        setPageCount(pc);
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const exportPDF = () => {
    setPdfLoading(true);
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 10;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);

  const paymentTypes = values.paymentType?.map((i) => i?.value);

    const title = "Transactions";
    let headers = [
      [
        "Date",
        "Collection Type",
        "Collected By",
        "Amount",
        "Commission",
        "Transaction Status",
        "Description",
        "Transaction ID",
      ],
    ];

    let data = filteredList.map((mmo, i) => [
      new Date(mmo.createdDate).toLocaleString(),
      mmo.collectionType ?? "-",
      mmo.collectedBy ?? "-",
      getAmt(mmo.amount) ?? "-",
      getAmt(mmo.commission) ?? "-",
      mmo.transactionStatus ?? "-",
      mmo.description,
      mmo.transactionId,
    ]);

    if (!paymentTypes.includes("Cash")) {
      headers = [
        [
          "Date",
          "Collection Type",
          "Collected By",
          "From Payment Name",
          "To Payment Name",
          "NFC Card ID",
          "Third Party Ref ID",
          "Amount",
          "Commission",
          "Transaction Status",
          "Description",
          "Transaction ID",
          "Transaction Fees",
          "FSP Fees",
          "SmartPay Fees",
        ],
      ];
      data = filteredList.map((mmo, i) => [
        new Date(mmo.createdDate).toLocaleString(),
        mmo.collectionType,
        mmo.collectedBy,
        mmo.fromPaymentName,
        mmo.toPaymentName ?? "-",
        mmo.nfcCardId,
        mmo.thirdPartyRefId,
        getAmt(mmo.amount) ?? "-",
        getAmt(mmo.commission) ?? "-",
        mmo.transactionStatus ?? "-",
        mmo.description,
        mmo.transactionId,
        mmo.transactionFees,
        mmo.fspPortion,
        mmo.smartPayPortion,
      ]);
  
    }
    

    console.log({filteredList, data, headers})
    var summaryReport = false;

    const COLSPAN = 0;
    const total = filteredList.reduce((sum, el) => sum + el.amount, 0);

    let content = {
      startY: 50,
      head: headers,
      margin: { left: 10, right: 10 },
      styles: {
        fontSize: 6, // Set font size for the table
        font: "helvetica", // Set the font family
      },
      body: summaryReport
        ? [
            ...data,
            [
              {
                content: "Total",
                colSpan: COLSPAN,
                styles: { fillColor: [163, 174, 169] },
              },
              {
                content: total,
                colSpan: 1,
                styles: { fillColor: [163, 174, 169] },
              },
            ],
          ]
        : data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
    setTimeout(() => {
      setPdfLoading(false);
    }, 1000);
  };

  const exportToXlsx = (reportData) => {
    const wb = XLSX.utils.book_new();
    let data = filteredList.map((mmo, i) => {
      return {
        Date: new Date(mmo.createdDate).toLocaleString(),
        "Collection Type": mmo.collectionType ?? "-",
        "Collected By": mmo.collectedBy ?? "-",
        Amount: getAmt(mmo.amount) ?? "-",
        Commission: getAmt(mmo.commission) ?? "-",
        "Transaction Status": mmo.transactionStatus ?? "-",
        Description: mmo?.description,
        "Transaction ID": mmo.transactionId,
      };
    });

    let headings = [
      "Date",
      "Collection Type",
      "Collected By",
      "Amount",
      "Commission",
      "Transaction Status",
      "Description",
      "Transaction ID",
    ];
    const paymentTypes = values.paymentType?.map((i) => i?.value);
    if (!paymentTypes.includes("Cash")) {
       headings = [
        "Date",
      "Collection Type",
      "Collected By",
        "From Payment Name",
        "To Payment Name",
        "Amount",
        "Commission",
        "Transaction Status",
        "NFC Card ID",
        "Description",
        "Third Party Ref ID",
        "Transaction ID",
        "Transaction Fees",
        "FSP Fees",
        "SmartPay Fees"
      ];
      data = filteredList.map((mmo, i) => {
        return {
          Date: new Date(mmo.createdDate).toLocaleString(),
          "Collection Type": mmo.collectionType ?? "-",
          "Collected By": mmo.collectedBy ?? "-",
          "From Payment Name": mmo.fromPaymentName,
          "To Payment Name": mmo.toPaymentName ?? "-",

          Amount: getAmt(mmo.amount) ?? "-",
          Commission: getAmt(mmo.commission) ?? "-",
          "Transaction Status": mmo.transactionStatus ?? "-",
          "NFC Card ID": mmo.nfcCardId,
          Description: mmo?.description,
          "Third Party Ref ID": mmo.thirdPartyRefId,
          "Transaction ID": mmo.transactionId,
          "Transaction Fees": mmo.transactionFees,
          "FSP Fees": mmo.fspPortion,
          "SmartPay Fees": mmo.smartPayPortion,
        };
      });
  
    }

    const ws = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Set up column headers
    ws["!cols"] = headings.map((heading) => ({ wch: heading.length + 5 }));

    // Export the workbook to a file
    setTimeout(() => {
      setXlLoading(false);
    }, 1000);
    XLSX.writeFile(wb, "report.xlsx");
  };

  const paymentTypes = values.paymentType?.map((i) => i?.value);

  return (
    <>
      &nbsp; &nbsp; &nbsp; &nbsp;
      <div className="px-4">
        <Row>
          <Col className={errors.startDate || errors.endDate ? "mt-3" : ""}>
            <Row>
              <Col lg="12">
                <small>
                  <strong>Start Date</strong>
                </small>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup className="mb-0">
                  <Input
                    type="date"
                    id="status"
                    className="form-control-alternative status-filter"
                    name="startDate"
                    style={{
                      border: "1px solid #ccc",
                    }}
                    placeholder="Start Date"
                    max={moment().format("YYYY-MM-DD")}
                    onChange={handleInputChange}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                {errors.startDate && (
                  <div className="text-left text-danger">
                    <small>* {errors.startDate}</small>
                  </div>
                )}
              </Col>
            </Row>
          </Col>

          <Col className={errors.endDate || errors.startDate ? "mt-3" : ""}>
            <Row>
              <Col lg="12">
                <small>
                  <strong>End Date</strong>
                </small>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <FormGroup className="mb-0">
                  <Input
                    type="date"
                    id="status"
                    className="form-control-alternative status-filter"
                    name="endDate"
                    placeholder="End Date"
                    min={values.startDate}
                    style={{
                      border: "1px solid #ccc",
                    }}
                    max={moment().format("YYYY-MM-DD")}
                    disabled={!values.startDate}
                    value={values.endDate}
                    onChange={handleInputChange}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                {errors.endDate && (
                  <div className="text-left text-danger">
                    <small>* {errors.endDate}</small>
                  </div>
                )}
              </Col>
            </Row>
          </Col>

          <Col className="w-100">
            <Row>
              <Col lg="12">
                <small>
                  <strong>Merchant</strong>
                </small>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <ReactSelect
                    isMulti
                    isSearchable
                    options={merchants}
                    onChange={(e) => setValues({ ...values, merchantID: e })}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                {errors.merchant && (
                  <div className="text-left text-danger">
                    <small>* {errors.merchant}</small>
                  </div>
                )}
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Col lg="12">
                <small>
                  <strong>FSP</strong>
                </small>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <ReactSelect
                    isMulti
                    isSearchable
                    options={fsps}
                    onChange={(e) => setValues({ ...values, fspid: e })}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                {errors.fsp && (
                  <div className="text-left text-danger">
                    <small>* {errors.fsp}</small>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col lg="12">
                <small>
                  <strong>Collected By</strong>
                </small>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <ReactSelect
                    isMulti
                    isSearchable
                    options={merchantUsers}
                    onChange={(e) => setValues({ ...values, collectedBy: e })}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                {errors.collectedBy && (
                  <div className="text-left text-danger">
                    <small>* {errors.collectedBy}</small>
                  </div>
                )}
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Col lg="12">
                <small>
                  <strong>POS MAC ID</strong>
                </small>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <ReactSelect
                    isMulti
                    isSearchable
                    options={posData}
                    onChange={(e) => setValues({ ...values, posMacID: e })}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Col lg="12">
                <small>
                  <strong>Collection Type</strong>
                </small>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <ReactSelect
                    isMulti
                    isSearchable
                    options={[{ label: "POS", value: "POS" }]}
                    onChange={(e) =>
                      setValues({ ...values, collectionType: e })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                {errors.bankCode && (
                  <div className="text-left text-danger">
                    <small>* {errors.bankCode}</small>
                  </div>
                )}
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Col lg="12">
                <small>
                  <strong>Transaction Status</strong>
                </small>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <ReactSelect
                    isMulti
                    isSearchable
                    options={[
                      { label: "Paid", value: "Paid" },
                      { label: "Rejected", value: "Rejected" },
                    ]}
                    onChange={(e) =>
                      setValues({ ...values, transactionStatus: e })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                {errors.transactionStatus && (
                  <div className="text-left text-danger">
                    <small>* {errors.transactionStatus}</small>
                  </div>
                )}
              </Col>
            </Row>
          </Col>

          <Col>
            <Row>
              <Col lg="12">
                <small>
                  <strong>Payment Type</strong>
                </small>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <ReactSelect
                    isMulti
                    isSearchable
                    options={[
                      { label: "Cash", value: "Cash" },
                      { label: "Wallet", value: "Wallet" },
                    ]}
                    onChange={(e) => setValues({ ...values, paymentType: e })}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                {errors.transactionStatus && (
                  <div className="text-left text-danger">
                    <small>* {errors.transactionStatus}</small>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end w-100">
          <Col>
            <Button color="primary" onClick={onSubmitReport} disabled={loading}>
              Submit
              {loading && <i class="fa fa-spinner fa-spin"></i>}
            </Button>
            {filteredList.length > 0 && (
              <>
                <Button
                  color="primary"
                  onClick={exportPDF}
                  disabled={pdfLoading}
                >
                  Download PDF
                  {pdfLoading && <i class="fa fa-spinner fa-spin"></i>}
                </Button>
                <Button
                  color="primary"
                  onClick={exportToXlsx}
                  disabled={xlLoading}
                >
                  Download XLSX
                  {xlLoading && <i class="fa fa-spinner fa-spin"></i>}
                </Button>
              </>
            )}
          </Col>
        </Row>
      </div>
      <Table className="align-items-center table-flush mt-4" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">Date </th>
            {!paymentTypes.includes("Cash") && (
              <>
                <th scope="col">From Payment Name</th>
                <th scope="col">To Payment Name</th>
                <th scope="col">NFC Card ID</th>
                <th scope="col">Third Party Ref ID</th>
              </>
            )}
            <th scope="col">Collection Type</th>
            <th scope="col">Collected By</th>
            <th scope="col">Amount</th>
            <th scope="col">Commission</th>
            <th scope="col">Transaction Status</th>
            <th scope="col">Description</th>
            <th scope="col">Transaction ID</th>
            
               {!paymentTypes.includes("Cash") && (
                <>
                   <th scope="col">Transaction Fees</th>
            <th scope="col">FSP Fees</th>
            <th scope="col">SmartPay Fees</th>
                </>
              )}
            
          </tr>
        </thead>
        <tbody>
          {filteredList.length > 0 &&
            filteredList.map((mmo, i) => {
              return (
                <tr>
                  <th scope="row">
                    {new Date(mmo.createdDate).toLocaleString()}
                  </th>
                  {!paymentTypes.includes("Cash") && (
                    <>
                      <td>{mmo.fromPaymentName}</td>
                      <td>{mmo.toPaymentName}</td>
                      <td>{mmo.nfcCardId}</td>
                      <td>{mmo.thirdPartyRefId}</td>
                    </>
                  )}

                  <td>{mmo.collectionType}</td>
                  <td>{mmo.collectedBy}</td>
                  <td>{getAmt(mmo.amount)}</td>
                  <td>{getAmt(mmo.commission)}</td>
                  <td>{mmo.transactionStatus}</td>
                  <td className="text-wrap">{mmo?.description}</td>
                  <td>{mmo.transactionId}</td>

                  {!paymentTypes.includes("Cash") && (
                    <>
                        <td>{mmo?.transactionFees}</td>
                  <td>{mmo?.fspPortion}</td>
                  <td>{mmo?.smartPayPortion}</td>
                    </>
                  )}
                
                </tr>
              );
            })}
        </tbody>
      </Table>
      {loading && (
        <div className="text-center font-bold w-100">
          {" "}
          <i class="fa fa-spinner fa-spin"></i>
        </div>
      )}
      {!loading && !filteredList.length && (
        <div className="text-center font-bold w-100">No Records Found</div>
      )}
      <CardFooter className="py-4">
        {filteredList.length > 0 && (
          <Row>
            <Col lg="6">
              <div>
                <small>
                  <strong>Total Records : {totalRec}</strong>
                </small>
              </div>
            </Col>

            <Col lg="6">
              <nav aria-label="...">
                <div className="float-right">
                  <ReactPaginate
                    previousLabel={"prev"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pagesCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"paginationV2"}
                    subContainerClassName={"pages paginationV2"}
                    activeClassName={"active"}
                    forcePage={currentPage}
                  />
                </div>
              </nav>
            </Col>
          </Row>
        )}
      </CardFooter>
    </>
  );
};

export default AllTransactionReport;
