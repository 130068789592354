import React from "react";
import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";

const SmartKorporPaymentTab = ({
  values1,
  handleSubmit1,
  handleInputChange1,
  formState1,
  errors1,
  userResponse1,
  setValues1,
  initialValue1,
  loading1,
  setFormState1,
}) => {
  return (
    <div className="mt-2">
      <Form onSubmit={handleSubmit1} autoComplete="off">
        <div className="pl-lg-4">
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-phone">
                  Reference Number
                </label>
                <Input
                  className="form-control-alternative"
                  // country={"sl"}
                  id="input-phone"
                  name="refNo"
                  value={values1.refNo}
                  // onlyCountries={MOBILE_ONLY_COUNTRY}
                  // countryCodeEditable={false}
                  onChange={handleInputChange1}
                  placeholder="Reference number"
                  required
                  maxLength={10}
                  minLength={5}
                  disabled={formState1 === 2}
                />
                {errors1.refNo && (
                  <div className="text-left text-danger">
                    <small>* {errors1.refNo}</small>
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
          

          {formState1 === 2 && (
            <>
            <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-phone">
                  Amount
                </label>
                <Input
                  className="form-control-alternative"
                  // country={"sl"}
                  id="input-phone"
                  name="amount"
                  value={values1.amount}
                  // onlyCountries={MOBILE_ONLY_COUNTRY}
                  // countryCodeEditable={false}
                  onChange={handleInputChange1}
                  placeholder="Amount"
                  maxLength={50}
                  disabled={formState1 === 2}
                />
                 
              </FormGroup>
            </Col>
          </Row> 
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-currency"
                    >
                      Currency
                    </label>
                    <Input
                      className="form-control-alternative"
                      // country={"sl"}
                      id="input-currency"
                      name="currency"
                      type="tel"
                      value={values1.currency}
                      // onlyCountries={MOBILE_ONLY_COUNTRY}
                      // countryCodeEditable={false}
                      onChange={handleInputChange1}
                      placeholder="Currency"
                      required
                      disabled={formState1 === 2}
                    />
                    {errors1.currency && (
                      <div className="text-left text-danger">
                        <small>* {errors1.currency}</small>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-customer-name"
                    >
                      Customer name
                    </label>
                    <Input
                      className="form-control-alternative"
                      // country={"sl"}
                      id="input-phone"
                      name="refNo"
                      value={values1.customername}
                      // onlyCountries={MOBILE_ONLY_COUNTRY}
                      // countryCodeEditable={false}
                      onChange={handleInputChange1}
                      placeholder="Customer name"
                      required
                      maxLength={10}
                      minLength={5}
                      disabled={formState1 === 2}
                    />
                    {errors1.customerName && (
                      <div className="text-left text-danger">
                        <small>* {errors1.customerName}</small>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-customer_account_number"
                    >
                      Account number
                    </label>
                    <Input
                      className="form-control-alternative"
                      // country={"sl"}
                      id="input-customer_account_number"
                      name="customer_account_number"
                      type="tel"
                      value={values1.customer_account_number}
                      // onlyCountries={MOBILE_ONLY_COUNTRY}
                      // countryCodeEditable={false}
                      onChange={handleInputChange1}
                      placeholder="Account number"
                      required
                      disabled={formState1 === 1}
                    />
                    {errors1.customer_account_number && (
                      <div className="text-left text-danger">
                        <small>* {errors1.customer_account_number}</small>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-pin">
                      Pin
                    </label>
                    <Input
                      className="form-control-alternative"
                      // country={"sl"}
                      id="input-pin"
                      name="pin"
                      type="tel"
                      value={values1.pin}
                      onChange={handleInputChange1}
                      placeholder="Pin"
                      required
                      maxLength={10}
                    />
                    {errors1.pin && (
                      <div className="text-left text-danger">
                        <small>* {errors1.pin}</small>
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}
          <Row>
            {formState1 === 2 && (
              <Col>
                <div className="float-left">
                  <Button
                    className="btn float-right"
                    color="primary"
                    type="button"
                    disabled={loading1}
                    onClick={() => {
                      setValues1(initialValue1);
                      setFormState1(1);
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            )}
            <Col>
              <div>
                <Button
                  className="btn float-right"
                  color="primary"
                  type="submit"
                  disabled={loading1}
                >
                  Submit {loading1 && <i class="fa fa-spinner fa-spin"></i>}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default SmartKorporPaymentTab;
