/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  Row,
  Table,
} from "reactstrap";

import FullPageLoader from "components/FullPageLoader/fullpageloader";
import Header from "components/Headers/Header";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { getMethod } from "services/httpServices";
import { getSortCls } from "services/util";

import { btnCss, iconCss } from "../../appConfig";
import AddFspTransactionFees from "./add-fsp-transaction-fees";

const sortIS = {
  sortFullName: 0,
};

function FspTransactionFeesList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(sortIS);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [addFeeOpen, setAddFeeOpen] = React.useState(false);

  function fetchData() {
    setLoading(true);
    getMethod("/api/FSP/Profile/Fees/GetAll")
      .then((res) => {
        if (res.data?.result?.length) {
          setData(res.data.result);
        }
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const sortHandle = (fieldValue, fieldName, sortBy) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = "1";
    } else if (fieldValue == "1") {
      newValue = "2";
    } else if (fieldValue == "2") {
      newValue = "1";
    }
    setSort({ ...sortIS, [fieldName]: newValue });
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSortBy(sortBy);
  };

  let filteredData = data.sort((a, b) => {
    if (sortDirection === "asc") {
      return typeof a[sortBy] === "string"
        ? String(a[sortBy]).localeCompare(String(b[sortBy]))
        : a[sortBy] - b[sortBy];
    }
    if (sortDirection === "desc") {
      return typeof a[sortBy] === "string"
        ? String(b[sortBy]).localeCompare(String(a[sortBy]))
        : b[sortBy] - a[sortBy];
    }
    return 0;
  });

  if (searchText) {
    filteredData = data.filter((item) =>
      Object.values(item) // Get all values of the item object
        .some(
          (value) =>
            String(value) // Convert each value to a string
              .toLocaleLowerCase() // Make it case-insensitive
              .includes(searchText?.trim().toLocaleLowerCase()) // Check if it includes the search text
        )
    );
  }

  const closeDeleteModal = () => {
    fetchData();
    setAddFeeOpen(false);
  };

  return (
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Modal isOpen={addFeeOpen} style={{ width: "70vw", maxWidth: "70vw" }}>
          <ModalBody style={{ padding: 0, width: "100%" }}>
            <AddFspTransactionFees closeModal={closeDeleteModal} />
          </ModalBody>
        </Modal>

        <Card className="shadow">
          <CardHeader className="border-0 d-flex justify-content-end align-items-center">
            <FormGroup className="mb-0 navbar-search ">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="search-cls"
                  placeholder="Search"
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            &nbsp;&nbsp;
            <Button
              className="my-4"
              color="primary"
              onClick={() => setAddFeeOpen(true)}
            >
              Add Fee
            </Button>
            &nbsp;&nbsp;
            <Button
              color="primary"
              onClick={fetchData}
              size="sm"
              style={btnCss}
              data-toggle="tooltip"
              title="Refresh"
            >
              <i className="fas fa-redo fa-lg" style={iconCss} />
            </Button>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th
                  scope="col"
                  onClick={() =>
                    sortHandle(sort.sortFullName, "sortFullName", "feeType")
                  }
                >
                  Name
                  <button style={btnCss}>
                    <i
                      className={getSortCls(sort.sortFullName)}
                      onClick={() =>
                        sortHandle(sort.sortFullName, "sortFullName", "feeType")
                      }
                    />
                  </button>
                </th>
                <th scope="col">fee Name</th>
                <th scope="col">fee Value</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData
                  .slice(currentPage * 10, (currentPage + 1) * 10)
                  .map((teller, i) => {
                    return (
                      <tr key={i}>
                        <th scope="row">{teller.feeType ?? "-"}</th>
                        <td>{teller.feeName ?? "-"}</td>
                        <td>
                          {teller?.feeCalculateMethod === 2
                            ? `${teller.feeValue}%`
                            : teller?.feeCalculateMethod === 1
                            ? `${Number(teller.feeValue).toFixed(2)}`
                            : teller.feeValue}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
            {loading && <FullPageLoader label="Fetching..." />}
            {!loading && !filteredData.length && <NoRecordFound />}
          </Table>
          <CardFooter className="py-4">
            {filteredData.length > 0 && (
              <Row>
                <Col lg="6">
                  <div>
                    <small>
                      <strong>Total Records : {filteredData.length}</strong>
                    </small>
                  </div>
                </Col>

                <Col lg="6">
                  <nav aria-label="...">
                    <div className="float-right">
                      <ReactPaginate
                        previousLabel="prev"
                        nextLabel="next"
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={Math.ceil(filteredData.length / 10)}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName="paginationV2"
                        subContainerClassName="pages paginationV2"
                        activeClassName="active"
                        forcePage={currentPage}
                      />
                    </div>
                  </nav>
                </Col>
              </Row>
            )}
          </CardFooter>{" "}
        </Card>
      </Container>
    </div>
  );
}

export default FspTransactionFeesList;
