import axios from 'axios';
import { API_ENDPOINTS, TOKEN, LOGIN_API_ENDPOINTS } from '../appConfig';


axios.interceptors.request.use(
  (req) => {
    // Add configurations here
    if (req.data) {
      if (req.data instanceof FormData) {
        req.data.append('LoggedInUserId', getUserId());
      } else if (typeof(req.data) === 'string') {
        if (checkJSON(req.data)) {
          req.data = addLoggedInUserId(req.data);
        }
      } else if (typeof(req.data) === 'object') {
        if (
          req.data.hasOwnProperty('SearchText') &&
          req.data.SearchText.length
        ) {
          req.data.SearchText = req.data.SearchText.trim();
        }
        req.data['LoggedInUserId'] = getUserId();
      }
    } 
    return req;

    // const CancelToken = axios.CancelToken;
    // return {
    //   ...req,
    //   cancelToken: new CancelToken((cancel) => cancel('Cancel repeated request'))
    // };
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => {
    if (
      res && 
      res.data && 
      res.data.responseCode !== '200' &&
      res.data.responseMessage
    ) {
      let ob = {
        data: {
          ...res.data
        }
      };
      return Promise.resolve(ob);
    } else {
      return Promise.resolve(res);
    }
  }, 
  (error) => {

  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    console.log("Oops!", "Something went wrong!", "error");
  }
  if (error && error.response && error.response.status === 401) {
    window.localStorage.clear();
    window.location = "/";
    let ob = {
      data: {
        responseCode: '400',
        responseMessage: 'Session Expires'
      }
    };
    return Promise.resolve(ob);
  } else if (error && error.response && error.response.status === 400) {
    let ob = {
      data: {
        responseCode: '400',
        responseMessage: error.response.data.responseMessage ? 
        error.response.data.responseMessage : typeof error.response.data?.result === "string" ? error.response.data?.result : 'Bad Request'
      }
    };
    return Promise.resolve(ob);
  } else {
    return Promise.reject(error);
  }
});


const checkJSON = (str) => {
  try {
    let ob = JSON.parse(str);
    if (typeof ob === 'object') {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  } 
}

const addLoggedInUserId = (str) => {
  try {
    let ob = JSON.parse(str);
    ob.LoggedInUserId = getUserId();
    if (
      ob.hasOwnProperty('SearchText') &&
      ob.SearchText.length
    ) {
      ob.SearchText = ob.SearchText.trim();
    }
    return JSON.stringify(ob);
  } catch (e) {
    return str;
  }
}

let isLoggedIn =  null;
let loginType = null;
let loginUserEmail = null;
let loginUserName =  null;
let loginUserImageB64 = null;
let loginUserId = null;
let loginSuperAgentId = null;
let loginPswd = null;
// const setAuthTokenHeader = () => {
//   const token = authService.getAuthToken();
//   if (!token || token === "undefined") {
//     window.location = "/";
//     logout();
//     return null;
//   } else {
//     return {
//       access_token: token,
//     };
//   }
// };

// export const fetchMethod = (url, data, method) => axios({
//   url: `${API_ENDPOINTS}${url}`,
//   method,
//   data,
//   // headers: isAuthToken ? { ...setAuthTokenHeader() } : null,
// });

const setAuthTokenHeader = () => {
  // const token =  window.localStorage.getItem("token");
  // if (!token) {
  //   window.location = "/";
  //   return null;
  // }
  return {
    Authorization: `Bearer ${TOKEN}`,
  };
};

const getAuthToken = () => {
  const token =  window.localStorage.getItem("token");
  if (!token) {
    window.location = "/";
    return null;
  }
  return token;
}
export const fetchMethod = (url, data, method, isAuthToken) => {
  return axios({
    url: `${API_ENDPOINTS}${url}`,
    method,
    data,
    headers: isAuthToken ? { ...setAuthTokenHeader() } : null,
  });
};


export const postMethod = async (url, data, method) => {
  const token = getAuthToken();
  return axios({
    url: `${API_ENDPOINTS}${url}`,
    method,
    data,
    headers: {
      "Content-Type": "application/json",
      // "Authorization": `Bearer ${TOKEN}`
      "Authorization": 'Bearer ' + token
    },
  });
}

export const getMethod = async (url, method) => {
  const token = getAuthToken();
  return await axios({
    url: `${API_ENDPOINTS}${url}`,
    method,
    // data,
    headers: {
      "Content-Type": "application/json",
      // "Authorization": `Bearer ${TOKEN}`
      "Authorization": 'Bearer ' + token
    },
  });
}

export const getMethodDefaultToken = (url, method) => {
  // const token = getAuthToken();
  return axios({
    url: `${API_ENDPOINTS}${url}`,
    method,
    // data,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${TOKEN}`
      // "Authorization": 'Bearer ' + token
    },
  });
}

export const postMethodDefaultToken = (url, data, method) => {
  // const token = getAuthToken();
  return axios({
    url: `${API_ENDPOINTS}${url}`,
    method,
    data,
    headers: {
      "Content-Type": "application/json",
      // "Authorization": `Bearer ${TOKEN}`
      // "Authorization": 'Bearer ' + token
    },
  });
}

export const loginMethod = (url, data, method) => {
  return axios({
    url: `${API_ENDPOINTS}${url}`,
    method,
    data,
    headers: {
      "Content-Type": "application/json"
    },
  });
}

export const adminMethod = (url, data, method) => {
  const token = getAuthToken();
  return axios({
    url: `${LOGIN_API_ENDPOINTS}${url}`,
    method,
    data,
    headers: {
      "Content-Type": "application/json",
      "Authorization": 'Bearer ' + token
    },
  });
}

// export const handleError = (error) => {

//   if (error && error.response) {
//     if (error.response.status == 401) {
//       window. window.localStorage.clear();
//       window.location = "/";
//     }
//   } else {

//   }
// }

export const getLoggedIn = () => {
  if (
    !isLoggedIn &&
    window.localStorage.getItem('validUser') === "true"
  ) {
    return true
  }
  return isLoggedIn;
}

export const setLoggedIn = (value) => {
  isLoggedIn = value;
}

export const getLoginType = () => {
  if (!loginType &&  window.localStorage.getItem('userType') !== "null") {
    return  window.localStorage.getItem('userType');
  }
  return loginType;
}

export const setLoginType = (type) => {
  loginType = type;
   window.localStorage.setItem('userType', type);
}

export const getUserEmail = () => {
  if (!loginUserEmail &&  window.localStorage.getItem('userEmail') !== "null") {
    return  window.localStorage.getItem('userEmail');
  }
  return loginUserEmail;
}

export const setUserEmail = (email) => {
  loginUserEmail = email.trim();
   window.localStorage.setItem('userEmail', loginUserEmail);
}

export const setUserName = (name) => {
  loginUserName = name;
   window.localStorage.setItem('userName', name);
}

export const getUserName = () => {
  if (!loginUserName &&  window.localStorage.getItem('userName') !== "null") {
    return  window.localStorage.getItem('userName');
  }
  return loginUserName;
}

export const setUserB64 = (b64) => {
  loginUserImageB64 = b64;
   window.localStorage.setItem('userImg', b64);
}

export const getUserB64 = () => {
  if (!loginUserImageB64 &&  window.localStorage.getItem('userImg') !== "null") {
    return  window.localStorage.getItem('userImg');
  }
  return loginUserImageB64;
}

export const setUserId = (id) => {
  loginUserId = id;
   window.localStorage.setItem('userId', id);
}

export const setSuperAgentId = (id) => {
  loginSuperAgentId = id;
   window.localStorage.setItem('loginSuperAgentId', id);
}

export const getUserId = () => {
  if (!loginUserId &&  window.localStorage.getItem('userId') !== "null") {
    return  window.localStorage.getItem('userId');
  }
  return loginUserId;
}

export const getSuperAgentId = () => {
  if (!loginSuperAgentId &&  window.localStorage.getItem('loginSuperAgentId') !== "null") {
    return  window.localStorage.getItem('loginSuperAgentId');
  }
  return loginSuperAgentId;
}

export const checkSpace = (value) => {
  const spaceRegex = /^\s*$/;
  if (!spaceRegex.test(value) || !value.length) {
    return true;
  } else {
    return false;
  }
}

export const setPswd = (value) => {
  loginPswd = value;
   window.localStorage.setItem('pswd', value);
}

export const getPswd = () => {
  if (!loginPswd &&  window.localStorage.getItem('pswd') !== "null") {
    return  window.localStorage.getItem('pswd');
  }
  return loginPswd;
}

export default {
  fetchMethod,
  postMethod,
  getMethod,
  loginMethod,
  setLoggedIn,
  getLoggedIn,
  getLoginType,
  setLoginType,
  getUserEmail,
  setUserEmail,
  adminMethod,
  getUserB64,
  setUserB64,
  getUserName,
  checkSpace,
  setPswd,
  getPswd,
  getMethodDefaultToken,
  postMethodDefaultToken
};
