import classnames from 'classnames';
import { useState } from 'react';
import { Card, CardBody, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import Header from 'components/Headers/Header.js';
import 'react-phone-input-2/lib/style.css';
import POSGroupList from 'views/pos-group/pos-group-list';

import PosTerminalList from './pos-terminal-list';

const navItemCls = {
  minWidth: '120px',
  textAlign: 'center',
  cursor: 'pointer',
};
const POSTerminal = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Header />
      <Container className="mt--8" fluid>
        <Card>
          <CardBody>
            <Nav tabs>
              <NavItem style={navItemCls}>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggle('1');
                  }}
                  style={{ padding: '10px' }}
                >
                  POS Terminal
                </NavLink>
              </NavItem>
              <NavItem style={navItemCls}>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggle('2');
                  }}
                  style={{ padding: '10px' }}
                >
                  POS Group
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <PosTerminalList />
              </TabPane>

              <TabPane tabId="2">
                <POSGroupList />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default POSTerminal;
