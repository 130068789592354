/* eslint-disable eqeqeq */
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import Header from "components/Headers/Header";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
} from "reactstrap";
import { getMethod } from "services/httpServices";
import { getUserId } from "services/httpServices";
import { btnCss, iconCss } from "../../appConfig";
import { getSortCls } from "services/util";
import ReactPaginate from "react-paginate";

const sortIS = {
  sortTellerID: 0,
  sortRegisteredDate: 0,
  sortTellerName: 0,
  sortTodaysSales: 0,
  sortMtdSales: 0,
  sortYtdSales: 0,
};

function POS() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(sortIS);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  function fetchData() {
    setLoading(true);
    getMethod("/api/MerchantProfile/POSLandingPage?MerchantId=" + getUserId())
      .then((res) => {
        if (res.data?.result?.length) {
          setData(res.data.result);
        }
      })
      .catch(console.log)
      .finally(() => {
        setLoading(false);
      });
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const sortHandle = (fieldValue, fieldName, sortBy) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = "1";
    } else if (fieldValue == "1") {
      newValue = "2";
    } else if (fieldValue == "2") {
      newValue = "1";
    }
    setSort({ ...sortIS, [fieldName]: newValue });
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSortBy(sortBy);
  };

  let filteredData = data.sort((a, b) => {
    if (sortDirection === "asc") {
      return typeof a[sortBy] === "string"
        ? String(a[sortBy]).localeCompare(String(b[sortBy]))
        : a[sortBy] - b[sortBy];
    } else if (sortDirection === "desc") {
      return typeof a[sortBy] === "string"
        ? String(b[sortBy]).localeCompare(String(a[sortBy]))
        : b[sortBy] - a[sortBy];
    } else {
      return 0;
    }
  });

  if (searchText) {
    filteredData = filteredData.filter((item) =>
      String(item?.registeredLocation).includes(searchText?.trim())
    );
  }

  return (
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <CardHeader className="border-0 d-flex justify-content-end align-items-center">
            <FormGroup className="mb-0 navbar-search ">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="search-cls"
                  placeholder="Search"
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </InputGroup>
            </FormGroup>
            <Button
              color="primary"
              onClick={fetchData}
              size="sm"
              style={btnCss}
              data-toggle="tooltip"
              title="Refresh"
            >
              <i class="fas fa-redo fa-lg" style={iconCss}></i>
            </Button>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">POS ID </th>
                <th
                  scope="col"
                  onClick={() =>
                    sortHandle(
                      sort.sortRegisteredLocation,
                      "sortRegisteredDate",
                      "registeredDate"
                    )
                  }
                >
                  Date Registered{" "}
                  <button style={btnCss}>
                    <i
                      className={getSortCls(sort.sortRegisteredDate)}
                      onClick={() =>
                        sortHandle(
                          sort.sortRegisteredDate,
                          "sortRegisteredDate",
                          "registeredDate"
                        )
                      }
                    />
                  </button>
                </th>
                <th scope="col">Registered Location </th>
                <th
                  scope="col"
                  onClick={() =>
                    sortHandle(
                      sort.sortTodaysSales,
                      "sortTodaysSales",
                      "todaysSales"
                    )
                  }
                >
                  Today's Sales{" "}
                  <button style={btnCss}>
                    <i
                      className={getSortCls(sort.sortTodaysSales)}
                      onClick={() =>
                        sortHandle(
                          sort.sortTodaysSales,
                          "sortTodaysSales",
                          "todaysSales"
                        )
                      }
                    />
                  </button>
                </th>
                <th
                  scope="col"
                  onClick={() =>
                    sortHandle(sort.sortMtdSales, "sortMtdSales", "mtdSales")
                  }
                >
                  MTD Sales{" "}
                  <button style={btnCss}>
                    <i
                      className={getSortCls(sort.sortMtdSales)}
                      onClick={() =>
                        sortHandle(
                          sort.sortMtdSales,
                          "sortMtdSales",
                          "mtdSales"
                        )
                      }
                    />
                  </button>
                </th>
                <th
                  scope="col"
                  onClick={() =>
                    sortHandle(sort.sortYtdSales, "sortYtdSales", "ytdSales")
                  }
                >
                  YTD Sales{" "}
                  <button style={btnCss}>
                    <i
                      className={getSortCls(sort.sortYtdSales)}
                      onClick={() =>
                        sortHandle(
                          sort.sortYtdSales,
                          "sortYtdSales",
                          "ytdSales"
                        )
                      }
                    />
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData
                  .slice(currentPage * 10, (currentPage + 1) * 10)
                  .map((teller, i) => {
                    return (
                      <tr>
                        <th scope="row">{teller.pos ?? "-"}</th>
                        <td>{teller.registeredDate ?? "-"}</td>
                        <td>{teller.registeredLocation ?? "-"}</td>
                        <td>{teller.todaysSales ?? "-"}</td>
                        <td>{teller.mtdSales ?? "-"}</td>
                        <td>{teller.ytdSales ?? "-"}</td>
                      </tr>
                    );
                  })}
            </tbody>
            {loading && <FullPageLoader label={"Fetching..."} />}
            {!loading && !filteredData.length && <NoRecordFound />}
          </Table>
          <CardFooter className="py-4">
            {filteredData.length > 0 && (
              <Row>
                <Col lg="6">
                  <div>
                    <small>
                      <strong>Total Records : {filteredData.length}</strong>
                    </small>
                  </div>
                </Col>

                <Col lg="6">
                  <nav aria-label="...">
                    <div className="float-right">
                      <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={Math.ceil(filteredData.length / 10)}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={"paginationV2"}
                        subContainerClassName={"pages paginationV2"}
                        activeClassName={"active"}
                        forcePage={currentPage}
                      />
                    </div>
                  </nav>
                </Col>
              </Row>
            )}
          </CardFooter>{" "}
        </Card>
      </Container>
    </div>
  );
}

export default POS;
