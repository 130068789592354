import React from "react";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import { Table } from "reactstrap";
import moment from "moment";
import { reportloader } from "services/util";
import { btnCss } from "../../appConfig";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import { getAmt } from "services/util";

const reportloaderContainer = {
  position: "absolute",
  top: "240px",
  left: 0,
  width: "100%",
  heigth: "100%",
  backgroundColor: "#F8F8F8AD",
};

const SettlementLiquidityAnalysisReportTable = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          padding: 24,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "50%" }}>
            <strong>Report Date:</strong> {moment().format("Do MMMM, YYYY")}
          </div>
          <div style={{ width: "50%" }}>
            <strong>Time:</strong> {moment().format("HH:mm")}
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "50%" }}>
            <strong>Total Bank Balances:</strong>{" "}
            {getAmt(props?.datalist?.totalBankBalance || 0)}
          </div>
          <div style={{ width: "50%" }}>
            <strong>SL Ratio:</strong> {props?.datalist?.slRatio}
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "50%" }}>
            <strong>Pending Settlements:</strong>{" "}
            {getAmt(props?.datalist?.overallPendingsettlement || 0)}
          </div>
          <div style={{ width: "50%" }}>
            <strong>Net Position:</strong>{" "}
            <span
              style={{
                color: props?.datalist?.netPosition < 0 ? "red" : "green",
              }}
            >
              {props?.datalist?.netPosition}
            </span>
          </div>
        </div>
      </div>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">
              Bank Type
              <button style={btnCss}></button>
            </th>

            <th scope="col">
              Amount
              <button style={btnCss}></button>
            </th>
            <th scope="col">
              Percent Share
              <button style={btnCss}></button>
            </th>
            <th scope="col">Required Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Settlement Bank</td>
            <td>{getAmt(props?.datalist?.settlemenetBankBalance || 0)}</td>
            <td>{props?.datalist?.percentagesettlementshare?.toFixed(2)}%</td>
            <td rowSpan={"2"}>
              {props?.datalist?.netPosition < 0
                ? "Please transfer funds from Collection to Settlement Account to meet the settlement cash requirements"
                : "Liquidity position OK"}
            </td>
          </tr>
          <tr>
            <td>Collection Bank</td>
            <td>{getAmt(props?.datalist?.collectionBankBalance || 0)}</td>
            <td>{props?.datalist?.percentagecollectionshare?.toFixed(2)}%</td>
          </tr>
        </tbody>
        {props.loading && (
          <FullPageLoader
            label={"Fetching..."}
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        )}
        {!Object.entries(props?.datalist)?.length && (
          <NoRecordFound
            loaderContainer={reportloaderContainer}
            loader={reportloader}
          />
        )}
      </Table>
    </>
  );
};
export default SettlementLiquidityAnalysisReportTable;
