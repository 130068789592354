/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  ModalHeader,
  Col,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import AddMerchantLocation from "./add-merchant-location";
import { useToasts } from "react-toast-notifications";
import { btnCss, iconCss } from "../../appConfig";
import { postMethod } from "../../services/httpServices";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import ReactPaginate from "react-paginate";
import { getSortCls } from "services/util";
import { getPrivileges } from "services/util";
import ViewMerchantLocation from "./view-merchant-location";
import EditMerchantLocation from "./edit-merchant-location";

const sortIS = {
  merchantName: 0,
  district: 0,
  chiefdomOrZone: 0,
  gpsCoordinate: 0,
};

const MerchantLocationsList = (props) => {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [merchantObj, setMerchantObj] = useState(null);
  const [openEdit, setOpenEditModal] = useState(false);
  const [editObj, setEditObj] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(10);
  const [pagesCount, setPageCount] = useState(0);
  const [deleteObj, setDeleteObj] = useState(null);
  const [openDelModal, setOpenDelModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [totalRec, setTotalRec] = useState(0);
  const [sort, setSort] = useState(sortIS);
  const [refresh, setRefresh] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  const { addToast } = useToasts();
  const privileges = getPrivileges("merchant");

  useEffect(() => {
    if (!refresh && isMounted) {
      if ((searchText && searchText.length >= 3) || searchText.length === 0) {
        fetchMerchant();
      }
    }
  }, [searchText]);

  useEffect(() => {
    fetchMerchant();
  }, [currentPage]);

  useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        fetchMerchant();
      }
    }
  }, [sort]);

  useEffect(() => {
    if (refresh && isMounted) {
      setSearchText("");
      setSort(sortIS);
      if (currentPage === 0) {
        fetchMerchant();
      } else {
        setCurrentPage(0);
      }
    }
  }, [refresh]);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    fetchMerchant();
  };

  const showMerchant = (obj) => {
    setMerchantObj(obj);
    setView(true);
  };

  const closeViewModal = () => {
    setView(false);
  };

  const closeEditModal = () => {
    setOpenEditModal(false);
    fetchMerchant();
  };

  const openDelete = (mmo) => {
    setDeleteObj(mmo);
    setOpenDelModal(true);
  };

  const closeDeleteModal = () => {
    setDeleteObj(null);
    setOpenDelModal(false);
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const fetchMerchant = async () => {
    let errorMsg = "";
    setLoading(true);
    let data = {
      PageNumber: Number(currentPage + 1),
      PerPage: pageSize,
    };

    if (searchText && searchText.length >= 3) {
      data["SearchText"] = searchText;
    }

    if (sort.merchantName == "1") {
      data["SortingOn"] = "merchantName";
      data["IsDescending"] = false;
    } else if (sort.merchantName == "2") {
      data["SortingOn"] = "merchantName";
      data["IsDescending"] = true;
    } else if (sort.district == "1") {
      data["SortingOn"] = "district";
      data["IsDescending"] = false;
    } else if (sort.district == "2") {
      data["SortingOn"] = "district";
      data["IsDescending"] = true;
    } else if (sort.chiefdomOrZone == "1") {
      data["SortingOn"] = "chiefdomOrZone";
      data["IsDescending"] = false;
    } else if (sort.chiefdomOrZone == "2") {
      data["SortingOn"] = "chiefdomOrZone";
      data["IsDescending"] = true;
    } else if (sort.gpsCoordinate == "1") {
      data["SortingOn"] = "gpsCoordinate";
      data["IsDescending"] = false;
    } else if (sort.gpsCoordinate == "2") {
      data["SortingOn"] = "gpsCoordinate";
      data["IsDescending"] = true;
    }

    postMethod("/api/MerchantProfile/Location/Search", data, "post")
      .then((res) => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            if (!res.data.result.list || !res.data.result.list.length) {
              res.data.result.list = [];
            }
            setFilteredList(res.data.result.list);
            let pc = Math.ceil(res.data.result.totalRecords / pageSize);
            setTotalRec(res.data.result.totalRecords);
            setPageCount(pc);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        setRefresh(false);
        setIsMounted(true);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const fetchMerchantById = (id, type) => {
    let errorMsg = "";
    postMethod(
      "/api/MerchantProfile/Location/GetByMerchantId?merchantId=" + id,
      {},
      "get"
    )
      .then((res) => {
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            if (type == "edit") {
              setEditObj(res.data.result[0]);
              setOpenEditModal(true);
            } else if (type == "view") {
              showMerchant(res.data.result[0]);
            }
          } else if (res.data.responseCode === "400") {
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const deleteMerchant = () => {
    let errorMsg = "";
    setModalLoading(true);
    let url = `/api/MerchantProfile/Location/BlockUnblock?locationId=${
      deleteObj.merchantLocationsid
    }&blocked=${!deleteObj.isBlocked}`;
    postMethod(url, {}, "post")
      .then((res) => {
        setModalLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            let text = deleteObj.isBlocked
              ? "Merchant location unblocked successfully"
              : "Merchant location blocked succsessfully";
            notifySuccess(text);
            closeDeleteModal();
            fetchMerchant();
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setModalLoading(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      })
      .finally(() => {
        closeDeleteModal();
      });
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const getSearch = (val) => {
    setSearchText(val);
  };

  const reloadList = () => {
    setSearchText("");
    setRefresh(true);
  };

  const getBlockText = () => {
    let str = "block";
    if (deleteObj?.isBlocked) {
      str = "unblock";
    }

    return `Do you want to ${str} ${deleteObj?.merchantName} merchant location?`;
  };

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = "1";
    } else if (fieldValue == "1") {
      newValue = "2";
    } else if (fieldValue == "2") {
      newValue = "1";
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}

                <Form
                  className="form-inline mr-3 d-md-flex ml-lg-auto"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup className="mb-0 navbar-search ">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="search-cls"
                        placeholder="Search"
                        type="text"
                        value={searchText}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>
                  &nbsp;&nbsp;
                  {privileges.add && (
                    <Button
                      className="my-4"
                      color="primary"
                      onClick={openModal}
                    >
                      Add Merchant Location
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={reloadList}
                    size="sm"
                    style={btnCss}
                    data-toggle="tooltip"
                    title="Refresh"
                  >
                    <i class="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>
                </Form>
              </CardHeader>

              <Modal
                isOpen={open}
                // fade={false}
                style={{ width: "70vw", maxWidth: "70vw" }}
              >
                <ModalBody style={{ padding: 0 }}>
                  <AddMerchantLocation closeModal={closeModal} />
                </ModalBody>
              </Modal>

              <Modal isOpen={view} style={{ width: "70vw", maxWidth: "70vw" }}>
                <ModalBody style={{ padding: 0 }}>
                  <ViewMerchantLocation
                    closeModal={closeViewModal}
                    values={merchantObj}
                  />
                </ModalBody>
              </Modal>

              <Modal
                isOpen={openEdit}
                style={{ width: "70vw", maxWidth: "70vw" }}
              >
                <ModalBody style={{ padding: 0 }}>
                  <EditMerchantLocation
                    closeModal={closeEditModal}
                    values={editObj}
                  />
                </ModalBody>
              </Modal>

              <Modal isOpen={openDelModal} className="modal-sm">
                <ModalHeader className="bg-white border-0">
                  <h3 className="mb-0">
                    {deleteObj && deleteObj.isBlocked
                      ? "Unblock Merchant Location"
                      : "Block Merchant Location"}
                  </h3>
                </ModalHeader>

                <ModalBody>
                  <div>
                    {deleteObj && deleteObj.merchantName ? (
                      <div>
                        <p className="text-left">{getBlockText()}</p>
                        <div className="text-right">
                          <Button
                            color="primary"
                            onClick={deleteMerchant}
                            disabled={modalLoading}
                          >
                            Yes{" "}
                            {modalLoading && (
                              <i class="fa fa-spinner fa-spin"></i>
                            )}
                          </Button>{" "}
                          <Button
                            color="secondary"
                            onClick={closeDeleteModal}
                            disabled={modalLoading}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <> </>
                    )}
                  </div>
                </ModalBody>
              </Modal>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th
                      scope="col"
                      onClick={() =>
                        sortHandle(sort.merchantName, "merchantName")
                      }
                    >
                      Merchant Name
                      <button style={btnCss}>
                        <i
                          className={getSortCls(sort.merchantName)}
                          onClick={() =>
                            sortHandle(sort.merchantName, "merchantName")
                          }
                        />
                      </button>
                    </th>
                    <th
                      scope="col"
                      onClick={() => sortHandle(sort.district, "district")}
                    >
                      District
                      <button style={btnCss}>
                        <i
                          className={getSortCls(sort.district)}
                          onClick={() => sortHandle(sort.district, "district")}
                        />
                      </button>
                    </th>
                    <th
                      scope="col"
                      onClick={() =>
                        sortHandle(sort.chiefdomOrZone, "chiefdomOrZone")
                      }
                    >
                      Chief dom Or Zone
                      <button style={btnCss}>
                        <i
                          className={getSortCls(sort.chiefdomOrZone)}
                          onClick={() =>
                            sortHandle(sort.chiefdomOrZone, "chiefdomOrZone")
                          }
                        />
                      </button>
                    </th>
                    <th
                      scope="col"
                      onClick={() =>
                        sortHandle(sort.gpsCoordinate, "gpsCoordinate")
                      }
                    >
                      GPS Coordinates
                      <button style={btnCss}>
                        <i
                          className={getSortCls(sort.gpsCoordinate)}
                          onClick={() =>
                            sortHandle(sort.gpsCoordinate, "gpsCoordinate")
                          }
                        />
                      </button>
                    </th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredList.length > 0 &&
                    filteredList.map((mmo, i) => {
                      return (
                        <tr>
                          <th scope="row">{mmo.merchantName}</th>
                          <td>{mmo.district}</td>
                          <td>{mmo.chiefdomOrZone}</td>
                          <td>{mmo.gpsCoordinate}</td>
                          <td>
                            {privileges.view && (
                              <Button
                                color="primary"
                                onClick={() =>
                                  fetchMerchantById(mmo.merchantId, "view")
                                }
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="View"
                              >
                                <i class="fas fa-eye fa-lg" style={iconCss}></i>
                              </Button>
                            )}

                            {privileges.edit && (
                              <Button
                                color="primary"
                                onClick={() =>
                                  fetchMerchantById(mmo.merchantId, "edit")
                                }
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Edit"
                              >
                                <i
                                  class="fas fa-edit fa-lg"
                                  style={iconCss}
                                ></i>
                              </Button>
                            )}

                            {!mmo.isBlocked && privileges.block && (
                              <Button
                                color="primary"
                                onClick={() => openDelete(mmo)}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Block"
                              >
                                <i
                                  class="fa fa-eye-slash fa-lg"
                                  style={iconCss}
                                ></i>
                              </Button>
                            )}
                            {mmo.isBlocked && privileges.unblock && (
                              <Button
                                color="primary"
                                onClick={() => openDelete(mmo)}
                                size="sm"
                                style={btnCss}
                                data-toggle="tooltip"
                                title="Unblock"
                              >
                                <i
                                  class="fa fa-eye-slash fa-lg"
                                  style={{ color: "#FF0000" }}
                                ></i>
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
                {loading && <FullPageLoader label={"Fetching..."} />}
                {!loading && !filteredList.length && <NoRecordFound />}
              </Table>
              <CardFooter className="py-4">
                {filteredList.length > 0 && (
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>Total Records : {totalRec}</strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6">
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage}
                          />
                        </div>
                      </nav>
                    </Col>
                  </Row>
                )}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MerchantLocationsList;
