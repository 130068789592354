import React from "react";
import Header from "components/Headers/Header.js";
import { useToasts } from 'react-toast-notifications';
import { getLoginType, getUserId, postMethod } from "../../services/httpServices";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import {btnCss, iconCss} from "../../appConfig";
import moment from "moment";
import ReactPaginate from 'react-paginate';
import { getSortCls } from "services/util";


import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  ModalHeader,
  Badge
} from "reactstrap";
import { DayEndReportStatus } from "appConfig";

const STATUS = DayEndReportStatus;

const sortIS = {
  sortPaymentMethodName: 0,
  sortCreatedAt: 0,
  sortProductId: 0,
  sortAmount: 0,
  sortCommissionAmount: 0,
  sortReferenceId: 0
};

const MerchantPaymentList = () => {
  const [banklist, setBankList] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [pagesCount, setPageCount] = React.useState(0);
  const [timeout, setTimeoutNew] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [statusFilter, setStatusFilter] = React.useState(STATUS[0].key);
  const [totalList, setTotalList] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [refresh, setRefresh] = React.useState(false);
  const [totalRec, setTotalRec] = React.useState(0);
  const [sort, setSort] = React.useState(sortIS);
  const [isMounted, setIsMounted] = React.useState(false);

  const { addToast } = useToasts();

  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }

  // React.useEffect(() => {
  //   // fetch mobilemoney operator
  //   fetchSettlement();
  // }, []);

  // React.useEffect(() => {
  //   setListDataWrapper(totalList);
  // }, [statusFilter]);

  // React.useEffect(()=> {
  //   if (refresh) {
  //     reloadListHandle();
  //   }
  // }, [refresh])

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (
        searchText && searchText.length >= 3 ||
        searchText.length == 0
      ) {
        fetchSettlement();
      }
    }
  }, [searchText]);


  React.useEffect(() => {
    fetchSettlement();
  }, [currentPage]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchSettlement();
      }
    }
  }, [sort]);

  React.useEffect(()=> {
    if (refresh && isMounted) {
      setSearchText('');
      setSort(sortIS);
      if (currentPage == 0) {
        fetchSettlement();
      } else {
        setCurrentPage(0);
      }
    }
  }, [refresh]);

  React.useEffect(() => {
    if (!refresh && isMounted) {
      if (currentPage != 0) {
        setCurrentPage(0);
      } else {
        fetchSettlement();
      }
    }
  }, [statusFilter]);



  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const changeStatus = (val) => {
    var value = val;
    setStatusFilter(value);
  }

  const statusFormat = (val) => {
    let ob = STATUS.find((s)=> s.key === val);
    // return ob && ob.value ? ob.value : val;
    return ob && ob.value ? (
      <Badge color="" className="badge-dot mr-4">
        <i className={ob.className}/>
        {ob.value}
      </Badge>
    ) : val
  };

  const getSearchHandle = (val, blist) => {
    let filteredList = [];
    let banklist = blist;
    for (let i = 0; i < banklist.length; i++) {
      if (
        ( banklist[i].paymentMethodName &&
          banklist[i].paymentMethodName.toString()
          .trim()
          .toLowerCase()
          .includes(val.trim().toLowerCase()))
      ) {
        filteredList.push(banklist[i]);
      }
    }
    return filteredList;
  }

  const getSearch = (val) => {
    setSearchText(val);
  };

  const getAmt = (amt) => {
    return Number(amt).toLocaleString();
  }

  const setListDataWrapper = (resp) => {
    let arr = setListData(resp);
    if (arr && arr.length) {
      setLoading(false);
    }
    if (searchText) {
      arr = getSearchHandle(searchText, arr);
    }
    setBankList(arr);
    setFilteredList(arr);
    let pc = Math.ceil(arr.length / pageSize);
    setPageCount(pc);
  }

  const setListData = (resp) => {
    let arr = [];
    if (statusFilter === 'All') {
      return resp;
    }
    for (let i = 0; i < resp.length; i++) {
      if (resp[i].status === statusFilter) {
        arr.push(resp[i]);
      }
    }
    return arr;
  }

  const fetchSettlement = async () => {
    let errorMsg = '';
    setLoading(true);

    let data = {
      'PageNumber': Number(currentPage + 1),
      'PerPage': pageSize,
      'Status': statusFilter
    };

    if (searchText && searchText.length >= 3) {
      data['SearchText'] = searchText;
      // data['PageNumber'] = 0;
    }

    if (sort.sortPaymentMethodName == "1") {
      data['SortingOn'] = 'PaymentMethodName';
      data['IsDescending'] = false;
    } else if (sort.sortPaymentMethodName == "2") {
      data['SortingOn'] = 'PaymentMethodName';
      data['IsDescending'] = true;
    } else if (sort.sortCreatedAt == "1") {
      data['SortingOn'] = 'CreatedAt';
      data['IsDescending'] = false;
    } else if (sort.sortCreatedAt == "2") {
      data['SortingOn'] = 'CreatedAt';
      data['IsDescending'] = true;
    } else if (sort.sortProductId == "1") {
      data['SortingOn'] = 'ProductId';
      data['IsDescending'] = false;
    } else if (sort.sortProductId == "2") {
      data['SortingOn'] = 'ProductId';
      data['IsDescending'] = true;
    } else if (sort.sortAmount == "1") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = false;
    } else if (sort.sortAmount == "2") {
      data['SortingOn'] = 'Amount';
      data['IsDescending'] = true;
    } else if (sort.sortCommissionAmount == "1") {
      data['SortingOn'] = 'CommissionAmount';
      data['IsDescending'] = false;
    } else if (sort.sortCommissionAmount == "2") {
      data['SortingOn'] = 'CommissionAmount';
      data['IsDescending'] = true;
    } else if (sort.sortReferenceId == "1") {
      data['SortingOn'] = 'ReferenceId';
      data['IsDescending'] = false;
    } else if (sort.sortReferenceId == "2") {
      data['SortingOn'] = 'ReferenceId';
      data['IsDescending'] = true;
    }
    let url = '/api/Settlement/MerchantStatement/'
    let loginType = getLoginType()
    if(loginType === "Agent") {
      url = '/api/transactions/SuperAgentTransaction'
    } else {
      url = '/api/Settlement/MerchantStatement/'
    }
    let id = getUserId();
    postMethod(url, data, 'post')
    .then(res => {
      setLoading(false);
      setRefresh(false);
      setIsMounted(true);
      if (
        res && res.data
      ) {

        if (res.data.responseCode === "200" &&
          res.data.result) {
          // Success
          if (
            !res.data.result.list ||
            !res.data.result.list.length
          ) {
            res.data.result.list = [];
          }
          setTotalList(res.data.result.list);
          setFilteredList(res.data.result.list)
          let pc = Math.ceil(res.data.result.totalRecords / pageSize);
          setTotalRec(res.data.result.totalRecords);
          setPageCount(pc);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching list';
          notifyFailed(errorMsg);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching';
          notifyFailed(errorMsg);

        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);

      }
    }).catch(err => {
      setLoading(false);
      setRefresh(false);
      setIsMounted(true);
      notifyFailed('Something went wrong!, Please call 3737');
    })
  }

  const reloadList = () => {
    setSearchText('');
    setRefresh(true);
  }

  const sortHandle = (fieldValue, fieldName) => {
    let newValue = 0;
    if (fieldValue == "0") {
      newValue = '1';
    } else if (fieldValue == "1") {
      newValue = '2';
    } else if (fieldValue == "2") {
      newValue = '1';
    }
    setSort({ ...sortIS, [fieldName]: newValue });
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  }


  return (
    <>
      <Header/>
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                {/* <h3 className="mb-0">Card tables</h3> */}

                <Form className="form-inline mr-3 d-md-flex ml-lg-auto" onSubmit={e => { e.preventDefault(); }}>
                  
                <FormGroup>
                  <label
                    for="status"
                    className="form-control-label">
                    Status
                  </label>
                  &nbsp; &nbsp;

                  <div class="dropdown-border">
                    <Input
                      type="select"
                      id="status"
                      className="form-control-alternative status-filter"
                      name="statusFilter"
                      value={statusFilter}
                      onChange={(e) => changeStatus(e.target.value)}
                      >
                      {STATUS.map(c => 
                      <option key={c.key} value={c.key}>{c.value}</option>
                      )}
                    </Input>
                  </div>
                  
                </FormGroup>

                &nbsp;&nbsp;&nbsp;
                  
                  <FormGroup className="mb-0 my-4 navbar-search">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input className="search-cls" placeholder="Search" 
                        type="text" 
                        value={searchText}
                        onChange={(e) => getSearch(e.target.value)}
                      />
                    </InputGroup>
                  </FormGroup>

                  &nbsp;&nbsp;
                  <Button
                    color="primary"
                    onClick={reloadList}
                    size="sm"
                    style={btnCss}
                    data-toggle="tooltip" 
                    title="Refresh">
                    <i class="fas fa-redo fa-lg" style={iconCss}></i>
                  </Button>

                </Form>

              </CardHeader>


              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortPaymentMethodName, 'sortPaymentMethodName')}>
                      Payment From
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortPaymentMethodName)}
                          onClick={() => sortHandle(sort.sortPaymentMethodName, 'sortReferenceId')}
                        />
                      </button>
                    </th>
                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortCreatedAt, 'sortCreatedAt')}>
                      Date
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortCreatedAt)}
                          onClick={() => sortHandle(sort.sortCreatedAt, 'sortCreatedAt')}
                        />
                      </button>
                    </th>
                    <th scope="col"
                      onClick={() => sortHandle(sort.sortProductId, 'sortProductId')}>
                      Product Id
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortProductId)}
                          onClick={() => sortHandle(sort.sortProductId, 'sortProductId')}
                        />
                      </button>
                    </th>

                    <th scope="col"
                      onClick={() => sortHandle(sort.sortReferenceId, 'sortReferenceId')}>
                      Smart Pay Id
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortReferenceId)}
                          onClick={() => sortHandle(sort.sortReferenceId, 'sortReferenceId')}
                        />
                      </button>
                    </th>

                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}>
                      Amount
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortAmount)}
                          onClick={() => sortHandle(sort.sortAmount, 'sortAmount')}
                        />
                      </button>
                    </th>
                    <th scope="col" 
                      onClick={() => sortHandle(sort.sortCommissionAmount, 'sortCommissionAmount')}>
                      Fees
                      <button style={btnCss} >
                        <i className={getSortCls(sort.sortCommissionAmount)}
                          onClick={() => sortHandle(sort.sortCommissionAmount, 'sortCommissionAmount')}
                        />
                      </button>
                    </th>
                    <th scope="status">Status</th>
                  </tr>
                </thead>
                <tbody>
                {filteredList.length > 0 && filteredList.map((mmo, i) => {
                      return (
                    <tr>
                      <th scope="row">
                        {mmo.paymentMethodName}
                      </th>
                      <td>{moment(mmo.createdAt).format('lll')}</td>
                      <td>{mmo.productId ? mmo.productId : '--'}</td>
                      <td>{mmo.referenceId}</td>
                      <td>{getAmt(mmo.amount)}</td>
                      <td>{mmo.commissionAmount}</td>
                      <td>{statusFormat(mmo.status)}</td>
                      </tr>

                  )
                }
                )}
                </tbody>
                {loading && <FullPageLoader label={"Fetching..."} />}
                {!loading && !filteredList.length && <NoRecordFound />}
              </Table>
              <CardFooter className="py-4">
              {filteredList.length > 0 &&
                  <Row>
                    <Col lg="6">
                      <div>
                        <small>
                          <strong>
                            Total Records : {totalRec}
                          </strong>
                        </small>
                      </div>
                    </Col>

                    <Col lg="6" >
                      <nav aria-label="...">
                        <div className="float-right">
                          <ReactPaginate
                            previousLabel={"prev"}
                            nextLabel={"next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pagesCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={2}
                            onPageChange={handlePageClick}
                            containerClassName={"paginationV2"}
                            subContainerClassName={"pages paginationV2"}
                            activeClassName={"active"}
                            forcePage={currentPage} />
                        </div>
                      </nav>

                    </Col>
                  </Row>}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )

}

export default MerchantPaymentList;