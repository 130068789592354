import { 
  PAYMENT_FECTH_LIST, PAYMENT_SET_LIST, PAYMENT_FETCH_ERROR, RESET_PAYMENT_LIST,
  COLLECTION_BANK_ERROR, COLLECTION_BANK_LIST, COLLECTION_BANK_SET_LIST, 
  RESET_COLLECTION_BANK_LIST } from './constant';

export function paymentList(data) {
  return {
    type: PAYMENT_FECTH_LIST,
    payload: data
  };
}

export function setPaymentList(data) {
  return {
    type: PAYMENT_SET_LIST,
    payload: data
  };
}

export function paymentListError(data) {
  return {
    type: PAYMENT_FETCH_ERROR,
    payload: data
  };
}

export function resetPaymentList() {
  return {
    type: RESET_PAYMENT_LIST
  };
}

export function cBankList() {
  return {
    type: COLLECTION_BANK_LIST
  };
}

export function setCbankList(data) {
  return {
    type: COLLECTION_BANK_SET_LIST,
    payload: data
  };
}

export function cBankListError(data) {
  return {
    type: COLLECTION_BANK_ERROR,
    payload: data
  };
}

export function resetCbankList() {
  return {
    type: RESET_COLLECTION_BANK_LIST
  };
}