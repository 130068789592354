import classnames from "classnames";
import React, { useState } from "react";
import {
  Button,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Home from "./Home";
import Insights from "./Insights";
import { getLoginType } from "services/httpServices";

function Index() {
  const [activeTab, setActiveTab] = useState("1");

  const loginType = getLoginType();
  
  const toggle = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="flex flex-column w-100">
      <Container
        fluid
        className="w-100 d-flex flex-column justify-content-center float-left align-items-start"
      >
        {loginType === "Admin" && (
          <Nav fill pills className="mx-5">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
                style={{ padding: "10px 20px", cursor: "pointer" }}
              >
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
                style={{
                  padding: "10px 20px",
                  cursor: "pointer",
                  marginLeft: "8px",
                }}
              >
                Insights
              </NavLink>
            </NavItem>
          </Nav>
        )}
        <TabContent activeTab={activeTab} className="w-100 mt-4">
          <TabPane tabId="1">
            <Home />
          </TabPane>
          {loginType === "Admin" && (
          <TabPane tabId="2">
            <Insights />
          </TabPane>
          )}
        </TabContent>
      </Container>
    </div>
  );
}

export default Index;
