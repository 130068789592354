import React from "react";
import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";

const ElephantBetsPaymentTab = ({
  values,
  handleSubmit,
  handleInputChange,
  formState,
  errors,
  bettingCompanyTypes,
  langs,
  userResponse,
  setValues,
  initialValue,
  loading,
  setFormState,
  VerifyAgent,
}) => {
  return (
    <div className="mt-2">
      <div className="pl-lg-4">
        <Row>
          <Col>
            <FormGroup>
              <label htmlFor="bettingCompany" className="form-control-label">
                Betting Zones
              </label>
              <Input
                type="select"
                id="bettingCompany"
                className="form-control-alternative"
                name="bettingCompany"
                value={values.bettingCompany}
                onChange={handleInputChange}
                required
                disabled={formState === 2}
              >
                <option key="" value="">
                  ----- Select Betting Zones -----
                </option>
                {bettingCompanyTypes?.map((c) => (
                  <option key={c.id} value={c.value}>
                    {c.label}
                  </option>
                ))}
              </Input>

              {errors.bettingCompany && (
                <div className="text-left text-danger">
                  <small>* {errors.bettingCompany}</small>
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        {values.bettingCompany !== "" && (
          <>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-agentId">
                    Agent Id
                  </label>
                  <Input
                    className="form-control-alternative"
                    // country={"sl"}
                    id="input-agentId"
                    name="agentId"
                    type="text"
                    value={values.agentId}
                    onChange={handleInputChange}
                    placeholder="Agent Id"
                    required
                    maxLength={10}
                  />
                  {errors.agentId && (
                    <div className="text-left text-danger">
                      <small>* {errors.agentId}</small>
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </>
        )}

        {formState === 2 && (
          <>
            {userResponse?.id && (
              <>
                <p>Agent Name: {userResponse?.name}</p>
              </>
            )}
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-amount">
                    Amount
                  </label>
                  <Input
                    className="form-control-alternative"
                    // country={"sl"}
                    id="input-amount"
                    name="amount"
                    type="number"
                    value={values.amount}
                    // onlyCountries={MOBILE_ONLY_COUNTRY}
                    // countryCodeEditable={false}
                    onChange={handleInputChange}
                    placeholder="Amount"
                    required
                    maxLength={50}
                  />
                  {errors.amount && (
                    <div className="text-left text-danger">
                      <small>* {errors.amount}</small>
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-pin">
                    Pin
                  </label>
                  <Input
                    className="form-control-alternative"
                    // country={"sl"}
                    id="input-pin"
                    name="pin"
                    type="password"
                    value={values.pin}
                    onChange={handleInputChange}
                    placeholder="Pin"
                    required
                    maxLength={10}
                  />
                  {errors.pin && (
                    <div className="text-left text-danger">
                      <small>* {errors.pin}</small>
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </>
        )}
        <Row>
          {formState === 2 && (
            <Col>
              <div className="float-left">
                <Button
                  className="btn float-right"
                  color="primary"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    setValues(initialValue);
                    setFormState(1);
                  }}
                >
                  Reset
                </Button>
              </div>
            </Col>
          )}
          <Col>
            <div>
              {values.agentId !== "" && (
                <Button
                  className="btn float-right"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  onClick={formState === 1 ? VerifyAgent : handleSubmit}
                >
                  Submit {loading && <i class="fa fa-spinner fa-spin"></i>}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ElephantBetsPaymentTab;
