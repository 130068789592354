import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label
} from "reactstrap";
import { useHistory } from "react-router";

const SHOWMSG = (props) => {
  const history = useHistory();

  var msg = '';
  var error = false;
  if (props.location && props.location.state) {
    error = props.location.state.error;
    msg = props.location.state.msg;
  }

  const gotoLogin = (e) => {
    e.preventDefault();
    history.push('/');
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0 mt--7">
          <CardBody className="px-lg-5 py-lg-5" style={{ minHeight: '300px' }}>
          <Row>
            <Col className="text-center">
              <strong><h2>Welcome !</h2></strong>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-center">
              {!error ? <img
                alt="..."
                height="100"
                width="100"
                className="round-circle"
                src={
                  require('../../assets/img/brand/right-checkmark-removebg-preview.png')
                    .default
                }
              /> : 
              <img
                alt="..."
                height="100"
                width="100"
                className="round-circle"
                src={
                  require('../../assets/img/brand/error.png')
                    .default
                }
              />}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="text-center">{msg}</Col>
          </Row>

          {!props.login && 
            <Row className="mt-3">
              <Col className="text-center">
                <a onClick={(e) => gotoLogin(e)} id="tag" href="#">Login
                </a>
              </Col>
            </Row>
          }
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default SHOWMSG;