import { SET_LOGIN_USER_IMAGE } from "./constant";

export const loginIS = {
  image: null
}

export const LoginReducer = (state = loginIS, action) => {
  switch (action.type) {
    case SET_LOGIN_USER_IMAGE:
      return { ...state, image: action.payload}
    default:
      return { ...state };
  }
};
