
import React, { useEffect } from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { getAmt, daysInMonth, daysInArray } from "services/util";
import { postMethod } from "services/httpServices";
import { useToasts } from 'react-toast-notifications';
import { FinancePieChart, financeMTDCollection, financeMTDCommission,
  FinanceDoughnutChart, financeMTDCollectionLC } from "variables/charts";
import { FinanceDoughnutChart2 } from "variables/charts";
import { financeMTDCollectionCash } from "variables/charts";
import { financeMTDCollectionWallet } from "variables/charts";


const FinanceUserHeader = (props) => {

  const [settleBal, setSettleBal] = React.useState(0);
  const [collBal, setCollBal] = React.useState(0);
  const [walletComm, setWalletComm] = React.useState(0);
  const [cardLoading, setCardLoading] = React.useState(false);
  const [paymentTypes, setPaymentTypes] = React.useState({})

  const { addToast } = useToasts();

  useEffect(() => {
    fetch();
  }, []);

  useEffect(()=> {
    props.showFinancePieLoader(true);
    fetchPieChart();
  }, [props.financePieMonth]);

  useEffect(()=> {
    props.showFinancePieLoader(true);
    fetchPieChart();
  }, [props.financePieYear]);

  useEffect(()=> {
    props.showFinanceMTDColltnLoader(true);
    fetchMTDCollectionPieChart();
  }, [props.financeMTDColltnMonth]);

  useEffect(()=> {
    props.showFinanceMTDColltnLoader(true);
    fetchMTDCollectionPieChart();
  }, [props.financeMTDColltnYear]);

  useEffect(()=> {
    props.showFinanceMTDColltnLoader2(true);
    fetchMTDCollection();
  }, [props.financeMTDColltnMonth2]);

  useEffect(()=> {
    props.showFinanceMTDColltnLoader2(true);
    fetchMTDCollection();
  }, [props.financeMTDColltnYear2]);

  useEffect(()=> {
    props.showFinanceMTDCommLoader(true);
    fetchMTDCommission();
  }, [props.financeMTDCommMonth]);

  useEffect(()=> {
    props.showFinanceMTDCommLoader(true);
    fetchMTDCommission();
  }, [props.financeMTDCommYear]);


  const notifyFailed = (text) => {
    if (!text) {
      text = 'Error in saving';
    }
    addToast(text, {
      appearance: 'error',
      autoDismiss: true,
    });
  }


  const fetch = () => {
    let errorMsg = '';
    setCardLoading(true);
    postMethod('/api/dashboard/financeuser', {}, 'post')
      .then(res => {
        setCardLoading(false);
        if (
          res && res.data
        ) {
          if (res.data.responseCode === "200") {
            // Success
            if (res.data.result.hasOwnProperty('totalPayable')) {
              setCollBal(res.data.result.totalPayable);
            }
            if (res.data.result.hasOwnProperty('totalCommission')) {
              setWalletComm(res.data.result.totalCommission);
            }
            if (res.data.result.hasOwnProperty('totalCollection')) {
              setSettleBal(res.data.result.totalCollection);
            }
            // if (res.data.result.hasOwnProperty('unVerifiedSubscriber')) {
            //   setUnverifiedSubs(res.data.result.unVerifiedSubscriber);
            // }
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || 'Error in fetching dashboard items';
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = 'Unknown Error in fetching';
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = 'Unknown Error';
          notifyFailed(errorMsg);
        }
      }).catch(err => {
        setCardLoading(false);
        notifyFailed('Something went wrong!, Please call 3737');
      });
  }

  

  const fetchPieChart = () => {

    let obj = {
      collectionAmt: 0,
      settlementAmt: 0,
      walletComm: 0,
      transfer: 0
    };

    let errorMsg = '';
    var data = {
      'Year': Number(props.financePieYear),
      'Month': Number(props.financePieMonth) + 1
    };
    postMethod('/api/dashboard/v2/FinanceUser/MonthlyPiChart', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (res.data.responseCode === "200") {
          // Success
          if (res.data.result.hasOwnProperty('totalCollection')) {
            obj.totalCollection = res.data.result.totalCollection;
          }
          if (res.data.result.hasOwnProperty('totalBankSettlement')) {
            obj.settlementAmt = res.data.result.totalBankSettlement;
          }
          if (res.data.result.hasOwnProperty('totalCommission')) {
            obj.totalCommission = res.data.result.totalCommission;
          }
          if (res.data.result.hasOwnProperty('totalMerchantSettlement')) {
            obj.transfer = res.data.result.totalMerchantSettlement;
          }
          // testng
          // if (res.data.result.hasOwnProperty('totalBankCollection')) {
          //   obj.collectionAmt = 10;
          // }
          // if (res.data.result.hasOwnProperty('totalBankSettlement')) {
          //   obj.settlementAmt = 20;
          // }
          // if (res.data.result.hasOwnProperty('totalCommission')) {
          //   obj.walletComm = 30;
          // }
          // if (res.data.result.hasOwnProperty('totalMerchantSettlement')) {
          //   obj.transfer = 40;
          // }
          let chart = FinanceDoughnutChart(obj);
          props.showFinancePieChart(chart);
          props.showFinancePieLoader(false);
        } else if (res.data.responseCode === "400") {
          // Error
          props.showFinancePieLoader(false);
          errorMsg = res.data.responseMessage || 'Error in fetching dashboard items';
          notifyFailed(errorMsg);
        } else {
          // Unknown Error
          props.showFinancePieLoader(false);
          errorMsg = 'Unknown Error in fetching';
          notifyFailed(errorMsg);
        }
      } else {
        props.showFinancePieLoader(false);
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
      }
    }).catch(err => {
      props.showFinancePieLoader(false);
      notifyFailed('Something went wrong!, Please call 3737');
    });
  }

  const fetchMTDCollection = () => {
    var errorMsg = '';
    var data = {
      'Year': Number(props.financeMTDColltnYear2),
      'Month': Number(props.financeMTDColltnMonth2) + 1
    };
    postMethod('/api/Dashboard/V2/FinanceUser/MonthlyBarChartCollection', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (
          res.data.responseCode === "200" && 
          res.data.result && res.data.result.list
        ) {
          // Success
          let month = Number(props.financeMTDColltnMonth2);
          let year = Number(props.financeMTDColltnYear2);
          let totalDays = daysInMonth(month + 1, year);
          let arr = daysInArray(totalDays);
          let valArr1 = [];
          let valArr2 = [];
          let totalCollectionCash = 0;
          let totalCollectionWallet = 0;

          for (let i = 0; i < arr.length; i++) {
            let val = res.data.result.list.find(l => l.day == arr[i]);
            if (val && val.hasOwnProperty('walletAmount')) {
              valArr1.push(Number(val.walletAmount));
              totalCollectionWallet = totalCollectionWallet + Number(val.walletAmount);
            } else {
              valArr1.push(0);
            }
          }
          for (let i = 0; i < arr.length; i++) {
            let val = res.data.result.list.find(l => l.day == arr[i]);
            if (val && val.hasOwnProperty('cashAmount')) {
              valArr2.push(Number(val?.cashAmount));
              totalCollectionCash = totalCollectionCash + Number(val.cashAmount);
            } else {
              valArr2.push(0);
            }
          }
          let mtdChart1 = financeMTDCollectionCash({
            daysArr: arr, 
            dataArr: valArr2,
            totalCollectionCash: totalCollectionCash
          });
          let mtdChart2 = financeMTDCollectionWallet({
            daysArr: arr, 
            dataArr: valArr1,
            totalCollectionWallet: totalCollectionWallet
          });
          console.log({chart1: mtdChart1, chart2: mtdChart2})
          props.showFinanceMTDColltn2({chart1: mtdChart1, chart2: mtdChart2});
          props.showFinanceMTDColltnLoader2(false);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching charts';
          notifyFailed(errorMsg);
          props.showFinanceMTDColltnLoader2(false);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching charts';
          notifyFailed(errorMsg);
          props.showFinanceMTDColltnLoader2(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        props.showFinanceMTDColltnLoader2(false);
      }
    }).catch(err => {
      notifyFailed('Something went wrong!, Please call 3737');
      props.showFinanceMTDColltnLoader2(false);
    });
  }

  const fetchMTDCollectionPieChart = () => {
    var errorMsg = '';
    var data = {
      'Year': Number(props.financeMTDColltnYear),
      'Month': Number(props.financeMTDColltnMonth) + 1
    };
    postMethod('/api/dashboard/V2/FinanceUser/PaymentType/MonthlyPiChart', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (
          res.data.responseCode === "200" && 
          res.data.result && res.data.result
        ) {
          // Success
        
          const ob = {
            cashAmount: res.data.result.totalCashCollection,
            walletAmount: res.data.result.totalWalletCollection,
          }
          setPaymentTypes(ob)
          const chart = FinanceDoughnutChart2(ob)
          // console.log({chart, ob})
          // let mtdChart = financeMTDCollection({
          //   daysArr: arr, 
          //   dataArr: valArr,
          //   totalComm: totalComm
          // });
          props.showFinanceMTDColltn(chart);
          props.showFinanceMTDColltnLoader(false);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching charts';
          notifyFailed(errorMsg);
          props.showFinanceMTDColltnLoader(false);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching charts';
          notifyFailed(errorMsg);
          props.showFinanceMTDColltnLoader(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        props.showFinanceMTDColltnLoader(false);
      }
    }).catch(err => {
      notifyFailed('Something went wrong!, Please call 3737');
      props.showFinanceMTDColltnLoader(false);
    });
  }

  const fetchMTDCommission = () => {
    var errorMsg = '';
    var data = {
      'Year': Number(props.financeMTDCommYear),
      'Month': Number(props.financeMTDCommMonth) + 1
    };
    postMethod('/api/Dashboard/V2/FinanceUser/MonthlyBarChartCommissionCollection', data, 'post')
    .then(res => {
      if (
        res && res.data
      ) {
        if (
          res.data.responseCode === "200" && 
          res.data.result && res.data.result.list
        ) {
          // Success
          let month = Number(props.financeMTDColltnMonth);
          let year = Number(props.financeMTDColltnYear);
          let totalDays = daysInMonth(month + 1, year);
          let arr = daysInArray(totalDays);
          let valArr = [];
          let totalComm = 0;

          for (let i = 0; i < arr.length; i++) {
            let val = res.data.result.list.find(l => l.day == arr[i]);
            if (val && val.hasOwnProperty('amount')) {
              valArr.push(Number(val.amount));
              totalComm = totalComm + Number(val.amount);
            } else {
              valArr.push(0);
            }
          }
          let mtdChart = financeMTDCommission({
            daysArr: arr, 
            dataArr: valArr,
            totalComm: totalComm
          });
          props.showFinanceMTDComm(mtdChart);
          props.showFinanceMTDCommLoader(false);
        } else if (res.data.responseCode === "400") {
          // Error
          errorMsg = res.data.responseMessage || 'Error in fetching charts';
          notifyFailed(errorMsg);
          props.showFinanceMTDCommLoader(false);
        } else {
          // Unknown Error
          errorMsg = 'Unknown Error in fetching charts';
          notifyFailed(errorMsg);
          props.showFinanceMTDCommLoader(false);
        }
      } else {
        errorMsg = 'Unknown Error';
        notifyFailed(errorMsg);
        props.showFinanceMTDCommLoader(false);
      }
    }).catch(err => {
      notifyFailed('Something went wrong!, Please call 3737');
      props.showFinanceMTDCommLoader(false);
    });
  }


  return (
    <>
      <div className="header bg-gradient-info pb-4 pt-2 pt-md-2">
        <Container fluid>
          <div className="header-body">
          <Row>
              <Col xl="4" md="6" lg="6">
                <Card className="card-stats  shadow mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Collection</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {cardLoading ?
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> :
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(settleBal)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>

              </Col>
            
              <Col xl="4" md="6" lg="6">
                <Card className="card-stats  shadow mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Payable</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i class="fas fa-envelope-open-text"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {cardLoading ?
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> :
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(collBal)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>

              </Col>
            

              <Col xl="4" md="6" lg="6">
                <Card className="card-stats  shadow mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Commission</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-purple text-white rounded-circle shadow">
                          <i class="fas fa-wallet"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {cardLoading ?
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> :
                        <span className="h2 font-weight-bold mb-0">
                          {getAmt(walletComm)}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>

              </Col>

              <Col xl="4" md="6" lg="6">
                <Card className="card-stats mt-4 shadow mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Cash Collection</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-green text-white rounded-circle shadow">
                          <i class="fas fa-money-bill-alt"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {cardLoading ?
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> :
                        <span className="h2 font-weight-bold mb-0">
                          {paymentTypes?.cashAmount?? 0}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>

              </Col>

              <Col xl="4" md="6" lg="6">
                <Card className="card-stats mt-4  shadow mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          <strong>Total Wallet Collection</strong>
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i class="fas fa-envelope-open-text"></i>
                        </div>
                      </Col>
                    </Row>
                    <p className="mb-0 text-muted text-sm">
                      {cardLoading ?
                        <div className="stage ml-4 mt-2 mb-3">
                          <div className="dot-flashing"></div>
                        </div> :
                        <span className="h2 font-weight-bold mb-0">
                          {paymentTypes?.walletAmount ?? 0}
                        </span>
                      }
                    </p>
                  </CardBody>
                </Card>

              </Col>

            </Row>
          
          </div>
        </Container>
      </div>
    </>
  );
};

export default FinanceUserHeader;
