import { PAYMENT_FETCH_ERROR } from 'views/bank/constant';
import { 
  FETCH_SUBSCRIBER_INFO,
  SET_SUBSCRIBER_INFO,
  SET_ERROR,
  ADD_VOUCHER,
  ADD_VOUCHER_SUCCESS,
  ADD_VOUCHER_FAILED,
  RESET_VOUCHER,
  FETCH_MERCHANT,
  SET_MERCHANT,
  FETCH_MERCHANT_ERROR,
  RESET_MERCHANT,
  FETCH_PAYABLE_AMT,
  SET_PAYABLE_AMT,
  PAYABLE_AMT_ERROR,
  RESET_PAYABLE_AMT
} from './constant';

export function fetchSubs() {
  return {
    type: FETCH_SUBSCRIBER_INFO
  };
}

export function setSubs(data) {
  return {
    type: SET_SUBSCRIBER_INFO,
    payload: data
  };
}

export function setError(data) {
  return {
    type: SET_ERROR,
    payload: data
  }
}

export function addVoucher(data) {
  return {
    type: ADD_VOUCHER,
    data
  }
}

export function addVoucherFailed(data) {
  return {
    type: ADD_VOUCHER_FAILED,
    payload: data
  }
}

export function addVoucherSuccess(data) {
  return {
    type: ADD_VOUCHER_SUCCESS,
    payload: data
  }
}

export function resetVoucher() {
  return {
    type: RESET_VOUCHER
  };
}

export function fetchMerchant() {
  return {
    type: FETCH_MERCHANT
  }
}

export function setMerchant(data) {
  return {
    type: SET_MERCHANT,
    payload: data
  }
}

export function fetchMerchantError(data) {
  return {
    type: FETCH_MERCHANT_ERROR,
    payload: data
  }
}

export function resetMerchant() {
  return {
    type: RESET_MERCHANT
  }
}


export function fetchPayableAmt(data) {
  return {
    type: FETCH_PAYABLE_AMT,
    data: data
  }
}

export function setPayableAmt(data) {
  return {
    type: SET_PAYABLE_AMT,
    payload: data
  }
}

export function fetchPayableAmtErro(data) {
  return {
    type: PAYABLE_AMT_ERROR,
    payload: data
  }
}

export function resetPayableAmt() {
  return {
    type: RESET_PAYABLE_AMT
  }
}