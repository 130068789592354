export const FETCH_SUBSCRIBER_INFO = 'app/TopUp/FETCH_SUBSCRIBER_INFO';
export const SET_SUBSCRIBER_INFO = 'app/TopUp/SET_SUBSCRIBER_INFO';
export const SET_ERROR = 'app/TopUp/SET_ERROR';
export const ADD_VOUCHER = 'app/TopUp/ADD_VOUCHER';
export const ADD_VOUCHER_SUCCESS = 'app/TopUp/ADD_VOUCHER_SUCCESS';
export const ADD_VOUCHER_FAILED = 'app/TopUp/ADD_VOUCHER_FAILED';
export const RESET_VOUCHER = 'app/TopUp/RESET_VOUCHER';
export const FETCH_MERCHANT = 'app/MakePayment/FETCH_MERCHANT';
export const FETCH_MERCHANT_ERROR = 'app/MakePayment/FETCH_MERCHANT_ERROR';
export const SET_MERCHANT = 'app/MakePayment/SET_MERCHANT';
export const RESET_MERCHANT = 'app/MakePayment/RESET_MERCHANT';
export const FETCH_PAYABLE_AMT = 'app/MakePayment/FETCH_PAYABLE_AMT';
export const SET_PAYABLE_AMT = 'app/MakePayment/SET_PAYABLE_AMT';
export const PAYABLE_AMT_ERROR = 'app/MakePayment/PAYABLE_AMT_ERROR';
export const RESET_PAYABLE_AMT = 'app/MakePayment/RESET_PAYABLE_AMT';