import Header from "components/Headers/Header";
import React from "react";
import { Card, CardHeader, Container } from "reactstrap";

function Maps() {
  return (
    <div>
      {" "}
      <Header />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <CardHeader className="border-0 d-flex justify-content-start  align-items-center">Coming soon.</CardHeader>
        </Card>
      </Container>
    </div>
  );
}

export default Maps;
